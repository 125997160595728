import { Component, Renderer2, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, ViewEncapsulation, ElementRef, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataViewModule } from 'primeng/dataview';
import { ViewportService } from 'src/app/services/viewport.service';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { ChangeDetectionStrategy } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Item } from 'src/app/models/liveshop';

export interface VideoItem {
  uid: string;
  id: number;
  src: string;   // URL do vídeo
  poster: string; // Poster do vídeo
  thumbnail?: string;
}
@Component({
  selector: 'app-grid',
  standalone: true,
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  imports: [CommonModule, DataViewModule, VideoModalComponent, ButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,

})
export class GridComponent implements AfterViewInit, OnChanges {
  @Input() arraySlicksFull: Item[] = [];
  @Input() videoUrls: VideoItem[] = [];
  @Input() isAdmin: boolean = true;
  @Input() loading: boolean = false;
  @Input() isCol4: boolean = false;
  @Input() widget_id?: number | undefined;
  @Input() templateType: string | undefined;
  @Input() hasMoreVideos: boolean = true;
  @Output() loadMoreVideos = new EventEmitter<void>();
  @Output() openSidebar = new EventEmitter<void>();
  @Input() totalAssets: number = 0;
  @Input() pageToVideos: number = 0;

  @ViewChild('modalContainer', { read: ElementRef }) modalContainer!: ElementRef;

  selectedVideoUrls: VideoItem[] = [];
  isModalOpen = false;
  constructor(
    private viewportService: ViewportService,
    private renderer: Renderer2,
    private host: ElementRef
  ) {

  }
  isWebComponent: boolean = false;
  ngAfterViewInit(): void {
    this.isWebComponent = !!document.querySelector('video-modal-widget');
    this.viewportService.setTemplateType(this.templateType);
    console.log('ngOnInit grid')
  }
  openModal(videoUrl: VideoItem) {
    // Definir a URL do vídeo selecionado
    this.selectedVideoUrls = [videoUrl];
    console.log('openModal grid');

    // Tornar o modal visível
    const modalElement = this.modalContainer.nativeElement;
    this.renderer.setStyle(modalElement, 'visibility', 'visible');
    this.isModalOpen = true;

    setTimeout(() => {
      const videoModal = document.getElementById('videoModal') as any;

      if (videoModal) {
        console.log('achou videoModal')
        videoModal.setAttribute('is-admin', this.isAdmin.toString());
        videoModal.setAttribute('widget-id', this.widget_id?.toString() || '');
        // videoModal.setAttribute('height', this.height?.toString() || '');
        videoModal.setAttribute('total-assets', this.totalAssets.toString());
        videoModal.setAttribute('show', 'true');

        // 🔹 Definir propriedades diretamente no Web Component
        videoModal.videoUrls = [videoUrl]; // Propriedade via JS
        videoModal.selectedVideo = videoUrl;
        videoModal.arraySlicksFull = this.arraySlicksFull;
        videoModal.pageToVideos = this.pageToVideos;
        // videoModal.template_id = this.template_id;
        // videoModal.store_id = this.store_id;
        // videoModal.aspectRatio = this.aspectRatio;

        if (typeof videoModal.showModal === 'function') {
          console.log('chamou showModal do web componente')
          videoModal.showModal(); // ✅ Agora só chama se existir
        } else {
          console.error('❌ showModal não está disponível no video-modal-widget.');
        }
      }
    }, 100);


  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges grid')
    if (changes['videoUrls']) {
      console.log('videoUrls foi atualizada:', changes['videoUrls'].currentValue);
      this.isLoadingSlides = false;
    }
    if (changes['isCol4']) {
      console.log('isCol4 foi atualizada:', changes['isCol4'].currentValue);
    }
    //this.isLoadingSlides = false;
  }

  closeModal() {
    // Tornar o modal invisível
    const modalElement = this.modalContainer.nativeElement;
    this.renderer.setStyle(modalElement, 'visibility', 'hidden');
    this.isModalOpen = false;
  }
  isLoadingSlides: boolean = false;
  showbtn: boolean = true;
  loadMore() {
    console.log('loadMore grid')
    this.isLoadingSlides = true;
    console.log('Loading more video: ' + this.showbtn);
    if (!this.loading && this.hasMoreVideos) {
      console.log('Loading more videos:');
      this.loadMoreVideos.emit();

    }
  }
  // Função para emitir o evento quando o botão for clicado
  onOpenSidebar(): void {
    console.log(' primeiro onOpenSidebar');
    this.openSidebar.emit();
  }
}
