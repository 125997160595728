import { Component, Input, Output, OnDestroy, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, ViewContainerRef, ComponentRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PVideoPlayerComponent } from '../p-video-player/p-video-player.component';
import { ModalstateService } from 'src/app/services/modalstate.service';
import { Item } from 'src/app/models/liveshop';
import { SidebarModule } from 'primeng/sidebar';

interface VideoItem {
  uid: string;
  id: number;
  src: string;
  poster: string;
  thumbnail?: string;
  products?: any[];
}

@Component({
  selector: 'app-video-modal',
  standalone: true,
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PVideoPlayerComponent, SidebarModule],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    ModalstateService
  ]
})
export class VideoModalComponent implements OnInit, OnDestroy, OnChanges {
  @Input() arraySlicksFull: Item[] = []; // Array completo de slicks isAdmin
  @Input() videoUrls: VideoItem[] = [];  // URLs dos vídeos recebidos do componente pai
  @Input() show: boolean = false;                  // Controla a visibilidade do modal
  @Input() isAdmin: boolean = true;
  @Input() selectedVideo?: VideoItem;    // Vídeo selecionado
  @Output() close = new EventEmitter<void>(); // Evento para fechar o modal
  @Input() totalAssets: number = 0;
  @Input() widget_id?: number;
  @Input() store_id?: number = 163;
  @Input() template_id?: number;
  @Input() height: number | undefined;
  @Input() pageToVideos: number = 0;
  aspectRatio: string | undefined;
  private worker?: Worker;


  // web componente selectedVideo videoUrls
  @Input('video-urls') set setVideoUrls(value: VideoItem[]) {
    this.videoUrls = value;
    console.log("📢 Atualizado videoUrls no Web Component", value);
  }

  @Input('selected-video') set setSelectedVideo(value: VideoItem | undefined) {
    this.selectedVideo = value;
    console.log("📢 Atualizado selectedVideo no Web Component", value);
  }

  @Input('array-slicks-full') set setArraySlicksFull(value: Item[]) {
    this.arraySlicksFull = value;
    console.log("📢 Atualizado arraySlicksFull no Web Component", value);
  }

  @Input('page-to-videos') set setPageToVideos(value: number) {
    this.pageToVideos = value;
    console.log("📢 Atualizado pageToVideos no Web Component", value);
  }

  @Input('template-id') set setTemplateId(value: number | undefined) {
    this.template_id = value;
    console.log("📢 Atualizado template_id no Web Component", value);
  }

  @Input('store-id') set setStoreId(value: number | undefined) {
    this.store_id = value;
    console.log("📢 Atualizado store_id no Web Component", value);
  }

  @Input('aspect-ratio') set setAspectRatio(value: any) {
    this.aspectRatio = value;
    console.log("📢 Atualizado aspectRatio no Web Component", value);
  }

  showModal() {
    this.show = true;
    console.log('📺 Modal aberto com vídeo:', this.selectedVideo);
  }
  // web componente


  isPlayerVisible = false;

  // Referência ao container onde o componente será criado
  @ViewChild('videoPlayerContainer', { read: ViewContainerRef, static: true }) videoPlayerContainer!: ViewContainerRef;
  private videoPlayerRef: ComponentRef<PVideoPlayerComponent> | null = null;

  constructor(
    private modalStateService: ModalstateService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private host: ElementRef
  ) { }
  private isWebComponent: boolean = false;

  private mutationObserver?: MutationObserver;
  ngOnInit(): void {
    this.isWebComponent = !!document.querySelector('video-modal-widget');
    this.aspectRatio = '16/9';
    document.addEventListener('keyup', this.handleKeyUp);

    if (this.isWebComponent) {
      console.log("🟢 Rodando como Web Component");

      (this.host.nativeElement as any).showModal = this.showModal.bind(this);

      this.mutationObserver = new MutationObserver(() => {
        this.syncAttributes();
      });
      this.mutationObserver.observe(this.host.nativeElement, { attributes: true });

    } else {
      console.log("🟢 Rodando como Componente Angular");
    }
  }

  private syncAttributes() {
    const element = this.host.nativeElement as any;

    this.isAdmin = element.getAttribute('is-admin') === 'true';
    this.widget_id = Number(element.getAttribute('widget-id')) || undefined;
    this.height = Number(element.getAttribute('height')) || undefined;
    this.totalAssets = Number(element.getAttribute('total-assets')) || 0;
    this.show = element.getAttribute('show') === 'true';

    console.log("📢 Web Component Atualizado com Atributos: ", {
      isAdmin: this.isAdmin,
      widget_id: this.widget_id,
      height: this.height,
      totalAssets: this.totalAssets,
      show: this.show
    });

    this.cdr.detectChanges();
  }
  private handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && this.isPlayerVisible) {
      console.log("🔴 Tecla ESC pressionada, fechando modal...");
      this.closeModal();
    }
  };

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['show']) {
      this.cdr.detectChanges();
      //this.modalStateService.emitModalOpened(changes['show'].currentValue);
      await this.sortSlicksAndSendToPlayer();
    }
  }

  // return comentado
  teste() {

  }

  private transformVideoToSlick(video: VideoItem): Item[] {
    return [
      {
        schedule: {
          date_from: new Date().toISOString(),  // Define a data atual ou ajuste conforme necessário
          date_to: null  // Ou defina uma data se necessário
        },
        media: {
          type: 'video',  // Ajuste conforme necessário
          src: video.src,
          pin: 0,  // Valor padrão ou ajuste conforme a regra de negócio
          time_to_pitch: '',
          thumbnail: video.thumbnail || null,
          duration: null,  // Ajuste se necessário
          stream_status: null,
          status: 1,  // Ajuste conforme a lógica (ativo/inativo)
          aspect_ratio: '16/9'  // Ajuste conforme necessário
        },
        content: {
          id: video.id,
          uid: video.uid,
          title: 'Título Padrão1',
          description: 'Descrição Padrão',  // Defina uma descrição padrão ou ajuste conforme necessário
          cta: {
            components: {
              custom: [],
              whatsapp: [],
              products: {
                items: video.products || [],
              },
              promotions: {
                items: [],
              },
              links: [],
              extra: {
                ecom_layer: {
                  cart: { type: null, display: null, platform: null },
                  search_bar: { display: null },
                },
                chat_box: { display: { read: null, write: null } },
                promotions: { items: [] },
                player: {
                  auto_play: true,
                  fake_bar: false,
                  time_to_pitch: false,
                  time_to_pitch_value: '',
                  player_color: '#000000',
                  progress_bar_color: '#FFFFFF',
                },
                tags: '',
              },
              alerts: [],
            },
          },
        },
        extra: {
          ecom_layer: {
            cart: { type: null, display: null, platform: null },
            search_bar: { display: null },
          },
          chat_box: { display: { read: null, write: null } },
          promotions: { items: [] },
          player: {
            auto_play: true,
            fake_bar: false,
            time_to_pitch: false,
            time_to_pitch_value: '',
            player_color: '#000000',
            progress_bar_color: '#FFFFFF',
          },
          tags: '',
        },
        zIndex: 1
      }
    ];
  }



  workerBlob: Blob | null = null;
  workerBlobURL: string | null = null;
  private async sortSlicksAndSendToPlayer() {
    if (typeof Worker !== 'undefined' && this.videoUrls[0]?.uid) {

      this.workerBlob = new Blob([
        `
        self.addEventListener('message', ({ data }) => {
          const { slicks, targetUid } = data;

          const targetIndex = slicks.findIndex((item) => item?.content?.uid === targetUid);
          
          if (targetIndex === -1) {
            postMessage({ sorted: slicks, success: false }); 
          } else {
            const sortedSlicks = Array.from(slicks);
            const rotatedSlicks = sortedSlicks.slice(targetIndex).concat(sortedSlicks.slice(0, targetIndex));
            postMessage({ sorted: rotatedSlicks, success: true }); 
          }
        });
        `
      ], { type: 'application/javascript' });
      this.workerBlobURL = URL.createObjectURL(this.workerBlob);
      this.worker = new Worker(this.workerBlobURL);
      // return comentado
      return new Promise((resolve) => {
        if (!this.worker) {
          resolve(false);
          return;
        }

        this.worker.onmessage = ({ data }) => {
          if (data.success) {
            this.arraySlicksFull = data.sorted;
            this.setupSrcs();
            resolve(true);
          } else {
            console.error('Worker não encontrou o vídeo alvo');
            resolve(false);
          }
        };

        this.worker.onerror = (error) => {
          console.error('❌ Erro no Worker:', error);
          resolve(false);
        };

        this.worker.postMessage({
          slicks: this.arraySlicksFull,
          targetUid: this.videoUrls[0].uid
        });
      });
    }
    return Promise.resolve(false);
  }
  private setupSrcs() {
    if (this.show) {
      this.isPlayerVisible = true;
      if (this.selectedVideo) {
        const selectedSlick = this.arraySlicksFull.find(item => item.content.uid === this.selectedVideo?.uid);
        if (selectedSlick && selectedSlick.media) {
          this.selectedThumbnail = selectedSlick.media.thumbnail;
          this.isFullScreen = selectedSlick.media.aspect_ratio === '2';
        }
      }


      this.createVideoPlayerComponent(); // Cria o componente dinamicamente
      this.cdr.detectChanges();
    }



  }
  private closeModalTimeout?: ReturnType<typeof setTimeout>;
  async createVideoPlayerComponent() {

    if (!this.videoPlayerRef) {
      console.log("🆕 Criando PVideoPlayerComponent...");
      this.videoPlayerRef = this.videoPlayerContainer.createComponent(PVideoPlayerComponent);

      if (this.videoPlayerRef.instance) {
        this.videoPlayerRef.instance.store_id = this.store_id;
        //this.videoPlayerRef.instance.slicks = this.arraySlicksFull;
        this.videoPlayerRef.instance.slicks = this.transformVideoToSlick(this.selectedVideo!);
        this.videoPlayerRef.instance.slick_id = this.selectedVideo?.uid;
        this.videoPlayerRef.instance.widget_id = this.widget_id;
        this.videoPlayerRef.instance.isAdmin = this.isAdmin;

        // this.videoPlayerRef.instance.appendToContainer = parentContainer;
        // ✅ Passa o resetPlayers para dentro do PVideoPlayerComponent
        this.videoPlayerRef.instance.resetPlayers = this.resetPlayers;

        await this.videoPlayerRef.instance.initializeSlicks(this.arraySlicksFull, this.totalAssets, this.pageToVideos, 0);
        this.videoPlayerRef.instance.initializeAfterView(); // novo com teste

        // Escutar o evento allPlayersReady para ocultar a imagem de pôster
        this.videoPlayerRef.instance.allPlayersReady.subscribe(() => {
          this.cdr.detectChanges();
        });
      }
    } else {
      console.log("⚠️ PVideoPlayerComponent já criado, apenas atualizando inputs.");
      this.updateVideoPlayerInputs();
    }

  }

  private updateVideoPlayerInputs() {
    if (!this.videoPlayerRef?.instance || !this.selectedVideo) return;

    console.log('🟢 Atualizando PVideoPlayerComponent');

    this.isPlayerVisible = true;
    this.videoPlayerRef.instance.slick_id = this.selectedVideo?.uid;
    this.videoPlayerRef.instance.widget_id = this.widget_id;
    this.videoPlayerRef.instance.isAdmin = this.isAdmin;

    // Atualiza os slicks
    this.videoPlayerRef.instance.slicks = this.transformVideoToSlick(this.selectedVideo!);

    console.log('🔄 Atualizando initializeSlicks');
    this.videoPlayerRef.instance.initializeSlicks(this.arraySlicksFull, this.totalAssets, this.pageToVideos, 1);

    this.videoPlayerRef.instance.initializeAfterView();

    // ⛔ Cancela o timeout se o modal for reaberto antes de 1 minuto
    if (this.closeModalTimeout) {
      clearTimeout(this.closeModalTimeout);
      this.closeModalTimeout = undefined;
      console.log("⛔ Timeout cancelado! O modal foi reaberto.");
    }
  }
  resetPlayers: EventEmitter<void> = new EventEmitter<void>();
  // 🔥 Método para destruir o componente após 1 minuto sem uso
  private destroyComponent() {
    console.log("🔥 Destruindo VideoModalComponent...");
    this.ngOnDestroy();
  }
  closeModal() {
    console.log('chamou closeModal')
    this.close.emit();
    if (this.videoPlayerRef) {
      this.videoPlayerRef.instance.clearPlayerSources();
    }
    this.isPlayerVisible = false;

    // 🔹 Desconectar o MutationObserver
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
      this.mutationObserver = undefined;
      console.log("✅ MutationObserver encerrado.");
    }

    //this.modalStateService.emitModalOpened(false);
    this.worker?.terminate(); // Finaliza o worker ao fechar
    this.worker = undefined;

    if (this.workerBlobURL) {
      URL.revokeObjectURL(this.workerBlobURL);
      this.workerBlobURL = null;
    }

    // 🕒 Inicia o contador de 1 minuto para destruir o componente
    this.closeModalTimeout = setTimeout(() => {
      console.warn("⏳ 1 minuto sem uso! Destruindo componente...");
      this.destroyComponent();
    }, 60000);
  }
  private cleanupDOM() {
    // Se houver elementos criados dinamicamente, remova-os aqui
    if (this.videoPlayerContainer) {
      this.videoPlayerContainer.clear();
    }
  }
  selectedThumbnail: string | null = null;
  isFullScreen = false;


  ngOnDestroy(): void {

    // 1️⃣ Finalizar o Web Worker (se estiver ativo)
    if (this.worker) {
      this.worker.terminate();
      this.worker = undefined;
    }

    // 🔹 Desconectar o MutationObserver
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
      this.mutationObserver = undefined;
      console.log("✅ MutationObserver encerrado.");
    }

    // 2️⃣ Revogar o URL do Blob
    if (this.workerBlobURL) {
      URL.revokeObjectURL(this.workerBlobURL);
      this.workerBlobURL = null;
    }

    // 3️⃣ Destruir o componente dinâmico (se existir)
    if (this.videoPlayerRef) {
      this.videoPlayerRef.destroy();
      this.videoPlayerRef = null;
    }

    // 4️⃣ Desinscrever eventos de EventEmitter ou Observables
    if (this.videoPlayerRef && (this.videoPlayerRef as ComponentRef<PVideoPlayerComponent>).instance?.allPlayersReady) {
      // Type assertion para garantir que videoPlayerRef é um ComponentRef válido
      (this.videoPlayerRef as ComponentRef<PVideoPlayerComponent>).instance.allPlayersReady.unsubscribe();  // Desinscreve o evento
    }
    this.isPlayerVisible = false;
    // 5️⃣ Limpeza manual do DOM
    this.cleanupDOM();

  }
}
