import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Subscription } from 'rxjs';
import { PedidosService } from 'src/app/services/pedidos.service';
import { AvatarService } from 'src/app/services/avatar.service';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit, OnDestroy {
    breadcrumb!: string;
    @ViewChild('menubutton') menuButton!: ElementRef;

    avatarUrl: string | null = null; 
    private avatarSubscription!: Subscription;

    constructor(
        public layoutService: LayoutService,
        private activatedRoute: ActivatedRoute,
        private avatarService: AvatarService,
        private pedidosService: PedidosService,
        private router: Router
    ) { }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }
    isLoading: boolean = true;
    ngOnInit() {
        let user_id = localStorage.getItem('user_id');
        if (user_id === null) {
            console.error('User ID is not available in localStorage');
            // Você pode tratar esse caso como achar melhor
            this.isLoading = false;
        } else {
            let numericUserId = parseInt(user_id, 10);
            this.pedidosService.detailUser(numericUserId).subscribe({
                next: (response: any) => {

                    if (response.photo) {
                        this.avatarUrl = response.photo.split('?')[0];
                       
                    }else {
                        this.avatarUrl = 'assets/layout/images/avatar.png';  // Imagem padrão se `null`
                    }
                    this.isLoading = false;
                },
                error: (error: any) => {
                    this.isLoading = false;
                }
            });
        }

        this.avatarSubscription = this.avatarService.avatar$.subscribe(avatar => {
            this.avatarUrl = avatar;
            //this.isLoading = false;
        });
    }

    ngOnDestroy() {
        if (this.avatarSubscription) {
            this.avatarSubscription.unsubscribe();
        }
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

}