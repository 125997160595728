// src/app/components/p-video-player/services/s-change-source.service.ts

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { SVirtualIndexCalculationService } from './s-virtual-index-calculation.service';
@Injectable({
  providedIn: 'root'
})
export class SChangeSourceService {
  private renderer: Renderer2;
  constructor(
    private rendererFactory: RendererFactory2,
    private virtualIndexService: SVirtualIndexCalculationService) {
    this.renderer = rendererFactory.createRenderer(null, null);
   }

  // Método principal para alterar as fontes dos vídeos no Swiper
  changeSrcs(
    swiperElement: any,
    virtualIndex: number,
    directionMove: string | undefined,
    slicks: any[],
    realIndex: number,
    arrayPlayers: any[]
  ): void {
    const swiperInstance = swiperElement?.swiperRef;
    if (!swiperInstance) {
      console.error("Swiper não está inicializado corretamente.");
      return; // Retorne imediatamente para interromper a execução se o swiper não estiver inicializado
    }

    let forwardSrcIndex: number;
    let backwardSrcIndex: number;
    let nextIndex: number = 0;
    let prevIndex: number = 0;

    const activeIndex = realIndex;

    if (directionMove === 'next') {
      forwardSrcIndex = (virtualIndex + 1) % slicks.length;
      //const nextDuplicateSrcIndex = (virtualIndex - 1 >= 0) ? virtualIndex - 1 : slicks.length - 1;

      if (activeIndex === 0) nextIndex = 1;
      else if (activeIndex === 1) nextIndex = 2;
      else if (activeIndex === 2) nextIndex = 0;

      if (slicks[forwardSrcIndex]?.media) {
        this.updateVideoSrc(nextIndex, slicks[forwardSrcIndex].media.src, swiperElement, arrayPlayers, slicks[forwardSrcIndex].media.thumbnail);
      } else {
        console.error("Media not found for forwardSrcIndex");
      }
    } else if (directionMove === 'prev') {
      backwardSrcIndex = (virtualIndex - 1 >= 0) ? virtualIndex - 1 : slicks.length - 1;
      //const prevDuplicateSrcIndex = (virtualIndex + 1) % slicks.length;

      if (activeIndex === 0) prevIndex = 2;
      else if (activeIndex === 1) prevIndex = 0;
      else if (activeIndex === 2) prevIndex = 1;

      if (slicks[backwardSrcIndex]?.media) {
        this.updateVideoSrc(prevIndex, slicks[backwardSrcIndex].media.src, swiperElement, arrayPlayers, slicks[backwardSrcIndex].media.thumbnail);
      } else {
        console.error("Media not found for backwardSrcIndex");
      }
    }
  }


  // Método para atualizar o vídeo no slide específico
  updateVideoSrc(
    index: number,
    src: string,
    swiperElement: any,
    arrayPlayers: any[],
    poster: string
  ): void {

    src = this.removeUrlValidator(src);

    this.updateAllSlides(index, src, swiperElement, arrayPlayers, poster);
  }
  removeUrlValidator(url: string): string {
    return url.includes('?') ? url.split('?')[0] : url;
  }
  // Método para atualizar todos os slides
  updateAllSlides(
    index: number,
    src: string,
    swiperElement: any,
    arrayPlayers: any[],
    poster: string
  ): void {
    const newSrc = this.getVideoSrc(src);

    // Iterar por todos os elementos do array e atualizar se o index for igual
    arrayPlayers.forEach(playerEntry => {
      if (playerEntry.index === index) {
        const videoElement = playerEntry.player as HTMLVideoElement;
  
        if (videoElement) {
          const currentSrc = videoElement.src;
          if (currentSrc !== newSrc) {
            this.renderer.setProperty(videoElement, 'src', newSrc);
            videoElement.pause(); // 🔥 Sempre pausa antes de trocar a `src`
          }
        }
      }
    });
    let cleanedPoster = poster ? (poster.includes('?') ? poster.split('?')[0] : poster) : '';


    const swiperInstance = swiperElement?.swiperRef;
    if (swiperInstance) {
      swiperInstance.off('loopFix');
      swiperInstance.on('loopFix', () => {
        let duplicateSlidesFound = 0;
        swiperInstance.slides.forEach((slide: any) => {
          if (duplicateSlidesFound >= 2) return;
          const slideElement = slide as HTMLElement;
          const slideIndexAttr = slideElement.getAttribute('data-swiper-slide-index');
          if (slideIndexAttr !== null) {
            const slideIndex = parseInt(slideIndexAttr, 10);
            const isDuplicateSlide = slideElement.classList.contains('swiper-slide-duplicate');
            if (slideIndex === index) {
              const ssPosterElement = slideElement.querySelector('.ss-l-poster') as HTMLElement;
              if (ssPosterElement) {
                // Atualiza o `background-image` da classe `ss-l-poster`
                //ssPosterElement.style.backgroundImage = `url('${cleanedPoster}')`;
                this.renderer.setStyle(ssPosterElement, 'background-image', `url('${cleanedPoster}')`);
              }
              if (isDuplicateSlide) {
                duplicateSlidesFound++;
              }
            }
          }
        });
      });
      swiperInstance.emit('loopFix');
    }
  }

  // Função auxiliar para obter a `src` do vídeo
  private getVideoSrc(src: string): string {
    return src;
  }

  // Função auxiliar para criar o poster
  private createPosterSrc(src: string): string {
    return `https://res.cloudinary.com/slicksell/video/fetch/so_0/f_webp/e_blur:1000/${src}`;
  }

  // Funções auxiliares para definir os índices
  private getNextIndex(activeIndex: number): number {
    return (activeIndex === 0) ? 1 : (activeIndex === 1) ? 2 : 0;
  }

  private getPrevIndex(activeIndex: number): number {
    return (activeIndex === 0) ? 2 : (activeIndex === 1) ? 0 : 1;
  }
}
