<p-dataView #dv [value]="videoUrls" [paginator]="false" [rows]="12" filterBy="name" [rowsPerPageOptions]="[12, 24, 96]"
    layout="grid" [ngClass]="{ 'center-items': isCol4 }">
    <ng-template let-video pTemplate="gridItem">
        <div class="grid-item" (click)="openModal(video)">
            <div class="ss-l-video-container-widget">
                <video [poster]="video.thumbnail">
                    <source [src]="video.src" type="video/mp4">
                    Seu navegador não suporta a tag de vídeo.
                </video>
            </div>
        </div>
    </ng-template>
</p-dataView>
<!-- Botão para carregar mais vídeos -->
<div class="button-container" *ngIf="hasMoreVideos">
    <button pButton pRipple label="Carregar Mais" [loading]="isLoadingSlides" (click)="loadMore()"
        class="ml-2 mr-2"></button>
</div>
<!-- Modal Carousel -->
<app-video-modal *ngIf="!isWebComponent" #modalContainer [isAdmin]="isAdmin" [videoUrls]="selectedVideoUrls" [selectedVideo]="selectedVideoUrls[0]" [show]="isModalOpen" [widget_id]="widget_id" [arraySlicksFull]="arraySlicksFull" [totalAssets]="totalAssets"
    (close)="closeModal()" style="visibility: hidden;"></app-video-modal>