import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewContainerRef, ComponentRef, Output, EventEmitter, ViewEncapsulation, ElementRef, AfterViewInit, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { ViewportService } from 'src/app/services/viewport.service'; // Importa o serviço
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PPlayerComponent } from '../p-player/p-player.component';
import { SkeletonModule } from 'primeng/skeleton';
import { ChipModule } from "primeng/chip";
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ShowProductsComponent } from 'src/app/components/show-products/show-products.component';
import { SidebarModule } from 'primeng/sidebar';
import { CarouselModule } from 'primeng/carousel';
import { PVideoPlayerComponent } from '../p-video-player/p-video-player.component';
import { Item } from 'src/app/models/liveshop';
import { VideoItem } from 'src/app/models/video-item.model';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-inline',
  standalone: true,
  templateUrl: './inline.component.html',
  styleUrls: ['./inline.component.scss'],
  imports: [CommonModule, PPlayerComponent, AvatarGroupModule, ShowProductsComponent, SidebarModule, ButtonModule, AvatarModule, ChipModule, CarouselModule, VideoModalComponent, SkeletonModule, PVideoPlayerComponent],
  encapsulation: ViewEncapsulation.Emulated,
})
export class InlineComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() height?: number | undefined;
  // 
  @ViewChild('videoPlayerContainer', { read: ViewContainerRef, static: true }) videoPlayerContainer!: ViewContainerRef;
  private videoPlayerRef?: ComponentRef<PVideoPlayerComponent> | null;
  @Input() showProducts?: boolean = false;
  @Input() isAdmin: boolean = true;
  @Input() showDescription?: boolean = false;
  @Input() widget_id?: number | undefined;
  @Input() store_id?: number | undefined = 163;
  @Input() template_id?: number | undefined;
  @Input() aspectRatio?: any = '540 / 540';
  @Input() templateType: string | undefined;
  @Input() isCol4: boolean = false;
  isMobile: boolean = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  @Output() openSidebar = new EventEmitter<void>();
  private resizeObserver?: ResizeObserver;
  loading: boolean = true;
  constructor(
    private viewportService: ViewportService,
    private sidebarService: SidebarService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private host: ElementRef
  ) { }
  iframeUrl: SafeResourceUrl | null = null;

  onVideoLoaded(): void {
    this.loading = false;
  }
  // Flag para controlar o estado do carousel
  showCarousel: boolean = false;

  // Método para alternar o estado
  toggleCarousel(event: MouseEvent) {
    event.stopPropagation(); // Evita propagação do clique
    this.showCarousel = !this.showCarousel;
    console.log('toggleCarousel chamado:', this.showCarousel);
  }
  isWebComponent: boolean = false;
  ngOnInit(): void {
    this.isWebComponent = !!document.querySelector('video-modal-widget');
    /////////////////////////////////////////
    console.log('ngOnInit inline');
    //this.createVideoPlayerComponent();
  }

  @Input() videoUrls: VideoItem[] = [];
  selectedVideoUrl: VideoItem = {
    uid: '',
    id: -1,
    src: '',
    poster: '',
    products: []  // ✅ Inicialização como array vazio
  };
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;


  showAllProductsSidebar2: boolean = false;
  selectedSku: number = 0;
  openProductSidebar(sku: string): void {
    let skuformated = Number(sku)
    console.log('sku selecioando: ' + sku)

    this.selectedSku = skuformated;
    this.showAllProductsSidebar2 = true;

    // Abrir a sidebar e passar o SKU para o ShowProductsComponent
    this.sidebarService.setSku(skuformated);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['height']) {
      console.log('height foi atualizada:', changes['height'].currentValue);
      this.calculateLayout();
    }
    if (changes['aspectRatio']) {
      console.log('aspectRatio foi atualizada:', changes['aspectRatio'].currentValue);
      this.calculateLayout();
    }
    if (changes['store_id']) {
      console.log('store_id foi atualizada:', changes['store_id'].currentValue);
    }
    if (changes['template_id']) {
      console.log('template_id foi atualizada:', changes['template_id'].currentValue);
    }
    if (changes['isCol4']) {
      console.log('isCol4 foi atualizada inline:', changes['isCol4'].currentValue);
    }
    if (changes['videoUrls'] && this.videoUrls.length > 0) {
      this.selectedVideoUrl = this.videoUrls[0]; // Pegando apenas o primeiro vídeo
      if (this.videoPlayer) {
        const videoElement = this.videoPlayer.nativeElement;
        videoElement.load(); // Recarrega o vídeo quando a URL é alterada
      }
      this.currentVideoIndex = 0;
      this.slickDesc = this.selectedVideoUrl?.description;
      this.handleTruncatedDesc();
      this.updateNextVideoDetails();

      this.updateVideoThumbnails();
      this.updateVideoPlayer();
    }
    if (changes['showDescription']) {
      console.log('showDescription foi atualizada:', changes['showDescription'].currentValue);
      this.selectedVideoUrl = this.videoUrls[0];
      this.slickDesc = this.selectedVideoUrl?.description;


    }
    if (changes['widget_uid']) {
      console.log('widget_uid foi atualizada:', changes['widget_uid'].currentValue);
    }
  }
  prevVideoThumbnail: string | undefined = '';
  selectedVideoThumbnail: string | undefined = '';
  nextVideoThumbnail: string | undefined;
  showPrevVideoButton: boolean = false;
  showNextVideoButton: boolean = false;
  currentVideoIndex: number = 0;
  playNextVideo(): void {
    if (this.currentVideoIndex < this.videoUrls.length - 1) {
      this.currentVideoIndex++;
      this.updateVideoThumbnails();
      this.updateVideoPlayer();
    }
  }

  playPrevVideo(): void {
    if (this.currentVideoIndex > 0) {
      this.currentVideoIndex--;
      this.updateVideoThumbnails();
      this.updateVideoPlayer();
    }
  }
  private updateVideoThumbnails(): void {
    this.selectedVideoThumbnail = this.videoUrls[this.currentVideoIndex]?.thumbnail || '';
    this.prevVideoThumbnail =
      this.currentVideoIndex > 0
        ? this.videoUrls[this.currentVideoIndex - 1]?.thumbnail || ''
        : undefined;
    this.nextVideoThumbnail =
      this.currentVideoIndex < this.videoUrls.length - 1
        ? this.videoUrls[this.currentVideoIndex + 1]?.thumbnail || ''
        : undefined;

    this.showPrevVideoButton = this.currentVideoIndex > 0;
    this.showNextVideoButton = this.currentVideoIndex < this.videoUrls.length - 1;
  }

  private updateVideoPlayer(): void {
    this.selectedVideoUrl = this.videoUrls[this.currentVideoIndex];
    if (this.videoPlayer) {
      const videoElement = this.videoPlayer.nativeElement;
      videoElement.load();
      videoElement.play();
      this.isPlaying = true;
    }
  }
  private updateNextVideoDetails(): void {
    const nextIndex = this.currentVideoIndex + 1;
    this.showNextVideoButton = nextIndex < this.videoUrls.length;

    // Atualizar a thumbnail do próximo vídeo, se existir
    this.nextVideoThumbnail = this.showNextVideoButton
      ? this.videoUrls[nextIndex].thumbnail || 'assets/imgTmp.webp' // Thumbnail padrão caso não exista
      : undefined;
  }

  showBtnVerMais: boolean = true;
  showBtnVerMaisFechar: boolean = false;
  truncatedDesc?: string = '';
  fullViewActive: boolean = false;
  slickDesc?: string = '';
  goVerMaisFechar() {
    this.fullViewActive = false;
    this.showBtnVerMais = true;
    this.showBtnVerMaisFechar = false;
    this.handleTruncatedDesc();
    this.bottomBarHeight = 60;
  }
  handleDivClick(event: Event): void {
    event.stopPropagation(); // Isso sempre será chamado.
    if (this.fullViewActive) {
      this.goVerMaisFechar();
    }
  }
  goVerMais() {
    this.fullViewActive = true;
    this.showBtnVerMaisFechar = true;
    this.showBtnVerMais = false;
    // this.center = this.center - 8;
    // this.bottom3 = this.bottom3 + 8;
    this.truncatedDesc = this.slickDesc;
    this.updateBottomBarHeight();
  }
  @ViewChild('bottomBar') bottomBar!: ElementRef;

  bottomBarHeight = 60;
  updateBottomBarHeight() {
    if (this.bottomBar) {
      this.bottomBarHeight = 100; // Garante um valor padrão
    }
  }
  @Input() viewMode: number = 1;
  handleTruncatedDesc(): void {
    if (!this.slickDesc) {
      this.truncatedDesc = ''; // Garantia de estado vazio
      this.showBtnVerMais = false;
      return;
    }

    if (this.viewMode === 1) {
      // Verifica se precisa truncar (modo compacto)
      if (this.slickDesc.length > 25) {
        this.truncatedDesc = `${this.slickDesc.substring(0, 25)}...`;
        this.showBtnVerMais = true;
      } else {
        this.truncatedDesc = this.slickDesc;
        this.showBtnVerMais = false;
      }
    } else {
      // Verifica se precisa truncar (modo expandido)
      if (this.slickDesc.length > 120) {
        this.truncatedDesc = `${this.slickDesc.substring(0, 120)}...`;
        this.showBtnVerMais = true;
      } else {
        this.truncatedDesc = this.slickDesc;
        this.showBtnVerMais = false;
      }
    }
  }

  // Função para emitir o evento quando o botão for clicado
  onOpenSidebar(): void {
    this.openSidebar.emit();
  }
  // Referência para a div de classe `s-s-l-inlineDiv`
  @ViewChild('inlineDiv', { static: true }) inlineDiv!: ElementRef;
  ngAfterViewInit(): void {
    if (!this.inlineDiv) {
      return;
    }
    if (this.videoUrls && this.videoUrls.length > 0) {
      // Observador para monitorar mudanças no tamanho da div
      this.resizeObserver = new ResizeObserver(() => {
        if (this.videoUrls && this.videoUrls.length > 0) {
          this.calculateLayout();
        }

      });
      this.resizeObserver.observe(this.inlineDiv.nativeElement);
    }
  }
  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

  }
  private calculateLayout(): void {
    if (!this.inlineDiv || !this.inlineDiv.nativeElement) {
      return;
    }
    if (this.videoUrls && this.videoUrls.length > 0) {
      let width = this.inlineDiv.nativeElement.offsetWidth;
      this.viewportService.setDivWidth(width); // Atualiza o Signal com a nova largura
      this.viewportService.setTemplateType(this.templateType); // Atualiza o Signal com a nova largura
      console.log('aspect ratio em inline:  ' + this.aspectRatio)

      let numericAspectRatio: number;
      if (typeof this.aspectRatio === 'string') {
        try {
          numericAspectRatio = eval(this.aspectRatio); // Converte "540 / 540" para 1
        } catch (e) {
          console.error('Erro ao avaliar aspectRatio:', e);
          numericAspectRatio = 1; // Valor padrão caso haja erro
        }
      } else {
        numericAspectRatio = this.aspectRatio;
      }
      const maxHeight = 30 * 16;
      // Calcular a altura com base na largura e numericAspectRatio
      if (numericAspectRatio && numericAspectRatio > 0) {
        let height = width / numericAspectRatio; // Cálculo da altura
        if (height > maxHeight) {
          height = this.inlineDiv.nativeElement.offsetHeight;

          // width = height*numericAspectRatio;
          // this.viewportService.setDivWidth(width);
        }

        console.log('Altura calculada: ' + height)

        this.viewportService.setDivHeight(height); // Atualiza altura no serviço
      }
    }
  }


  isPlaying: boolean = true; // Para gerenciar o estado de play/pause
  isMuted: boolean = true;   // Para gerenciar o estado de mute/unmute

  togglePlay(): void {
    if (this.videoPlayer) {
      const videoElement = this.videoPlayer.nativeElement;
      if (videoElement.paused) {
        videoElement.play();
        this.isPlaying = true;
      } else {
        videoElement.pause();
        this.isPlaying = false;
      }
    }
  }

  toggleMute(): void {
    this.isMuted = !this.isMuted;
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.muted = this.isMuted;
    }
  }
  isModalOpen = false;
  @Input() arraySlicksFull: Item[] = [];
  @Input() totalAssets: number = 0;
  @Output() modalOpened = new EventEmitter<VideoItem>();
  selectedVideoUrls: VideoItem[] = [];
  openModal(video: VideoItem) {
    if (this.videoPlayer) {
      const videoElement = this.videoPlayer.nativeElement;
      if (!videoElement.paused) {
        videoElement.pause();
        this.isPlaying = false;
      }
    }

    console.log('cliclou em openModal: ', video);
    this.selectedVideoUrls = [video];
    this.isModalOpen = true;
    this.modalOpened.emit(video);

    // 🔹 Obtendo o Web Component no DOM
    const videoModal = document.getElementById('videoModal') as any;

    if (videoModal) {
      console.log('✅ Encontrou video-modal-widget no DOM');

      // 🔹 Definir atributos no Web Component
      videoModal.setAttribute('is-admin', this.isAdmin.toString());
      videoModal.setAttribute('widget-id', this.widget_id?.toString() || '');
      videoModal.setAttribute('height', this.height?.toString() || '');
      videoModal.setAttribute('total-assets', this.totalAssets.toString());
      videoModal.setAttribute('show', 'true');

      // 🔹 Passar propriedades diretamente para o Web Component
      videoModal.videoUrls = [...this.selectedVideoUrls];
      videoModal.selectedVideo = this.selectedVideoUrls[0];
      videoModal.arraySlicksFull = this.arraySlicksFull;
      // videoModal.pageToVideos = this.pageToVideos;
      // videoModal.template_id = this.template_id;
      videoModal.store_id = this.store_id;
      // videoModal.aspectRatio = this.aspectRatio;

      // 🔹 Chamar o método `showModal()` do Web Component, se existir
      if (typeof videoModal.showModal === 'function') {
        videoModal.showModal();
      } 
    }


  }
}
