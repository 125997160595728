import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfGeraisService } from 'src/app/services/conf-gerais.service';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'app-email-reset',
  templateUrl: './email-reset.component.html',
  styleUrls: ['./email-reset.component.scss']
})
export class EmailResetComponent implements OnInit {
  new_email: string = '';
  criarLoagind: boolean = false;
  errorDisponibilidadeEmal: boolean = false;
  errorEmail: boolean = false;
  issueForm?: FormGroup;
  token: string = '';
  user_id?: number;
	get dark(): boolean {
		return this.layoutService.config.colorScheme !== 'light';
	}
  constructor(
    public fb: FormBuilder,
    private layoutService: LayoutService,
    private configService: ConfGeraisService,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) {
    this.issueForm = this.fb.group({
      // defina os controles do formulário aqui
      // exemplo: 'name': ['']
    });
  }

  ngOnInit(): void {
    // Se inscreve nos queryParams
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      const email = params['email'];
      const user = params['user'];

      // Defina esses valores em seu formulário ou variáveis conforme necessário
      if (token) {
        this.token = decodeURIComponent(token); // Decode o token para garantir que ele corresponda ao valor original
      }
      if (email) {
        this.new_email = decodeURIComponent(email); // Decode o e-mail que pode ter caracteres codificados
      }

      this.user_id = user;
      // Você pode fazer algo com o user também, se necessário
      console.log('User:', user);
    });
  }


  private debounceTimer: any = null;
  checkEmail(): void {
    this.errorEmail = false;
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.errorEmail = !this.isValidEmail(this.new_email);
    }, 1500);
  }
  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  salvarEmail() {
    // Verifica se o e-mail é vazio
    if (!this.new_email.trim()) {  // Usando trim() para checar espaços em branco também
      this.errorEmail = true;
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Por favor, insira um e-mail válido.'
      });
      return;  // Sai do método se não há e-mail
    }

    // Verifica se o e-mail é válido usando a função existente
    if (!this.isValidEmail(this.new_email)) {
      this.errorEmail = true;
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'O e-mail fornecido não é válido.'
      });
      return;  // Sai do método se o e-mail não é válido
    }

    // Verifica se o user_id está definido
    if (this.user_id === undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Usuário não identificado.'
      });
      return;  // Sai do método se não há um user_id definido
    }


    this.criarLoagind = true;
    this.issueForm = this.fb.group({
      "email": this.new_email,
      "token": this.token
    });
    console.log(this.token)

    this.configService.insertNewEmail(this.issueForm.value, this.user_id).subscribe({
      next: (res: any) => {
        console.log('res', res);
        this.criarLoagind = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Email ALterado',
          detail: 'O email foi alterado com sucesso!'
        });


        this.router.navigate(['/']);
      },
      error: (error: any) => {
        this.criarLoagind = false;
        let message = '';
        if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 422 || error.status === 405) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }
      }
    });

  }
  showErrorDialog(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Erro',
      icon: 'pi pi-times',
      accept: () => {
      }
    });
  }
}
