<div #overlayContainer class="flex align-items-center overlayContainer justify-content-center ss-container-to-sidebar">
    <p-splitter #splitter [panelSizes]="splitterSizes" [style]="{ height: '100%' }"
        class="col-12 p-0 unselectable ss-l-secondDivVideo" layout="vertical" (onResizeStart)="onSplitterResizeStart()" (onResizeEnd)="onSplitterResizeEnd($event)">
        <ng-template pTemplate>
            <div #splitterTop class="splitter-panel ">
                <div (click)="$event.stopPropagation();"
                    style="position: absolute; left: 0rem; right: 0rem; background-color: transparent !important;height: 100%;">
                    <div class="ss-g-controls aaa" *ngIf="isPaused">
                        <div class="text-white" *ngIf="showPlayPauseButton">
                            <button (click)="togglePlayPause(); $event.stopPropagation();" #playPauseButton
                                class="ss-l-control-button text-2xl zoomin animation-duration-100 cursor-pointer"
                                style="z-index: 1002;" pButton pRipple [ngStyle]="{'background-color': playPauseColor}">
                                <i class="bi bi-play-fill " *ngIf="isPaused" [ngStyle]="{'color': iconColor}"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <swiper [config]="swiperConfig" *ngIf="InicialSlicks.length"
                    class="swiper-container ss-l-swiper-videos swiper" #swiperElement (swiper)="onSwiper($event)"
                    (wheel)="onScroll($event)" (touchmove)="onScroll($event)"
                    (activeIndexChange)="onActiveIndexChange($event)">
                    <ng-template swiperSlide *ngFor="let slick of InicialSlicks; let i = index; trackBy: trackByFn">
                        <div #videoUploadContainer class="relative video-container 123"
                            [ngClass]="{'ss-g-video-container': viewMode === 1,}" (click)="togglePlayPause()">

                            <div class="videoUploadContainer flex" #vc2 [ngClass]="{
                    'ss-g-view-mode-mobile': aspect_ratio === '1',
                    'ss-g-view-mode-desktop': aspect_ratio === '2',
                    'ss-g-view-mode-desktop-vertical': aspect_ratio === '2' && viewMode === 2
                  }">

                                <div #videoInner class="text-center flex video-inner modoTeatro" [ngClass]="{
                        'video-upload-container-full-vertical-desktop': (aspect_ratio === '1'),
                        'ss-g-view-mode-mobile-inner': aspect_ratio === '1',
                        'ss-g-video-container-inner-landscape': aspect_ratio === '2' }">
                                    <video #videoElement id="my-video-{{i}}" [class]="'my-video-' + i "
                                        [ngStyle]="dynamicOPC"
                                        class="video-preview ss-l-muteClass ss-l-video" muted autoplay loop
                                        playsinline webkit-playsinline preload="auto">
                                    </video>
                                    <div class="ss-l-poster"
                                        [ngStyle]="{'background-image': 'url(' + slick.media.thumbnail + ')'}">
                                    </div>
                                </div>


                            </div>


                        </div>
                        <!-- grid aqui obs-->

                        <!-- fim grid aqui obs-->
                        <!-- btnsssss-->
                        <div _ngcontent-ng-c1845266451=""
                            class="text-center ss-l-btns-right-inf mb-4 ss-g-alignItensEnd ss-l-right-btns">
                            <div>
                                <button *ngIf="!screenChat" (click)="toggleChat(); $event.stopPropagation();" pButton
                                    pRipple
                                    class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right">
                                    <i class="bi bi-chat-fill"></i>
                                </button>
                                <button *ngIf="screenChat" (click)="toggleChat(); $event.stopPropagation();" pButton
                                    pRipple
                                    class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right">
                                    <i class="bi bi-chat-fill"></i>
                                </button>
                            </div>
                            <div>


                                <button (click)="triggerHeartAnimation(); $event.stopPropagation();"
                                    class="m-0 border-none ss-g-custom-bg ss-g-btns-menu-sup ss-g-btns-menu-right"
                                    pButton pRipple [ngClass]="{'liked': isActive}">
                                    <i class="bi bi-heart-fill" [ngClass]="{'ss-g-heartBgColor': isActive}"
                                        style="position: relative;top:3px;"></i>
                                </button>

                            </div>
                            <div>
                                <button pButton pRipple class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right"
                                    (click)="compartilhar();$event.stopPropagation();" style="border: none;">
                                    <i class="bi bi-share-fill"></i>
                                </button>
                            </div>
                            <div>
                                <button pButton pRipple type="button"
                                    (click)="menu.toggle($event);$event.stopPropagation();"
                                    class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right">
                                    <i class="bi bi-three-dots-vertical"></i>
                                </button>
                                <p-menu #menu class="s-g-menuLoja" [model]="getActionMenu()" [popup]="true"
                                    appendTo="body" [baseZIndex]="10001"
                                    overlayOptions="{ showTransitionOptions: '0ms', hideTransitionOptions: '0ms' }"></p-menu>
                            </div>
                            <div>
                                <button pButton pRipple (mousemove)="showVolumeControl()"
                                    (click)="toggleMute(); $event.stopPropagation();" (touchstart)="showVolumeControl()"
                                    class="m-0 border-none ss-g-custom-bg  ss-g-btns-menu-right">
                                    <i [ngClass]="isVideoMuted() ? 'bi bi-volume-mute-fill' : 'bi bi-volume-up'"></i>
                                </button>
                            </div>
                        </div>
                        <!-- btnsssss-->

                    </ng-template>

                </swiper>
                <div _ngcontent-ng-c1580141953="" class="pl-3 ss-l-titleDesc mb-3">
                    <div class="text-white w-full font-bold select-none text-left ss-l-element mb-1" #targetElement>
                        {{slickTitles[realActiveIndex]}}
                    </div>
                    <div class="text-white w-full mb-2 cursor-pointer select-none ss-l-element ss-l-description"
                        #targetElement (click)="handleDivClick($event); $event.stopPropagation();"
                        style="text-align: left; max-height: auto; overflow-y: auto;"
                        [ngStyle]="{'max-height': dynamicHeight, 'overflow-y': 'auto'}"
                        (touchstart)="preventSwiperOnTouchStart($event)" (touchmove)="preventSwiperOnTouchMove($event)">
                        {{truncatedDescs[realActiveIndex]}}
                        <a (click)="goVerMais(); $event.stopPropagation();" class="text-white cursor-pointer"
                            *ngIf="showBtnVerMais[realActiveIndex] && truncatedDescs[realActiveIndex].length > 0"><b>ver
                                mais</b></a>

                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div #splitterBottom class="splitter-panel  align-items-end bottom-panel">
                <div class="ss-l-firstDivAfterSwiper align-items-start bottom-panel-inner">
                    <div style="z-index: 999;position: relative;display: flex;flex-direction: column;justify-content: flex-end;gap: 0.5rem;"
                        class="w-full ">

                        <p-tabView class="my-fixed-height-tabview">
                            <p-tabPanel [header]="'('+totalProducts + ') Produtos'">
                                <div class="w-full ss-l-element">
                                    <swiper *ngIf="productsPerSlide().length > 0" [config]="configSwiperSecond"
                                        class="mySwiperSecond" #swiperSecond>
                                        <ng-template swiperSlide
                                            *ngFor="let product of productsPerSlide(); trackBy: trackByFn">
                                            <div class="link-container cursor-pointer "
                                                (click)="openProductSidebar(product.sku);$event.stopPropagation();">
                                                <div class="link-card"
                                                    [ngStyle]="{'background-color': 'rgba(128, 128, 128, 0.5)'}">
                                                    <!-- Área da Imagem -->
                                                    <div class="link-image"
                                                        [ngStyle]="{'background-color': 'rgba(128, 128, 128, 0.5)'}">
                                                        <img [src]="product.thumbnail" [alt]="product.name"
                                                            class="image-style" loading="lazy">
                                                    </div>

                                                    <!-- Conteúdo do Texto (Nome e Preço empilhados) -->
                                                    <div class="link-name2 text-sm text-white flex flex-column"
                                                        [ngStyle]="{'background-color': 'rgba(128, 128, 128, 0.5)'}">
                                                        <div class="text-white font-bold mb-1 text-left">
                                                            {{product.name}}</div>
                                                        <div class="text-xs text-bluegray-200">${{product.price}}</div>
                                                    </div>

                                                    <!-- Ícone à Direita -->
                                                    <div class="link-icon"
                                                        [ngStyle]="{'background-color': 'rgba(128, 128, 128, 0.5)'}">
                                                        <i class="bi bi-chevron-right"
                                                            style="position: relative; top: 0px;"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </swiper>

                                </div>
                            </p-tabPanel>
                            <p-tabPanel [header]="'('+totalLinks + ') Links'">
                                <div _ngcontent-ng-c1580141953="" class="w-full ss-l-element">
                                    <swiper *ngIf="filteredLinks().length > 0" [config]="configSwiperThird" #swiperThird
                                        class="mySwiperThird ss-g-fade-out-swiper">
                                        <ng-template swiperSlide
                                            *ngFor="let link of filteredLinks(); trackBy: trackByFn">
                                            <div class="link-container cursor-pointer "
                                                (click)="handleLinkClick(link); $event.stopPropagation();">
                                                <div class="link-card"
                                                    [ngStyle]="{'background-color': convertToRgba(link.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                                    <!-- Área da Imagem -->
                                                    <div class="link-image"
                                                        [ngStyle]="{'background-color': link.bg_color}">
                                                        <img [src]="link.click_url" [alt]="link.name"
                                                            class="image-style" loading="lazy">
                                                    </div>
                                                    <!-- Conteúdo do Texto -->
                                                    <div class="link-name text-sm text-white"
                                                        [ngStyle]="{'background-color': convertToRgba(link.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                                        <div class="text-white">{{link.name}}</div>
                                                    </div>
                                                    <!-- Ícone da Direita -->
                                                    <div class="link-icon"
                                                        [ngStyle]="{'background-color': convertToRgba(link.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                                        <i class="bi bi-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </swiper>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel [header]="'('+totalShorts + ') Shorts'">
                                <div _ngcontent-ng-c1580141953="" class="w-full ss-l-element">
                                    <swiper *ngIf="filteredShortcuts().length > 0" [config]="configSwiperShortcuts"
                                        class="mySwiperShortcuts">
                                        <ng-template swiperSlide
                                            *ngFor="let shortcut of filteredShortcuts(); trackBy: trackByFn">
                                            <div class="link-container cursor-pointer"
                                                (click)="handleLinkClick(shortcut); $event.stopPropagation();">
                                                <div class="link-card"
                                                    [ngStyle]="{'background-color': convertToRgba(shortcut.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                                    <!-- Área da Imagem -->
                                                    <div class="link-image"
                                                        [ngStyle]="{'background-color': shortcut.bg_color}">
                                                        <img src="{{shortcut.click_url}}" [alt]="shortcut.name"
                                                            class="image-style" loading="lazy">
                                                    </div>
                                                    <!-- Conteúdo do Texto -->
                                                    <div class="link-name text-sm text-white"
                                                        [ngStyle]="{'background-color': convertToRgba(shortcut.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                                        <div class="text-white">{{shortcut.name}}</div>
                                                    </div>
                                                    <!-- Ícone da Direita -->
                                                    <div class="link-icon"
                                                        [ngStyle]="{'background-color': convertToRgba(shortcut.bg_color ?? 'rgb(0, 0, 0)', 0.5)}">
                                                        <i class="bi bi-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </swiper>

                                </div>
                            </p-tabPanel>
                            <p-tabPanel header="Btn Custom">
                                <div _ngcontent-ng-c1580141953="" class="w-full ss-l-element">
                                    <div #targetElement class="ss-l-custom-btn-screen ss-l-element"
                                        style="display: flex; justify-content: flex-start;">
                                        <div *ngIf="visibleCustomButton() as button" class="w-full md:max-w-25rem">
                                            <button pButton pRipple
                                                class="font-bold py-1 ss-g-btn-screen-custom border-none ss-l-w-full md:max-w-25rem ss-l-scaleMobile-btn"
                                                [ngStyle]="{'color': button.font_color_custom, 'background-color': button.bg_color_custom}"
                                                (click)="handleLinkClickShortcut(button); $event.stopPropagation()">
                                                {{ button.button_text }}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </div>
                <div *ngIf="screenChat" class="ss-l-secondDivAfterSwiper align-items-end bottom-panel-inner">
                    <!-- chat -->
                    <!-- Container do chat -->
                    <div class="chat-container" [ngClass]="{'open': screenChat}">
                        <div class="chat-header">
                            <span class="text-xl font-bold">Comentários</span>
                            <!-- Opcional: botão para fechar -->
                            <button (click)="toggleChat(); $event.stopPropagation();">Fechar</button>
                        </div>
                        <div class="chat-content">
                            <app-chat-app #chatBoxComponent [admin]="false" [showSidebar]="sidebarChat"
                                [chatId]="chatId" [showTopChat]="false">
                            </app-chat-app>
                        </div>
                    </div>

                    <!-- chat -->
                </div>
            </div>
        </ng-template>
    </p-splitter>

    <p-sidebar [(visible)]="showAllShortucutLinks" (onHide)="onClose()" (onShow)="adjustOverlayZIndex()"
        class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom z-9 p-0" position="bottom">
        <ng-template pTemplate="header">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <span class="text-xl font-bold">Links</span>
            </div>
        </ng-template>
        <!-- Container Flex para alinhar os links lado a lado -->
        <div class="ss-g-custom-container cursor-pointer">
            <div *ngFor="let link of getFlattenedShortcuts(realActiveIndex); trackBy: trackByFn" class="ss-g-links2"
                (click)="handleLinkClickShortcut(link); $event.stopPropagation();">
                <img [src]="link.click_url" class="border-circle ss-g-maxheight-links-adds" loading="lazy" />
                <div class="ss-g-maxWidthName-links-adds">{{ link.name }}</div>
            </div>
        </div>
    </p-sidebar>

    <p-sidebar [(visible)]="showAllProductsSidebar" [position]="isMobile ? 'bottom' : 'right'" (onHide)="onClose()"
        (onShow)="adjustOverlayZIndex()"
        class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom ss-l-productsSidebar z-9 p-0"
        [ngStyle]="isMobile ? { 'max-height': '50vh', 'overflow-y': 'auto' } : { }">

        <ng-template pTemplate="header">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <span class="text-xl font-bold">Produtos</span>
            </div>
        </ng-template>

        <!-- Grid Responsiva com até 2 produtos por linha em Mobile -->
        <div class="grid p-3">
            <div *ngFor="let product of productsPerSlide(); trackBy: trackByFn"
                class="col-6 md:col-6 lg:col-6 xl:col-6 p-2">

                <div class="shadow-2 p-3 surface-card border-round h-full flex flex-column justify-content-between cursor-pointer"
                    (click)="openProductSidebar(product.sku);$event.stopPropagation();">

                    <!-- Imagem do Produto (Ajustada para caber 2 por linha) -->
                    <div class="relative mb-2 text-center">
                        <img [src]="product.thumbnail || 'assets/imgTmp.webp'" [alt]="product.name" class="border-round"
                            style="width: 100%; height: 150px; object-fit: cover;">
                    </div>

                    <!-- Nome e Avaliação -->
                    <div class="flex justify-content-between align-items-center mb-2">
                        <span class="text-900 font-medium text-sm text-left">{{ product.name }}</span>
                    </div>

                    <!-- Preço -->
                    <span class="text-primary text-md font-medium mb-2">
                        R$ {{ product.price }}
                    </span>

                </div>
            </div>
        </div>

    </p-sidebar>
    <p-sidebar [(visible)]="showAllProductsSidebar2" [position]="isMobile ? 'bottom' : 'right'" (onHide)="onClose()"
        (onShow)="moveOverlayToComponent()"
        class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom ss-l-productsSidebar z-9 p-0"
        [ngStyle]="isMobile ? { 'max-height': '50vh', 'overflow-y': 'auto' } : { }">

        <!-- ✅ Ícone do Carrinho no topo da sidebar -->
        <div class="flex justify-content-between align-items-center px-4 py-3 border-bottom-1 surface-border">
            <span class="text-lg font-bold">Detalhes do Produto</span>
            <button pButton class="p-button-text p-button-rounded p-button-lg p-overlay-badge"
                (click)="openCartSidebar()">
                <i class="bi bi-cart3 p-overlay-badge " style="font-size: 1.5rem;"></i>

                <!-- 🔥 Substituímos pBadge por um span customizado -->
                <span *ngIf="(cartCount$ | async) as count" class="ss-l-custom-badge" [hidden]="count <= 0">
                    {{ count }}
                </span>
            </button>
        </div>

        <app-show-products [sku]="selectedSku"></app-show-products>

    </p-sidebar>

    <p-sidebar [(visible)]="showCartSidebar" [position]="isMobile ? 'bottom' : 'right'" (onHide)="onClose()"
        (onShow)="adjustOverlayZIndex()"
        class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom ss-l-productsSidebar z-9 p-0"
        [ngStyle]="isMobile ? { 'max-height': '50vh', 'overflow-y': 'auto' } : { }">

        <app-shopping-cart></app-shopping-cart>

    </p-sidebar>






    <p-sidebar [(visible)]="chatAutorization" (onHide)="onClose()" (onShow)="moveOverlayToComponent()"
        class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom z-1 p-0 ss-g-sidebarChat"
        position="bottom">

        <div class="p-field mb-2 text-center">
            <i class="bi bi-wechat text-6xl mb-3"></i>
            <label for="buttonText" class="ss-g-globalClassLabel">Para conversar pelo chat preencha as informações
                abaixo:</label>
            <input class="mt-2 w-full mb-4" type="text" pInputText maxlength="40" [(ngModel)]="username"
                placeholder="Nome" />

            <div class="mb-4 flex align-items-center justify-content-center">
                <input type="checkbox" [(ngModel)]="acceptedTerms" class="mr-2" />
                <label for="terms" class="m-0">Li e concordo com os <a href="#" target="_blank">termos e
                        condições.</a></label>
            </div>
            <button pButton pRipple label="Participar do Chat" class="w-full" [disabled]="!canActivateButton()"
                (click)="autorizeChat()"></button>
        </div>
    </p-sidebar>




    <!-- <div #lottiePlayerRef class="ss-l-lottiePlayer flex flex-column" *ngIf="showLottiePlayer">
            <lottie-player [src]="srcLottie" autoplay loop></lottie-player>
        </div> -->
    <div class="ss-g-muteImg flex flex-column" *ngIf="videoMuteService.getMuteState() && shouMuteImg && showMute"
        (click)="toggleMute(); $event.stopPropagation()">
        <i class="bi bi-volume-mute-fill text-white"></i>
        <div class="font-bold pl-4 pr-4 text-center text-white ss-l-mute-message">Toque na tela para tirar o mute
        </div>
    </div>

    <div class="custom-navigation hidden md:flex" *ngIf="InicialSlicks.length">
        <button type="button" pbutton pripple class="p-element p-ripple p-button-icon-only p-button p-component"
            aria-label="Move Up" pTooltip="Anterior" tooltipPosition="left" (click)="customSwiperInstance.slidePrev()">
            <i class="bi bi-arrow-up"></i>
        </button>
        <button type="button" pbutton pripple class="p-element p-ripple p-button-icon-only p-button p-component"
            aria-label="Move Down" pTooltip="Próximo" tooltipPosition="left" (click)="customSwiperInstance.slideNext()">
            <i class="bi bi-arrow-down"></i>
        </button>
    </div>


</div>

<p-dialog [baseZIndex]="1200" header="Confirmação" [(visible)]="displayDialog" [modal]="true" [closable]="false"
    [resizable]="false">
    <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Você será redirecionado para uma
        página externa - {{ tempUrl }}. Deseja continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="confirmRedirect(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não" (click)="cancelRedirect(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>
<p-dialog [baseZIndex]="1200" header="Confirmação" [(visible)]="displayDialogWhats" [modal]="true" [closable]="false"
    [resizable]="false">
    <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Você será redirecionado para uma
        página externa - {{ tempUrl }}. Deseja continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="confirmRedirectWhats(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não" (click)="cancelRedirect(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>
<p-dialog [baseZIndex]="1200" header="Confirmação" [(visible)]="displayDialogCustom" [modal]="true" [closable]="false"
    [resizable]="false">
    <p style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Você será redirecionado para uma
        página externa - {{ tempUrl }}. Deseja continuar?</p>
    <p-footer>
        <button type="button" pButton pRipple label="Sim"
            (click)="confirmRedirectCustom(); $event.stopPropagation()"></button>
        <button type="button" pButton pRipple label="Não" (click)="cancelRedirect(); $event.stopPropagation()"></button>
    </p-footer>
</p-dialog>


<p-toast position="top-center" key="confirm" (onClose)="onClose()" [baseZIndex]="5000" [closable]="false">
    <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
        <section class="flex p-3 gap-3 w-full bg-black-alpha-90 shadow-2" style="border-radius: 10px">
            <i class="pi pi-cloud-upload text-primary-500 text-2xl"></i>
            <div class="flex flex-column gap-3 w-full">
                <p class="m-0 font-semibold text-base text-white">
                    Carregando novos vídeos...
                </p>
                <p-progressBar [value]="progress" [showValue]="false" [style]="{ height: '4px' }" />
            </div>
        </section>
    </ng-template>
</p-toast>