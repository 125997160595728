import { Component, OnInit, OnDestroy, DoCheck, inject, effect, ChangeDetectorRef, Renderer2, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Location } from '@angular/common'; import { MenuItem, MessageService } from 'primeng/api'; import { WidgetsService } from 'src/app/services/widgets.service';
import { takeUntil, map } from 'rxjs/operators'; import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs'; import { ActivatedRoute } from '@angular/router'; import { AssetsResponse, WidgetDetailsResponse } from './models';
import { WidgetHelperService } from 'src/app/services/widget-helper.service'; import { Router } from '@angular/router';
import { ModalstateService } from 'src/app/services/modalstate.service'; import { ViewportService } from 'src/app/services/viewport.service';
// import { WidgetTemplateComponent } from '../widget-template/widget-template.component'; 
import { StoreService } from 'src/app/services/store.service';
// import { template } from 'lodash'; 
import { ProductService } from 'src/app/services/product.service';
interface ResolutionOption {
  label: string;
  value: { width: number; height: number };
  selected?: boolean; // Opcional, pois nem todos os itens terão `selected`
}
@Component({
  selector: 'app-edit-widget',
  templateUrl: './edit-widget.component.html',
  styleUrls: ['./edit-widget.component.scss']
})
export class EditWidgetComponent implements OnInit, OnDestroy, DoCheck {
  viewPortSerice = inject(ViewportService);
  @ViewChild('containerDiv', { static: false }) containerDivRef!: ElementRef<HTMLDivElement>; isDragDrop: boolean = false; resolutions: { label: string; value: { width: number; height: number } }[] = [];
  selectedResolution: any; widgetTemplateEventEmitter = new EventEmitter<void>(); isAdmin: boolean = true;
  displaySidebar: boolean = false; ctaOptions: any[] = []; popupPositions: any[] = []; issueForm?: FormGroup; widget_id!: number; widget_uid?: string; selectedTemplate: string = 'Criando Widget';
  activeItem?: MenuItem; activeOption: number | null = 2; hasError1: boolean = false; selectedPopupPosition: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' = 'bottomleft';
  template_uid?: string; store_id?: number; height?: number; aspectRatio: any; //template_id?:number; 
  hasErrorTitle: boolean = false; hasError2: boolean = false; hasError3: boolean = false; displayAguarde: boolean = false; height_inline: number = 0;
  saveBtnDisabled: boolean = true; isLoadingSave: boolean = false; limitShowItens: boolean = false; showDescription?: boolean = false; showProducts?: boolean = false; bg_color: string = '#ffffff'; font_color: string = '#000000';
  showClearSlicksTags: boolean = false; loading: boolean = true; widgetTitle?: string; highlight?: string;
  widgetTypes: any[] = []; slickTypes: any[] = []; selectedWidgetType: string = ''; selectedSlickType: string = ''; filteredResults: any[] = [];
  filteredResultsSlicks: any[] = []; sourceProducts: any[] = []; targetProducts: any[] = []; skeletonSource: any[] = [];
  valuesSlicksTags: string[] | undefined; manualValue: number | null = null; createWidgetBtn: boolean = false;
  showSmartphones: boolean = false; showDesktops: boolean = false; valSelect1Video: any = { value: 1 }; refreshTrigger: boolean = false;
  landscapeOptions2 = [{ icon: 'pi pi-mobile', name: 'Desktop', value: 1 }, { icon: 'pi pi-mobile rotate-90', name: 'Mobile', value: 2 },];
  shapeOptions = [{ label: 'Portrait', value: 'portrait' }, { label: 'Landscape', value: 'landscape' }, { label: 'Circle', value: 'circle' }];
  script_share: string = '<script type="text/javascript" src="https://cdn.slicksell.link/widget.js" defer></script>';
  widget_share: string = '';
  templateType?: string = 'stories';
  selectedShape: string = '';
  private previousTargetOrder: any[] = [];
  private isFirstCheck = true;
  ngDoCheck() {
    if (this.isFirstCheck) {
      this.isFirstCheck = false; // Após a primeira execução, desativa a lógica
      return; // Impede a execução de qualquer lógica na primeira verificação
    }

    if (this.hasTargetProductsChanged()) {
      this.handleTargetReorder();
    }
  }

  private hasTargetProductsChanged(): boolean {
    const currentOrder = JSON.stringify(this.targetProducts);
    const previousOrder = JSON.stringify(this.previousTargetOrder);
    const orderChanged = currentOrder !== previousOrder;
    this.previousTargetOrder = [...this.targetProducts]; // Atualiza a referência para a próxima verificação
    return orderChanged;
  }

  async handleTargetReorder() {
    let stCS;
    clearTimeout(stCS);
    stCS = setTimeout(() => {
      this.editWidget(0);
    }, 150);

  }
  templates: any[] = [
    { name: 'Carousel', key: 'carousel', icon: 'pi pi-video' },
    { name: 'Grid', key: 'grid', icon: 'bi bi-grid' },
    { name: 'Popup', key: 'popup', icon: 'pi pi-mobile' },
    { name: 'Inline', key: 'inline', icon: 'pi pi-mobile' },
    { name: 'Stories', key: 'stories', icon: 'pi pi-mobile' },
    { name: 'Iframe', key: 'iframe', icon: 'bi bi-phone-landscape' }
  ];
  private destroy$ = new Subject<void>();
  conditionOptions = [
    { label: 'Igual', value: 'igual' },
    { label: 'Contém', value: 'contem' }
  ];
  selectedCondition: string = this.conditionOptions[0].value;
  valuesEqualTags: string[] = [];
  valuesContainTags: string[] = [];
  showClearEqualTags: boolean = false; isNewWidget: boolean = false;
  showClearContainTags: boolean = false; isCreateMode: boolean = false;
  constructor(
    private productService: ProductService,
    private widgetService: WidgetsService, private router: Router, private storeService: StoreService, public fb: FormBuilder, private route: ActivatedRoute, private messageService: MessageService, private modalStateService: ModalstateService,
    private cdr: ChangeDetectorRef, private location: Location, private renderer: Renderer2, private widgetHelperService: WidgetHelperService
  ) {
    this.issueForm = this.fb.group({
      type: [0], // Valor fixo para type
      title: [''], // O valor do campo de título
      feed_type: [''], // O valor do tipo de feed (manual ou tags)
      slick_filter: [''], // O valor do tipo de slick
      max_limit: [null], // Valor de limite
      smart: [0], // Valor fixo para smart
      tags: [''], // Tags em formato string
      template: ['Pip'], // Valor fixo para template
      url_display: ['www'], // Valor fixo para URL display
      assets: [[]], // IDs dos assets do picklist
      style: this.fb.group({}),
      widget_rule: this.fb.group({
        device_display: this.fb.group({
          mobile: [false], // Valor inicial para dispositivos móveis
          desktop: [false] // Valor inicial para desktops
        }),
        url_display: this.fb.group({
          contain_rule: [''], // String para URLs separadas por vírgula
          equal_rule: [''] // String para URLs separadas por vírgula
        })
      })
    });

    effect(() => {
      let templateType = this.viewPortSerice.getTemplateType()();
      if (templateType == 'iframe') {
        this.height = this.viewPortSerice.getDivHeightSignal()();
      } else {
        this.height = this.viewPortSerice.getDivHeightSignal()();
      }
      this.cdr.detectChanges();
    });

    this.breadcrumbItems = [
      { label: 'Configurações', command: () => this.navigateToConfigurations() },
      { label: 'Integrações', command: () => this.navigateToIntegrations() },
      { label: 'Domínios Permitidos', command: () => this.navigateToDomains() }
    ];
  }
  ngOnInit(): void {
    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    if (selectedStoreId) {
      this.store_id = Number(selectedStoreId);
    } else {
      this.store_id = 163;
    }
    let loja: string = '';
    const domainStore = localStorage.getItem('domain_store'); // Obtém o valor do localStorage

    if (domainStore) {
      // Extrai o subdomínio (parte antes do primeiro ponto)
      const match = domainStore.match(/^([^\.]+)\./);
      loja = match ? match[1] : ''; // Se o subdomínio for encontrado, use-o, caso contrário, mantenha vazio
    }
    this.widget_share = `<iframe src="http://slicksell.link/${loja}" frameborder="0" width="100px" height="100px"></iframe>`;



    this.isCreateMode = this.location.path().includes('/create_widget');
    this.selectedCondition = this.conditionOptions[0].value;
    this.valSelect1Video = this.landscapeOptions2[0];
    this.displaySidebar = true;
    this.modalStateService.modalOpened$.subscribe((isModalOpen) => {
    });
    this.popupPositions = this.widgetHelperService.getPopupPositions();
    this.resolutions = this.widgetHelperService.getResolutions(); // selectedResolution

    this.ctaOptions = this.widgetHelperService.getCtaOptions(this, this.isCreateMode);
    this.route.queryParams.subscribe(params => {
      if (params['embed'] === 'true') {
        // Se embed é verdadeiro, seleciona a aba "Embedding"
        this.activeItem = this.ctaOptions[2];
        this.activeOption = 2;
      } else {
        // Caso contrário, seleciona a aba padrão
        this.activeItem = this.ctaOptions[0];
        this.activeOption = 0;
      }
    });

    this.widgetTypes = this.widgetHelperService.getWidgetTypes();
    this.slickTypes = this.widgetHelperService.getSlickTypes();
    this.filteredResults = this.widgetTypes;
    this.filteredResultsSlicks = this.slickTypes; this.selectedSlickType = 'all';
    this.skeletonSource = Array(6).fill({});
    this.height = window.visualViewport ? window.visualViewport.height : window.innerHeight;
    this.route.url.subscribe(segments => {
      // Verifica se estamos acessando 'create-widget'
      if (segments.length > 0 && segments[0].path === 'create_widget') {
        // É para criar um novo widget
        this.listAssets().pipe(takeUntil(this.destroy$)).subscribe({
          next: () => {
            this.createWidgetBtn = true;
            this.loading = false;
            this.selectedTemplate = 'carousel';
            this.isNewWidget = true;
          },
          error: (err) => {
            this.loading = false;
            console.error('Erro ao carregar os assets:', err);
          }
        });
      } else {
        this.displayAguarde = true;
        this.route.paramMap.subscribe(params => {
          const uid = params.get('uid');
          if (uid) {
            this.widgetService.detailWidget(uid).pipe(takeUntil(this.destroy$)).subscribe({
              next: (res: WidgetDetailsResponse) => {
                this.selectedShape = 'circle';
                this.widget_id = res.id;
                this.widget_uid = res.uid;
                this.widgetTitle = res.title;
                this.selectedWidgetType = res.feed_type;

                if(this.selectedWidgetType === 'manual'){
                  this.initializeObserver();
                }else{
                  if(this.observer){
                    this.observer.disconnect();
                  }
                }

                this.filteredTemplates = this.templates.map(template => ({
                  ...template,
                  disabled: this.isTemplateDisabled(template.key) // Adiciona a propriedade 'disabled'
                }));
                this.selectedSlickType = res.slick_filter;
                this.manualValue = res.max_limit;
                if (this.manualValue != undefined && this.manualValue > 0) {
                  this.limitShowItens = true;
                }
                this.valuesSlicksTags = res.tags ? res.tags.split('|') : [];
                this.showSmartphones = res.widget_rule.device_display.mobile_display;
                this.showDesktops = res.widget_rule.device_display.desktop_display;
                this.valuesEqualTags = typeof res.widget_rule.url_display.equal_rule === 'string'
                  ? JSON.parse(res.widget_rule.url_display.equal_rule)
                  : res.widget_rule.url_display.equal_rule || [];

                this.valuesContainTags = typeof res.widget_rule.url_display.contain_rule === 'string'
                  ? JSON.parse(res.widget_rule.url_display.contain_rule)
                  : res.widget_rule.url_display.contain_rule || [];


                if (res.style) {
                  const styleKeys = Object.keys(res.style);
                  if (styleKeys.length > 0) {
                    this.selectedTemplate = styleKeys[0];
                    this.cdr.detectChanges();
                  }
                }
                if (res.style && res.style.popup) {
                  const position = res.style.popup.position;
                  this.selectedPopupPosition = position && ['center', 'top', 'bottom', 'left', 'right', 'topleft', 'topright', 'bottomleft', 'bottomright'].includes(position)
                    ? this.widgetHelperService.mapPositionValue(position)
                    : 'bottomleft';
                  this.cdr.detectChanges();
                  this.highlight = res.style.popup.highlight || '';
                  this.selectedShape = res.style.popup.shape || 'circle';
                  this.bg_color = res.style.popup.background_color || '#ffffff';
                  this.font_color = res.style.popup.font_color || '#000000';
                  if (res.style.popup.height) {
                    this.height = res.style.popup.height;
                  }

                } else if (res.style && res.style.inline) {
                  this.showDescription = res.style.inline.showDescription;
                  this.showProducts = res.style.inline.showProducts;

                  const savedResolution = res.style.inline.selectedResolution;
                  if (savedResolution) {
                    const resolution = this.resolutions.find(
                      r => r.value.width === savedResolution.width && r.value.height === savedResolution.height
                    );

                    if (resolution) {
                      this.selectedResolution = resolution.value;
                      this.height = resolution.value.height;
                    }
                  }


                } else if (res.style && res.style.carousel) {
                  this.showProducts = res.style.carousel.showProducts;
                  this.showDescription = res.style.carousel.showDescription;
                  this.bg_color = res.style.carousel.background_color || '#ffffff';
                  this.font_color = res.style.carousel.font_color || '#000000';
                  const position = res.style.carousel.position;
                  this.selectedPopupPosition = position && ['center', 'top', 'bottom', 'left', 'right', 'topleft', 'topright', 'bottomleft', 'bottomright'].includes(position)
                    ? this.widgetHelperService.mapPositionValue(position)
                    : 'bottomleft';
                } else if (res.style && res.style.stories) {
                  this.showProducts = res.style.stories.showProducts;
                  this.highlight = res.style.stories.highlight || '';
                  this.bg_color = res.style.stories.background_color || '#ffffff';
                  this.font_color = res.style.stories.font_color || '#000000';
                  const position = res.style.stories.position;
                  this.selectedPopupPosition = position && ['center', 'top', 'bottom', 'left', 'right', 'topleft', 'topright', 'bottomleft', 'bottomright'].includes(position)
                    ? this.widgetHelperService.mapPositionValue(position)
                    : 'bottomleft';
                } else {
                  this.selectedShape = 'circle';
                }
                this.resolutions = this.widgetHelperService.getResolutions();
                this.selectedResolution = this.resolutions[2].value;
                if (res.style && (res.style.iframe && res.style.iframe.selectedResolution)) {
                  const savedResolution = res.style.iframe.selectedResolution;
                  const resolution = this.resolutions.find(
                    r => r.value.width === savedResolution.width && r.value.height === savedResolution.height
                  );

                  if (resolution) {
                    this.selectedResolution = resolution.value;
                    this.height = resolution.value.height;
                  }


                } else if (res.style && (res.style.inline && res.style.inline.selectedResolution)) {
                  const savedResolution = res.style.inline.selectedResolution;
                  const resolution = this.resolutions.find(
                    r => r.value.width === savedResolution.width && r.value.height === savedResolution.height
                  );

                  if (resolution) {
                    this.selectedResolution = resolution.value;
                    this.height = resolution.value.height;
                  }
                }
                // Definir o aspect ratio com base na resolução selecionada
                const width = this.selectedResolution.width;
                const height = this.selectedResolution.height;
                this.aspectRatio = `${width} / ${height}`;
                if (res.style && res.style.iframe && res.style.iframe.selectedResolution) {
                  let width = 100;
                  if (this.aspectRatio && this.height) {
                    // Separa largura e altura da `aspectRatio`, por exemplo: "16 / 9"
                    const [ratioWidth, ratioHeight] = this.aspectRatio.split('/').map(Number);

                    // Calcula a largura com base na altura e na proporção
                    width = this.height * (ratioWidth / ratioHeight);
                  }
                  let loja: string = '';
                  const domainStore = localStorage.getItem('domain_store'); // Obtém o valor do localStorage

                  if (domainStore) {
                    // Extrai o subdomínio (parte antes do primeiro ponto)
                    const match = domainStore.match(/^([^\.]+)\./);
                    loja = match ? match[1] : ''; // Se o subdomínio for encontrado, use-o, caso contrário, mantenha vazio
                  }
                  this.widget_share = `<iframe src="http://slicksell.link/${loja}" frameborder="0" width="${width}" height="${this.height}"></iframe>`;
                } else {
                  let width = 100;
                  if (this.aspectRatio && this.height) {
                    // Separa largura e altura da `aspectRatio`, por exemplo: "16 / 9"
                    const [ratioWidth, ratioHeight] = this.aspectRatio.split('/').map(Number);

                    // Calcula a largura com base na altura e na proporção
                    width = this.height * (ratioWidth / ratioHeight);
                  }
                  this.widget_share = `<slicksell-widget data-template="${this.selectedTemplate}" data-height="${this.height}" data-width="${width}" data-store-id="${this.store_id}" data-widget-id="${this.widget_id}"></slicksell-widget>`;
                }

                this.listAssets(this.selectedSlickType).pipe(takeUntil(this.destroy$)).subscribe({
                  next: () => {
                    // Extraindo os ids dos assets e suas respectivas posições da resposta do detalhe do widget (res)
                    const assetMap = new Map<number, number | null>(
                      res.assets.map(asset => [asset.id, asset.position])
                    );

                    // Filtrando e separando os produtos entre target e source
                    this.targetProducts = this.sourceProducts.filter(product => assetMap.has(product.id));
                    this.sourceProducts = this.sourceProducts.filter(product => !assetMap.has(product.id));

                    // Ordenando os produtos target de acordo com a position
                    this.targetProducts.sort((a, b) => {
                      const positionA = assetMap.get(a.id) ?? 0; // Define 0 como valor padrão se position for null ou undefined
                      const positionB = assetMap.get(b.id) ?? 0; // Define 0 como valor padrão se position for null ou undefined
                      return positionA - positionB;
                    });


                    this.createWidgetBtn = false;
                    this.loading = false;
                    this.displayAguarde = false;
                    this.cdr.detectChanges();
                  },
                  error: (err: any) => {
                    this.loading = false;
                    this.displayAguarde = false;
                  }
                });
              },
              error: (err) => {
                this.loading = false;
              }
            });
          }
        });
      }
    });


    let key = 'shopify';
    this.productService.getAppsStore(key).subscribe({
      next: (response) => {
        const storeApp = response.store_apps?.find((app) => {
          return app.store_id === this.store_id;
        });
        if (storeApp) {
          this.hasIntegration = true; // Adicione uma propriedade personalizada
        }        
      },
      error: (error) => {
        
      },
    });
  }
  hasIntegration:boolean=false;
  filteredTemplates: any[] = [...this.templates];
  capitalizeFirstLetter(text: string | undefined): string {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  async onFeedTypeChange(newType: string) {
    await this.editWidget(0);
    this.selectedWidgetType = newType;
    console.log('newType', newType);
    if(newType === 'manual'){
      this.initializeObserver();
    }else{
      if(this.observer){
        this.observer.disconnect();
      }
    }
    // Verifica se o tipo selecionado é "fullfed" e ajusta os templates
    // if (newType === 'fullfeed') {
    //   this.filteredTemplates = this.templates.filter(template => template.key === 'iframe');
    // } else {
    //   this.filteredTemplates = [...this.templates];
    // }
    this.filteredTemplates = [...this.templates];
    // Se o template atual for desabilitado pela nova seleção, redefinir o template.
    if (this.isTemplateDisabled(this.selectedTemplate)) {
      this.selectedTemplate = ''; // Limpa a seleção.
    }

    // Resetar a seleção do radioButton se o template atual não for permitido
    if (!this.filteredTemplates.some(template => template.key === this.selectedTemplate)) {
      this.selectedTemplate = '';
    }
  }
  isTemplateDisabled(templateKey: string): boolean {
    if (this.selectedWidgetType === 'fullfeed') {
      // Desabilita todas as opções exceto 'iframe'
      return templateKey !== 'iframe';
    } else {
      // Desabilita apenas 'iframe'
      return templateKey === 'iframe';
    }
  }
  breadcrumbItems: any[];
  navigateToConfigurations(): void {
    this.router.navigate(['/configurations']);
  }
  navigateToIntegrations(): void {
    // Navega para configurações com a aba Integrações ativa
    this.router.navigate(['/configurations'], { queryParams: { tab: 'integrations' } });
  }

  navigateToDomains(): void {
    // Navega para configurações com Integrações ativa e foca nos Domínios Permitidos
    this.router.navigate(['/configurations'], { queryParams: { tab: 'integrations', focus: 'domains' } });
  }
  openSidebar(): void {
    this.displaySidebar = true;
  }
  onResolutionChange(resolution: { width: number; height: number }) {
    if (resolution) {
      this.height = resolution.height;
      this.aspectRatio = `${resolution.width} / ${resolution.height}`;
    }
  }
  checkTempalte() {
    let loja: string = '';
    const domainStore = localStorage.getItem('domain_store'); // Obtém o valor do localStorage

    if (domainStore) {
      // Extrai o subdomínio (parte antes do primeiro ponto)
      const match = domainStore.match(/^([^\.]+)\./);
      loja = match ? match[1] : ''; // Se o subdomínio for encontrado, use-o, caso contrário, mantenha vazio
    }
    let width = 100;
    if (this.selectedTemplate == 'iframe') {
      if (this.aspectRatio && this.height) {
        // Separa largura e altura da `aspectRatio`, por exemplo: "16 / 9"
        const [ratioWidth, ratioHeight] = this.aspectRatio.split('/').map(Number);

        // Calcula a largura com base na altura e na proporção
        width = this.height * (ratioWidth / ratioHeight);
      }

      this.widget_share = `<iframe src="http://slicksell.link/${loja}" frameborder="0" width="${width}" height="${this.height}"></iframe>`;
    } else {
      if (this.aspectRatio && this.height) {
        // Separa largura e altura da `aspectRatio`, por exemplo: "16 / 9"
        const [ratioWidth, ratioHeight] = this.aspectRatio.split('/').map(Number);

        // Calcula a largura com base na altura e na proporção
        width = this.height * (ratioWidth / ratioHeight);
      }
      this.widget_share = `<slicksell-widget data-template="${this.selectedTemplate}" data-height="${this.height}" data-width="${width}" data-store-id=${this.store_id} data-widget-id=${this.widget_id}></slicksell-widget>`;
    }
  }


  visible: boolean = false;

  position: string = 'center';
  dragDrop(x: number, event?: Event) {
    if (event) {
      event.stopPropagation(); // Previne que o clique se propague e desative o drag and drop
    }

    if (x === 0) {
      this.isDragDrop = true;
    } else {
      this.isDragDrop = false;
    }
  }
  async onReorder(event: any) {
    this.isDragDrop = false;

    const { items, listType } = event;

    if (listType === 'target') {
      // Executa o código específico para a lista `target` quando a ordem é alterada
      await this.editWidget(0);
    }
  }
  showEqualClearFunction() { this.showClearEqualTags = this.widgetHelperService.showClearFunction(this.valuesEqualTags); }
  showContainClearFunction() { this.showClearContainTags = this.valuesContainTags.length > 0; }
  clearEqualTags() { this.valuesEqualTags = []; this.showClearEqualTags = false; }
  clearContainTags() { this.valuesContainTags = []; this.showClearContainTags = false; }
  isCol4: boolean = false;
  changeLandscape() {
    this.widgetHelperService.changeLandscapeLayout(this.valSelect1Video, this.containerDivRef, this.renderer);
    this.isCol4 = this.containerDivRef.nativeElement.classList.contains('col-4');
  }
  copiarScript() { if (this.script_share) { this.widgetHelperService.copiarTextoParaClipboard(this.script_share); } }
  copiarWidget() { if (this.widget_share) { this.widgetHelperService.copiarTextoParaClipboard(this.widget_share); } }
  listAssets(typeSlick: string | undefined = undefined): Observable<void> {
    return this.widgetService.getAssets(typeSlick).pipe(
      map((res: AssetsResponse) => {
        const assets = res.assets || [];
        this.sourceProducts = assets.flatMap((asset: { items: any[] }) => asset.items.map((item: any) => ({ id: item.content.id, uid: item.content.uid, title: item.content.title ?? "Título", type: item.media.type ?? "Slick", thumbnail: item.media.thumbnail ?? 'assets/imgTmp.webp', src: item.media.src, description: item.content.description, duration: item.media.duration })));
      })
    );
  }
  isUuid(value: string): boolean { const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i; return uuidRegex.test(value); }
  onSlickTypeChange(event: any) {
    this.loading = true; let filter = ''; const selectedValue = event.value || this.selectedSlickType;
    switch (selectedValue) { case 'todos': filter = 'all'; break; case 'shorts': filter = 'shorts'; break; case 'live': filter = 'live'; break; case 'spots': filter = 'spots'; break; default: filter = 'all'; }
    if (filter) {
      this.widgetService.getAssets(filter).pipe().subscribe({
        next: (res: AssetsResponse) => {
          const assets = res.assets || [];
          this.loading = false;
          // Obtendo os IDs dos produtos já em `targetProducts` para evitar duplicação
          const targetProductIds = new Set(this.targetProducts.map(product => product.id));

          // Filtrando os produtos de `sourceProducts`, excluindo os que já estão em `targetProducts`
          this.sourceProducts = assets.flatMap((asset: { items: any[] }) =>
            asset.items
              .map((item: any) => ({
                id: item.content.id,
                uid: item.content.uid,
                title: item.content.title ?? "Título",
                type: item.media.type ?? "Slick",
                thumbnail: item.media.thumbnail ?? 'assets/imgTmp.webp',
              }))
              // Excluindo produtos que já estão no `targetProducts`
              .filter(product => !targetProductIds.has(product.id))
          );

          this.cdr.detectChanges();
        },
        error: (err) => { this.loading = false; }
      });
    } else { this.loading = false; }
  }
  isMobileDevice() { const isMobileUA = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); const isTouchCapable = navigator.maxTouchPoints > 0; return isMobileUA || isTouchCapable; }
  selecionarOpcao(opcao: number): void { this.activeOption = opcao; }
  onInputChange() { this.hasError1 = false; }
  createWidget() {
    let hasError = false;

    // Verifica se o título está preenchido
    if (!this.widgetTitle || this.widgetTitle.trim() === '') {
      this.hasErrorTitle = true;
      hasError = true;
    } else {
      this.hasErrorTitle = false;
    }

    // Verifica se o tipo de feed está preenchido
    if (!this.selectedWidgetType) {
      this.hasError1 = true;
      hasError = true;
    } else {
      this.hasError1 = false;
    }

    // Verifica se o tipo de Slick está preenchido
    if (!this.selectedSlickType) {
      this.hasError2 = true;
      hasError = true;
    } else {
      this.hasError2 = false;
    }

    // Se houver algum erro, interrompe o fluxo
    if (hasError) {
      return;
    }
    this.hasErrorTitle = false; // Reseta o erro se houver título válido
    if (this.issueForm) {
      const style: any = {};
      if (this.selectedTemplate === 'popup') {
        style['popup'] = {
          position: this.selectedPopupPosition || 'top-right', // Se nenhuma posição for selecionada, usa 'top-right' como padrão
          highlight: this.highlight,
          shape: this.selectedShape,
          background_color: this.bg_color,
          font_color: this.font_color,
        };
      } else { style[this.selectedTemplate] = { background_color: this.bg_color }; }
      const containRuleValues = this.valuesContainTags.join(','); const equalRuleValues = this.valuesEqualTags.join(',');
      this.issueForm = this.fb.group({
        title: [this.widgetTitle],
        feed_type: [this.selectedWidgetType],
        type: [0],
        smart: [0],
        template: ['2Pip'],
        slick_filter: [this.selectedSlickType],
        max_limit: [this.limitShowItens ? this.manualValue : null],
        tags: [this.valuesSlicksTags ? this.valuesSlicksTags.join('|') : ''],
        assets: [
          this.targetProducts.map((product, index) => ({
            id: product.id,
            position: index
          }))
        ],
        style: [Object.keys(style).length ? style : undefined],
        widget_rule: this.fb.group({
          device_display: this.fb.group({
            mobile: [this.showSmartphones],
            desktop: [this.showDesktops]
          }),
          url_display: this.fb.group({
            contain_rule: [containRuleValues],
            equal_rule: [equalRuleValues]
          })
        })
      });
      this.isLoadingSave = true; // Mostra o indicador de carregamento
      this.widgetService.createWidget(this.issueForm.value).pipe(takeUntil(this.destroy$)).subscribe({
        next: (response: WidgetDetailsResponse) => {
          this.isLoadingSave = false; // Esconde o indicador de carregamento
          this.messageService.add({ severity: 'success', summary: 'Widget Salvo', detail: 'O widget foi salvo com sucesso!' });
          this.widget_id = response.id;
          this.widget_uid = response.uid;
          this.location.replaceState(`/edit_widgets/${response.uid}`);
          this.isCreateMode = this.location.path().includes('/create_widget');
          this.ctaOptions = this.widgetHelperService.getCtaOptions(this, this.isCreateMode);
          this.isNewWidget = false;
          this.cdr.detectChanges(); this.createWidgetBtn = false;
        },
        error: (error) => {
          this.isLoadingSave = false; // Esconde o indicador de carregamento
          let message = '';
          if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 404) { message = error.error.message; } else if (error.status === 500) { message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde."; }
          if (message) { this.showErrorDialog(message); }
        }
      });
    }
  }
  async editWidget(n: number): Promise<void> {
    let hasError = false;
    // Validações
    if (!this.widgetTitle || this.widgetTitle.trim() === '') {
      this.hasErrorTitle = true;
      hasError = true;
    } else {
      this.hasErrorTitle = false;
    }

    if (!this.selectedWidgetType) {
      this.hasError1 = true;
      hasError = true;
    } else {
      this.hasError1 = false;
    }

    if (!this.selectedSlickType) {
      this.hasError2 = true;
      hasError = true;
    } else {
      this.hasError2 = false;
    }

    if (this.issueForm) {
      let style: any = {};
      if (this.selectedTemplate === 'popup') {
        style['popup'] = {
          position: this.selectedPopupPosition || 'top-right',
          highlight: this.highlight,
          shape: this.selectedShape,
          background_color: this.bg_color,
          font_color: this.font_color,
        };
      } else if (this.selectedTemplate === 'iframe') {
        style[this.selectedTemplate] = {
          selectedResolution: this.selectedResolution
        };
      } else if (this.selectedTemplate === 'inline') {
        style[this.selectedTemplate] = {
          selectedResolution: this.selectedResolution,
          showDescription: this.showDescription,
          showProducts: this.showProducts,
        };
      } else if (this.selectedTemplate === 'carousel' || this.selectedTemplate === 'stories') {
        style[this.selectedTemplate] = {
          showDescription: this.showDescription,
          background_color: this.bg_color,
          font_color: this.font_color,
          position: this.selectedPopupPosition || 'top-right',
          showProducts: this.showProducts,
        };
      } else {
        style[this.selectedTemplate] = {
          background_color: this.bg_color
        };
      }

      const containRuleValues = this.valuesContainTags;
      const equalRuleValues = this.valuesEqualTags;

      this.issueForm = this.fb.group({
        title: [this.widgetTitle],
        feed_type: [this.selectedWidgetType],
        type: [0],
        smart: [0],
        template: ['2Pip'],
        slick_filter: [this.selectedSlickType],
        max_limit: [this.limitShowItens ? this.manualValue : null],
        tags: [this.valuesSlicksTags ? this.valuesSlicksTags.join('|') : ''],
        assets: [
          this.targetProducts.map((product, index) => ({
            id: product.id,
            position: index
          }))
        ],
        style: [Object.keys(style).length ? style : undefined],
        widget_rule: this.fb.group({
          device_display: this.fb.group({
            mobile: [this.showSmartphones],
            desktop: [this.showDesktops]
          }),
          url_display: this.fb.group({
            contain_rule: [containRuleValues],
            equal_rule: [equalRuleValues]
          })
        })
      });


      if (this.widget_id) {
        this.isLoadingSave = true;
        this.widgetService.editWidget(this.issueForm.value, this.widget_id).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response) => {
            if (n == 1) {
              this.messageService.add({
                severity: 'success',
                summary: 'Widget Editado',
                detail: 'O widget foi editado com sucesso!'
              });
            }
            this.isLoadingSave = false;
            this.toggleRefreshTrigger();
          },
          error: (error) => {
            this.isLoadingSave = false;
            let message = '';
            if ([400, 403, 401, 404].includes(error.status)) {
              message = error.error.message;
            } else if (error.status === 500) {
              message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            }
            if (message) {
              this.showErrorDialog(message);
            }
            // reject(error); // Rejeita a Promise caso haja erro
          }
        });
      }

    } else {
      this.isLoadingSave = false;
      //resolve(); // Resolve a Promise caso não haja edição para fazer
    }

  }

  private toggleRefreshTrigger() {
    this.cdr.detectChanges();
    this.refreshTrigger = !this.refreshTrigger; // Altera o valor de true para false, ou vice-versa
  }
  showErrorDialog(message: string) { this.messageService.add({ severity: 'error', summary: 'Erro', detail: message, icon: 'pi pi-times' }); }
  checkInputTitle() { this.hasErrorTitle = !this.widgetTitle || this.widgetTitle.trim().length === 0; }
  clearSlickMaps() { this.valuesSlicksTags = []; this.showClearSlicksTags = false; }
  async showSlickClearFunction() {
    if (this.valuesSlicksTags && this.valuesSlicksTags?.length > 0) {
      this.showClearSlicksTags = true;
    } else {
      this.showClearSlicksTags = false;
    }
    await this.editWidget(0);
  }
  async onMoveToSource(event: any) {
    this.isCreateMode = this.location.path().includes('/create_widget');
    if (!this.isCreateMode) {

      // Pegando o primeiro item, já que sempre estamos movendo um por vez
      const movedItem = event.items[0];
      if (movedItem && movedItem.uid) {
        const widget_uid = movedItem.uid;

        // Atualizando o valor do formulário com o UID do item movido
        this.issueForm = this.fb.group({
          uid: widget_uid,
        });

        // Fazer a requisição para remover o vínculo do widget com o asset movido
        this.widgetService.subWidget(this.issueForm.value, this.widget_uid).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response) => {
            this.isLoadingSave = false;
          },
          error: (error) => {
            this.isLoadingSave = false;
            let message = '';
            if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 404) {
              message = error.error.message;
            } else if (error.status === 500) {
              message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            }
            if (message) {
              this.showErrorDialog(message);
            }
          }
        });
      }
    }
    this.targetProducts = [...this.targetProducts];
  }
  async onMoveToTarget(event: any) {
    this.isCreateMode = this.location.path().includes('/create_widget');
    if (!this.isCreateMode) {
      // Atualizar as posições dos itens no `targetProducts`
      //this.targetProducts = [...this.targetProducts];
      // Atualizando o valor do formulário com a lista de itens e suas posições
      this.issueForm = this.fb.group({
        items: [this.targetProducts]
      });
      // Enviar a lista completa para o backend
      this.widgetService.addWidget(this.issueForm.value, this.widget_uid).pipe(takeUntil(this.destroy$)).subscribe({
        next: (response) => {
          this.isLoadingSave = false;
        },
        error: (error) => {
          this.isLoadingSave = false;
          let message = '';
          if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 404) {
            message = error.error.message;
          } else if (error.status === 500) {
            message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
          }
          if (message) {
            this.showErrorDialog(message);
          }
        }
      });
    }

  }

  updateProductPositions(): void { this.targetProducts.forEach((product, index) => { product.position = index; }); }
  closeCopyTemplate(n: number) { if(n == 0){this.displaySidebar = false;}else{ this.displayCommandMenu = false;} }
  onSwitchChange(event: any): void { this.limitShowItens = event.checked; this.cdr.detectChanges(); }
  onSwitchChangeDescription(event: any): void { this.showDescription = event.checked; this.cdr.detectChanges(); }
  onSwitchChangeProducts(event: any): void { this.showProducts = event.checked; this.cdr.detectChanges(); }
  onSwitchChangeMobile(event: any): void { this.showSmartphones = event.checked; this.cdr.detectChanges(); }
  onSwitchChangeDesktop(event: any): void { this.showDesktops = event.checked; this.cdr.detectChanges(); }
  filterWidgetTypes(event: any) { this.filteredResults = this.widgetHelperService.filterItems(this.widgetTypes, event.query); }
  filterSlickTypes(event: any) { this.filteredResultsSlicks = this.widgetHelperService.filterItems(this.slickTypes, event.query); }
  selectTemplate(template: string) {
    this.selectedTemplate = template;
  }
  ngOnDestroy(): void { 
    if (this.observer) {
      this.observer.disconnect();
    }
  }
  onTouchStart(event: Event) {
    this.isDragDrop = true;
    this.cdr.detectChanges();
  }

  onTouchMove(event: Event) {
    this.isDragDrop = true;
    if (this.isDragDrop) {
      event.preventDefault(); // Previne o comportamento padrão de scroll
    }
    this.cdr.detectChanges();
  }

  onTouchEnd(event: Event) {
    this.isDragDrop = false;
    this.cdr.detectChanges();
  }

  ///////////////////////////////
  // Mostrar modal
  recentProducts: any[] = []; // Todos os produtos carregados
  filteredProducts: any[] = []; // Produtos filtrados com base na busca
  //valuesEqualTags: string[] = []; // Valores do p-chips
  searchQuery: string = ''; // Termo de busca
  filterProducts(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredProducts = this.recentProducts.filter(product =>
      product.title.toLowerCase().includes(query)
    );
  }

  addProductToChips(product: any): void {
    if (!this.valuesEqualTags.includes(product.product_url)) {
      this.valuesEqualTags = [...this.valuesEqualTags, product.product_url]; // Cria uma nova referência do array
      this.cdr.detectChanges(); // Notifica o Angular para re-renderizar o componente
      this.showClearEqualTags = true;
    }
  }

  showCommandMenuModal(): void {
    this.displayAguarde = true;
    this.productService.listProducts().subscribe({
      next: (response) => {
        this.recentProducts = response.products.map((product: any) => ({
          thumbnail: product.thumbnail,
          title: product.title,
          product_url: product.product_url
        }));
        this.filteredProducts = [...this.recentProducts]; // Inicializa com todos os produtos
        this.displayCommandMenu = true;
        this.displayAguarde = false;
      },
      error: (error) => {
        console.error('Erro ao carregar produtos:', error);
      }
    });

  }


  valuesEqualTagsString: string = '';
  displayCommandMenu = false;
  recentItems = ['unread mails', 'go to last mail', 'lock account', 'profile'];
  allOptions = [
    { label: 'System Settings', description: 'Change your system preferences', icon: 'pi pi-cog', shortcut: 'S' },
    { label: 'My Profile', description: 'Go to your profile', icon: 'pi pi-user', shortcut: 'P' },
    { label: 'Switch to Dark Mode', description: 'Switch to dark mode save your eyes', icon: 'pi pi-moon', shortcut: 'D' },
    { label: 'Log out', description: 'End your session', icon: 'pi pi-sign-out', shortcut: 'L' },
  ];
  filteredOptions = [...this.allOptions];
  // Filtrar opções com base na busca
  filterOptions() {
    const query = this.searchQuery.toLowerCase();
    this.filteredOptions = this.allOptions.filter((option) =>
      option.label.toLowerCase().includes(query) || option.description.toLowerCase().includes(query)
    );
  }

  // Selecionar uma opção
  selectOption(option: any) {
    this.displayCommandMenu = false; // Fecha o modal
  }

  // Selecionar um item recente
  selectRecent(recent: string) {
    //console.log('Item recente selecionado:', recent);
  }
  onOpenSidebar(): void {
    this.displaySidebar = true;
  }
  private observer?: MutationObserver;
  initializeObserver() {
    // Desconectar o observador anterior, se existir
    if (this.observer) {
      this.observer.disconnect();
    }

    console.log('chamou multi');
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const target = mutation.target as HTMLElement;
          if (target.classList.contains('p-picklist-item') && target.style.zIndex !== '1500' ||
            target.classList.contains('p-orderlist-item') && target.style.zIndex !== '1500') {
            target.style.zIndex = '1500'; // Definindo um novo z-index
          }
        }
      });
    });

    this.observer.observe(document.body, { attributes: true, subtree: true, attributeFilter: ['style'] });
  }
}
