import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { WatchedInterval } from '../models';
import { VideoMuteService } from 'src/app/services/video-mute.service';
@Injectable({
  providedIn: 'root'
})
export class SVideoPlayerService {
  private renderer: Renderer2;
  private players: any[] = []; // Array para armazenar os players de vídeo

  constructor(private videoMuteService: VideoMuteService, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // Método para alternar o mudo
  toggleMute(player: HTMLVideoElement, isMuted: boolean): boolean {
    console.log('chamou toggleMute', isMuted);
  
    if (isMuted) {
      player.muted = false; // 🔥 Agora `muted` é uma propriedade
      player.volume = 1; // 🔥 Volume também é uma propriedade
      return false;
    } else {
      player.muted = true;
      player.volume = 0;
      return true;
    }
  }
  

  // Método para alternar play e pause
  togglePlayPause(player: any, isPaused: boolean): boolean {
    if (isPaused) {
      player.play()?.then(() => {
      }).catch((error: any) => {
        console.error("Erro ao tentar reproduzir o vídeo:", error);
      });
      return false;
    } else {
      player.pause();
      return true;
    }
  }

  // Método para buscar o player de um slide baseado no índice e se é duplicado
  getPlayerFromArray(index: number, isDuplicate: boolean, playersArray: any[]): any {
    return playersArray.find(p => p.index === index && p.isDuplicate === isDuplicate)?.player || null;
  }

  // Método para definir a lista de players, se necessário
  setPlayers(players: any[]): void {
    this.players = players;
  }

  // Método para obter o índice do slide ativo
  getActiveSlideIndex(swiperInstance: any): number {
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    return parseInt(activeSlide.getAttribute('data-swiper-slide-index'), 10);
  }


  calculateBufferedData(player: HTMLVideoElement): number {
    if (!player.buffered || player.buffered.length === 0) {
      return 0; // Se não há buffer, retorna 0
    }

    let totalBuffered = 0;

    for (let i = 0; i < player.buffered.length; i++) {
      totalBuffered += player.buffered.end(i) - player.buffered.start(i);
    }

    return totalBuffered;
  }
  watchedIntervals: WatchedInterval[] = [];
  calculateWatchedDuration() {
    if (this.watchedIntervals.length === 0) return 0;

    // Sort watched intervals by start time
    this.watchedIntervals.sort((a, b) => a.start - b.start);

    // Initialize total watched duration
    let totalDuration = 0;

    // Initialize variables to track the current merged interval
    let currentStart = this.watchedIntervals[0].start;
    let currentEnd = this.watchedIntervals[0].end;

    // Iterate through the sorted intervals and merge overlapping intervals
    for (let i = 1; i < this.watchedIntervals.length; i++) {
      const interval = this.watchedIntervals[i];

      if (interval.start <= currentEnd + 1) {
        // Merge overlapping or adjacent intervals
        currentEnd = Math.max(currentEnd, interval.end);
      } else {
        // Calculate duration of the current merged interval
        totalDuration += currentEnd - currentStart + 1;

        // Move to the next interval
        currentStart = interval.start;
        currentEnd = interval.end;
      }
    }

    // Add the duration of the last merged interval
    totalDuration += currentEnd - currentStart + 1;

    // Return the total watched duration
    return totalDuration;
  }

  // Método para reproduzir o vídeo atual, movido para o serviço
  async playVideoAtual(
    swiperInstance: any,
    realIndex: number,
    getPlayerFromArray: (index: number, isDuplicate: boolean) => HTMLVideoElement | undefined,
    isPaused: boolean,
    virtualIndex: number,
    slickInPlay: number,
    pauseOhtersCallback: () => void
  ): Promise<void> {
    if (!swiperInstance) {
      console.error("Swiper não está inicializado corretamente.");
      return;
    }

    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const isDuplicate = activeSlide.classList.contains("swiper-slide-duplicate");
    let videoElement = getPlayerFromArray(realIndex, isDuplicate);

    if (videoElement) {
      const visibleSlide = Array.from(swiperInstance.slides).find((slide: any) => {
        return slide.classList.contains("swiper-slide-visible") &&
          slide.classList.contains("swiper-slide-active") &&
          !slide.classList.contains("swiper-slide-next") &&
          !slide.classList.contains("swiper-slide-prev");
      }) as HTMLElement;

      if (!visibleSlide) {
        return;
      }

      const visibleIndexStr = visibleSlide.getAttribute("data-swiper-slide-index");
      if (!visibleIndexStr) {
        console.error("Atributo 'data-swiper-slide-index' não encontrado.");
        return;
      }

      const visibleIndex = parseInt(visibleIndexStr, 10);
      let activeIndex = realIndex;

      if (activeIndex === visibleIndex) {
        if (videoElement && videoElement.paused && !isPaused) {
          this.renderer.listen(videoElement, "canplay", () => {
            if (!videoElement) return; // Garante que o elemento existe antes de tentar acessá-lo

            videoElement.play()
              .then(() => {
                slickInPlay = virtualIndex; // Atualiza o estado
              })
              .catch(error => console.warn("Falha ao reproduzir vídeo automaticamente", error));
          });
        }
      }


      // 🔥 Atualizar visibilidade dos vídeos nos slides
      swiperInstance.slides.forEach((slide: any, index: number) => {
        const poster = slide.querySelector(".ss-l-poster") as HTMLElement;
        const video = slide.querySelector(".ss-l-video") as HTMLVideoElement;

        if (index !== swiperInstance.activeIndex) {
          if (poster) {
            this.renderer.removeClass(poster, "ss-l-hidden");
          }
          if (video) {
            this.renderer.addClass(video, "ss-l-hidden");
          }
        } else {
          if (poster) {
            this.renderer.addClass(poster, "ss-l-hidden");
          }
          if (video) {
            this.renderer.removeClass(video, "ss-l-hidden");
          }
        }
      });

      // 🔥 Pausar vídeos nos slides próximos (next/prev)
      const nextOrPrevVisibleSlide = Array.from(swiperInstance.slides).find((slide: any) => {
        return slide.classList.contains("swiper-slide-visible") &&
          (slide.classList.contains("swiper-slide-next") || slide.classList.contains("swiper-slide-prev"));
      }) as HTMLElement;

      if (nextOrPrevVisibleSlide) {
        const nextOrPrevIndexStr = nextOrPrevVisibleSlide.getAttribute("data-swiper-slide-index");
        if (nextOrPrevIndexStr) {
          const nextOrPrevIndex = parseInt(nextOrPrevIndexStr, 10);
          const isDuplicateNextOrPrev = nextOrPrevVisibleSlide.classList.contains("swiper-slide-duplicate");
          let nextOrPrevPlayer = getPlayerFromArray(nextOrPrevIndex, isDuplicateNextOrPrev);
          if (nextOrPrevPlayer && !nextOrPrevPlayer.paused) {
            nextOrPrevPlayer.pause();
          }
        }
      }
    }

    // 🔥 Chamar o callback para pausar outros vídeos
    pauseOhtersCallback();
  }

  async playActiveVideo(
    swiperInstance: any, // Instância do Swiper
    getActiveSlideIndex: () => number, // Função callback para obter o índice ativo
    getPlayerFromArray: (index: number, isDuplicate: boolean) => any, // Função callback para obter o player
    pauseOhtersCallback: () => void, // Função callback para pausar outros players
    cdrDetectChanges: () => void, // Função callback para detectar mudanças no `ChangeDetectorRef`
    setPlayerState: (key: string, value: any) => void, // Função callback para definir estados globais no componente
    virtualIndex: number, // Índice virtual atual
    isPaused: boolean // Estado global de pausa do player
  ): Promise<void> {
    if (!swiperInstance) {
      console.error("Swiper não está inicializado corretamente.");
      return;
    }

    const realIndex = getActiveSlideIndex();
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const isDuplicate = activeSlide.classList.contains('swiper-slide-duplicate');

    // Obter o player associado ao índice real e se é duplicado ou não
    let player = getPlayerFromArray(realIndex, isDuplicate);
    cdrDetectChanges(); // Detecção de mudanças no componente

    if (player && player.paused()) {
      player.on('canplay', () => {
        const visibleSlide = swiperInstance.el.querySelector('.swiper-slide-visible') as HTMLElement;
        if (!visibleSlide) {
          return;
        }

        const visibleIndexStr = visibleSlide.getAttribute('data-swiper-slide-index');
        if (!visibleIndexStr) {
          console.error("Atributo 'data-swiper-slide-index' não encontrado.");
          return;
        }

        const visibleIndex = parseInt(visibleIndexStr, 10);
        //let activeIndex = getActiveSlideIndex();

        if (realIndex === visibleIndex) {
          if (player.paused()) {
            try {
              if (!isPaused) {
                player.play()?.then(() => {
                  setPlayerState('slickInPlay', virtualIndex); // Atualizar `slickInPlay`
                }).catch((error: any) => {
                  console.error("Erro ao tentar reproduzir o vídeo: ", error);
                });
              }
            } catch (error) {
              console.error("Erro capturado durante a tentativa de tocar o vídeo:", error);
            }
          }
        }
      });
    }

    if (!player) {
      console.error('Player não foi inicializado corretamente.');
      return;
    }

    // Configuração inicial do player
    this.setupPlayerEvents(player, setPlayerState, pauseOhtersCallback, cdrDetectChanges, virtualIndex, realIndex);

    // Pausar outros players
    pauseOhtersCallback();
  }

  // Método auxiliar para configurar eventos do player
  private setupPlayerEvents(
    player: any,
    setPlayerState: (key: string, value: any) => void,
    pauseOhtersCallback: () => void,
    cdrDetectChanges: () => void,
    virtualIndex: number,
    realIndex: number
  ) {
    console.log('realIndex', realIndex);
    player.ready(() => {
      player.on('play', () => {
        setPlayerState('slickInPlay', virtualIndex);
        player.muted(this.videoMuteService.getMuteState());
      });

      player.on('timeupdate', () => {
        const currentTime = player.currentTime();
        setPlayerState('lastCurrentTime', currentTime);
        cdrDetectChanges();
      });

      player.on('playing', () => {
        setPlayerState('isPlaying', true);
        cdrDetectChanges();
      });

      player.on('pause', () => {
        setPlayerState('isPlaying', false);
        cdrDetectChanges();
      });

      player.on('ended', () => {
        setPlayerState('isPlaying', false);
        cdrDetectChanges();
        pauseOhtersCallback();
      });
    });
  }
}
