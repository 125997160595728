import { Component, Input, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent {
  @Input() fullScreen: boolean = false;
  @Input() valSelect1Video: any;
  @Input() videoUrl?: string;


  @ViewChild('videoElement') videoElementRef?: ElementRef;
  private player: any;
  myButtonDom: any;
  // initVideoPlayer(): void {

  //   if (this.videoElementRef && this.videoElementRef.nativeElement) {

  //     this.player = videojs(this.videoElementRef.nativeElement);

  //     this.player.ready(() => {
  //       // Esconde o botão de play grande quando o vídeo começa a ser reproduzido fff
  //       this.player.on("play", () => {
  //         this.player.bigPlayButton.hide();
  //       });

  //       // Encontra o índice do botão de fullscreen antes de removê-lo
  //       const fullscreenButton = this.player.controlBar.getChild('FullscreenToggle');
  //       const index = this.player.controlBar.children().indexOf(fullscreenButton);

  //       // Remove o botão de toggle de fullscreen da barra de controle
  //       this.player.controlBar.removeChild(fullscreenButton);

  //       // Adiciona um botão personalizado na posição do botão de fullscreen
  //       const myButton = this.player.controlBar.addChild('button', {}, index);
  //       this.myButtonDom = myButton.el();

  //       // Define o HTML interno do botão e adiciona um evento de clique
  //       if (this.fullScreen) {
  //         this.myButtonDom.innerHTML = "<i class='pi pi-window-minimize active' style='cursor:pointer;'></i>";
  //       } else {
  //         this.myButtonDom.innerHTML = "<i class='pi pi-window-maximize active' style='cursor:pointer;'></i>";
  //       }
  //       this.myButtonDom.onclick = () => {
  //         if (this.fullScreen) {
  //           this.goMinscreen();
  //         } else {
  //           this.goFullscreen();
  //         }
  //       };


  //       console.log('acima de marginTopVideo()')
  //       this.marginTopVideo();
  //       this.adjustVideoMargin();


  //       this.playerBorderRadius();
  //       //this.moveControlBar();
  //     });
  //   }else{
  //     console.log('não achou elemento de vídeo');
  //   }
  // }


  // goFullscreen() {
  //   console.log(this.valSelect1Video.value)
  //   this.fullScreen = true;
  //   //this.showMenuInf = false;
  //   if (this.videoUploadContainer && this.videoUploadContainer.nativeElement) {

  //     const elem = this.videoUploadContainer.nativeElement;
  //     if (elem.requestFullscreen) {
  //       elem.requestFullscreen();
  //     } else if (elem.mozRequestFullScreen) { /* Firefox */
  //       elem.mozRequestFullScreen();
  //     } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
  //       elem.webkitRequestFullscreen();
  //     } else if (elem.msRequestFullscreen) { /* IE/Edge */
  //       elem.msRequestFullscreen();
  //     }
  //     this.fullScreen = true;
  //     this.adjustVideoMargin();
  //     this.adjustVideoMarginSubMenu();
  //     ////////////////////////
  //     if (this.fullScreen) {
  //       this.myButtonDom.innerHTML = "<i class='pi pi-window-minimize' style='cursor:pointer;'></i>";
  //     } else {
  //       this.myButtonDom.innerHTML = "<i class='pi pi-window-maximize' style='cursor:pointer;'></i>";
  //     }
  //     this.myButtonDom.onclick = () => {
  //       if (this.fullScreen) {
  //         this.goMinscreen();
  //       } else {
  //         this.goFullscreen();
  //       }
  //     };

  //     ////////////////////////
  //   }
  //   console.log(this.fullScreen)
  //   //if (this.valSelect1Video.value == 2) {
  //   this.marginTopVideo()
  //   //}

  //   if (this.valSelect1Video.value == 1) {
  //     this.aplicarEstiloFullscreen();
  //   }
  //   this.playerBorderRadius();
  // }
  // goMinscreen = () => {
  //   this.fullScreen = false;
  //   this.showMenuInf = false;
  //   if (document.fullscreenElement) {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } else if ((document as any).webkitExitFullscreen) { // Safari
  //       (document as any).webkitExitFullscreen();
  //     } else if ((document as any).mozCancelFullScreen) { // Firefox
  //       (document as any).mozCancelFullScreen();
  //     } else if ((document as any).msExitFullscreen) { // IE e Edge
  //       (document as any).msExitFullscreen();
  //     }

  //   } else {
  //     console.log('Executar ação alternativa para a tecla Esc');
  //   }
  //   ////////////////////////
  //   if (this.fullScreen) {
  //     this.myButtonDom.innerHTML = "<i class='pi pi-window-minimize' style='cursor:pointer;'></i>";
  //   } else {
  //     this.myButtonDom.innerHTML = "<i class='pi pi-window-maximize' style='cursor:pointer;'></i>";
  //   }
  //   this.myButtonDom.onclick = () => {
  //     if (this.fullScreen) {
  //       this.goMinscreen();
  //     } else {
  //       this.goFullscreen();
  //     }
  //   };
  //   this.marginTopVideo();
  //   if (this.valSelect1Video.value == 1) {
  //     this.aplicarEstiloFullscreen();
  //   }
  //   this.playerBorderRadius();
  //   ////////////////////////
  //   this.adjustVideoMargin()
  // }

  // marginTopVideo() {
  //   const videoJsDivs = document.getElementsByClassName('video-js');
  //   const videoContainer = document.getElementsByClassName('video-upload-container');
  //   let marginTop = 0;

  //   if (videoJsDivs.length > 0) {
  //     if (this.fullScreen && this.valSelect1Video.value == 2) {
  //       //const windowHeight = window.innerHeight;
  //       let videoHeight = (videoJsDivs[0] as HTMLElement).offsetHeight;
  //       let videoWidth = (videoJsDivs[0] as HTMLElement).offsetWidth;
  //       let videoContainerHeight = (videoContainer[0] as HTMLElement).offsetHeight;
  //       const userAgent = navigator.userAgent;

  //       //////////////////////
  //       const windowHeight = window.innerHeight;
  //       const windowWidth = window.innerWidth;
  //       let videoAspectRatio = videoWidth / videoHeight;

  //       const windowAspectRatio = windowWidth / windowHeight;

  //       //let videoHeight, videoWidth, marginTop;

  //       // Se a proporção da janela for maior que a proporção do vídeo, ajustar pela altura
  //       if (windowAspectRatio > videoAspectRatio) {
  //         videoHeight = windowHeight; // Faz o vídeo preencher a altura
  //         videoWidth = videoHeight * videoAspectRatio; // Ajusta a largura para manter a proporção do vídeo
  //         marginTop = 0; // Sem necessidade de ajuste vertical, pois o vídeo preenche a altura
  //       } else {
  //         // Ajusta pela largura
  //         videoWidth = windowWidth; // Faz o vídeo preencher a largura
  //         videoHeight = videoWidth / videoAspectRatio; // Ajusta a altura para manter a proporção
  //         marginTop = (windowHeight - videoHeight) / 2; // Calcula o marginTop para centralizar verticalmente
  //       }
  //       /////////////////////
  //       (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
  //       if (this.isMobileOS(userAgent)) {
  //         console.log('ios')

  //         if (this.fullScreen) {
  //           marginTop = (windowHeight - videoHeight) / 2;
  //         } else {
  //           console.log(this.isIpad(userAgent));
  //           if (!this.isIpad) {
  //             marginTop = (windowHeight - videoHeight) / 2;
  //           }


  //         }
  //         console.log(marginTop);
  //         if (marginTop >= 0) {
  //           console.log('222222');
  //           if (this.isIpad(userAgent)) {
  //             (videoJsDivs[0] as HTMLElement).style.top = '60%';
  //           } else {
  //             (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
  //           }

  //         } else {
  //           console.log('333333');
  //           (videoJsDivs[0] as HTMLElement).style.top = '100%';
  //         }



  //       } else if (this.detectDevice() == 'mobile') {
  //         console.log('mobile')
  //         if (this.fullScreen) {
  //           marginTop = (windowHeight - videoHeight) / 2;
  //         } else {
  //           marginTop = (videoContainerHeight - videoHeight) / 2;
  //         }

  //         if (marginTop >= 0) {
  //           (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
  //         } else {
  //           (videoJsDivs[0] as HTMLElement).style.top = '100%';
  //         }
  //       } else if (this.detectDevice() == 'tablet') {
  //         console.log('tablet')
  //         if (this.fullScreen) {
  //           marginTop = (windowHeight - videoHeight) / 2;
  //         } else {
  //           marginTop = (videoContainerHeight - videoHeight) / 2;
  //         }
  //         if (marginTop >= 0) {
  //           (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
  //         } else {
  //           (videoJsDivs[0] as HTMLElement).style.top = '100%';
  //         }
  //       } else if (this.detectDevice() == 'desktop') {
  //         console.log('desktop')
  //         marginTop = 0;
  //         //console.log(window.innerWidth);
  //         if (this.fullScreen) {
  //           if (window.innerWidth >= 821) {
  //             console.log('desktop');
  //             (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
  //           } else {
  //             console.log('tablet');
  //             marginTop = (windowHeight - videoHeight) / 2;
  //             (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
  //           }
  //         } else {
  //           (videoJsDivs[0] as HTMLElement).style.top = '100%';
  //         }

  //       }
  //     } else if (this.valSelect1Video.value == 2) {
  //       marginTop = 100;
  //       console.log('imaginado');
  //       //(videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}%`;

  //       (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}%`;

  //     } else if (this.valSelect1Video.value == 1) {
  //       marginTop = 0;
  //       //(videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}%`;
  //       if (marginTop < 0) {
  //         marginTop = 0;
  //       }
  //       (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}%`;

  //     }



  //   }
  // }

  // adjustVideoMargin() {
  //   const videoJsDivs = document.getElementsByClassName('video-js');
  //   if (this.fullScreen) {
  //     const zoomLevel = window.innerWidth / window.screen.width;
  //     //   this.screenWidth = window.screen.width * zoomLevel; // Ajustar largura da tela ao nível de zoom
  //     // this.screenHeight = window.screen.height * zoomLevel; 
  //     console.log('entrou ajuste');
  //     const screenWidth = window.screen.width * zoomLevel;
  //     const screenHeight = window.screen.height * zoomLevel;
  //     let marginLeft = 0;
  //     let marginRight = 0;
  //     let barLeft = 0;

  //     const videoJsControlBar = document.getElementsByClassName('vjs-control-bar');
  //     const videoJsSpinner = document.getElementsByClassName('vjs-loading-spinner');
  //     if (videoJsDivs.length > 0) {
  //       const videoWidth = (videoJsDivs[0] as HTMLElement).offsetWidth;
  //       if (this.fullScreen && this.valSelect1Video.value === 1) {
  //         const larguraDoVideo = screenHeight * (9 / 16);
  //         marginLeft = (screenWidth / 2) - (larguraDoVideo / 2);
  //         if (marginLeft < 0) {
  //           barLeft = -marginLeft;
  //         } else {
  //           barLeft = -marginLeft;
  //         }
  //         marginRight = marginLeft;
  //       } else if (!this.fullScreen) {
  //         marginLeft = 0;
  //         barLeft = 0;

  //       }


  //       if (this.fullScreen) {
  //         (videoJsDivs[0] as HTMLElement).style.marginLeft = `${marginLeft}px`;
  //         //(videoJsSpinner[0] as HTMLElement).style.marginLeft = `${marginLeft}px`;

  //         if (this.fullScreen) {
  //           (videoJsControlBar[0] as HTMLElement).style.width = 'auto';
  //         }


  //         //if (!this.isMobileDevice()) {
  //         (videoJsDivs[0] as HTMLElement).style.marginRight = `${marginLeft - 1}px`;
  //         //}
  //       } else {
  //         if (this.valSelect1Video.value === 2) {
  //           (videoJsDivs[0] as HTMLElement).style.width = `${100}%`;
  //         }
  //       }

  //     }
  //   } else {

  //     const screenWidth = window.innerWidth;

  //     console.log('mmmm');
  //     console.log(screenWidth);
  //     if (screenWidth < 1733) {
  //       (videoJsDivs[0] as HTMLElement).style.marginRight = '1px';
  //     } else if (screenWidth < 1800) {
  //       if (this.valSelect1Video.value === 1) {
  //         (videoJsDivs[0] as HTMLElement).style.marginRight = '2px';
  //       } else {
  //         (videoJsDivs[0] as HTMLElement).style.marginRight = '3px';
  //       }

  //     } else {
  //       if (this.valSelect1Video.value === 1) {
  //         (videoJsDivs[0] as HTMLElement).style.marginRight = '2px';
  //       } else {
  //         (videoJsDivs[0] as HTMLElement).style.marginRight = '3px';
  //       }
  //     }
  //     (videoJsDivs[0] as HTMLElement).style.marginLeft = '0px';

  //   }

  // }
  // playerBorderRadius() {
  //   const videoTechElement = this.el.nativeElement.querySelector('.vjs-tech');
  //   if (this.valSelect1Video.value === 1) {
  //     if (this.fullScreen) {
  //       this.renderer.setStyle(videoTechElement, 'border-radius', '0px');
  //     } else {
  //       if (this.isMobileDevice()) {
  //         this.renderer.setStyle(videoTechElement, 'border-radius', '10px 10px 0px 0px');
  //       } else {
  //         this.renderer.setStyle(videoTechElement, 'border-radius', '10px 10px 12px 12px');
  //       }

  //     }

  //   } else {
  //     this.renderer.setStyle(videoTechElement, 'border-radius', '0px');
  //   }
  // }
}
