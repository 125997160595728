<p-sidebar [(visible)]="displaySidebar" #sidebar id="ss-l-sideBarBottom" class="ss-g-custom-sidebar-width"
    position="right" [modal]="false">
    <ng-template pTemplate="header">
        <div class="flex flex-column">
            <h3 class="mb-3">Configurações</h3>
            <p class="mt-0">Customize o conteúdo de seu widget, insira em suas páginas, compartilhe, com seus clientes e
                muito mais.</p>
        </div>
    </ng-template>
    <div class="card p-1 mb-1">
        <div class="mb-2 w-full">
            <div class="mt-2 w-full">
                <p-tabMenu [model]="ctaOptions" [(activeItem)]="activeItem" [scrollable]="true">
                </p-tabMenu>
            </div>
        </div>
    </div>
    <div *ngIf="activeOption === 0">
        <div class="p-0">
            <div class="card shadow-none">
                <div class="flex flex-column mb-4">
                    <label class="w-full ss-g-globalClassLabel">
                        Título
                        <div class="p-inputgroup mt-2">
                            <input type="text" id="title" pInputText class="w-full" (ngModelChange)="onInputChange()"
                                [(ngModel)]="widgetTitle" (input)="checkInputTitle();"
                                [ngClass]="{'ng-invalid': hasErrorTitle, 'ng-dirty': hasErrorTitle}">
                        </div>
                    </label>
                    <div class="flex align-items-center justify-content-between">
                        <small *ngIf="hasErrorTitle" class="p-error">
                            Obrigatório
                        </small>

                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label for="typeSelect" class="w-full ss-g-globalClassLabel">
                        Tipo de feed
                        <p-dropdown [(ngModel)]="selectedWidgetType" id="typeSelect"
                            (ngModelChange)="onFeedTypeChange($event)" [options]="filteredResults" optionLabel="label"
                            optionValue="value" class="w-full mt-2 ss-g-sidebarVendas"
                            placeholder="Selecione o tipo de feed"
                            [ngClass]="{'ng-invalid': hasError1, 'ng-dirty': hasError1}">
                        </p-dropdown>
                    </label>
                    <div class="flex align-items-center justify-content-between">
                        <small *ngIf="hasError1" class="p-error">
                            Obrigatório
                        </small>
                    </div>
                </div>

                <div class="flex flex-column mb-4"
                    *ngIf="(selectedWidgetType === 'manual' || selectedWidgetType === 'tags') && !isNewWidget">
                    <label for="typeSelect" class="w-full ss-g-globalClassLabel">
                        Tipo de Slick
                        <p-dropdown [(ngModel)]="selectedSlickType" id="typeSelect" [options]="filteredResultsSlicks"
                            optionLabel="label" optionValue="value" class="w-full mt-2 ss-g-sidebarVendas"
                            (onChange)="onSlickTypeChange($event.value)"
                            [ngClass]="{'ng-invalid': hasError2, 'ng-dirty': hasError2}">
                        </p-dropdown>
                    </label>
                    <div class="flex align-items-center justify-content-between">
                        <small *ngIf="hasError2" class="p-error">
                            Obrigatório
                        </small>
                    </div>
                </div>

                <div class="flex flex-column mb-4" *ngIf="selectedWidgetType === 'manual' && !isNewWidget">
                    <label for="typeSelect" class="w-full ss-g-globalClassLabel mb-2">Adicione Slicks ao widget</label>
                    <div *ngIf="!isMobileDevice()">
                        <p-pickList [source]="loading ? skeletonSource : sourceProducts" [target]="targetProducts"
                            (onReorder)="onReorder($event)" [dragdrop]="isDragDrop"
                            targetHeader="Selecionados ({{targetProducts.length}})" [responsive]="true" class="z-2"
                            [dragdrop]="true" sourceFilterPlaceholder="Buscar por nome"
                            targetFilterPlaceholder="Buscar por nome" (onMoveToSource)="onMoveToSource($event)"
                            (onMoveToTarget)="onMoveToTarget($event)">
                            <ng-template pTemplate="sourceHeader">
                                <div>
                                    Disponíveis ({{loading ? 0 : sourceProducts.length}}) <i *ngIf="isDragDrop"
                                        class="pi pi-arrows-alt p-2"></i>
                                </div>
                            </ng-template>
                            <ng-template let-product pTemplate="item">
                                <div *ngIf="loading; else productContent"
                                    class="flex flex-wrap p-2 align-items-center gap-3">
                                    <p-skeleton width="100px" height="60px" styleClass="mr-2"></p-skeleton>
                                    <div class="flex flex-column ml-3" style="flex: 1;">
                                        <p-skeleton width="150px" height="1.5rem" class="mb-2"></p-skeleton>
                                        <p-skeleton width="100px" height="1.2rem"></p-skeleton>
                                    </div>
                                </div>
                                <ng-template #productContent>
                                    <div class="flex flex-wrap p-2 align-items-center gap-3">
                                        <div class="image-container-9-16">
                                            <img *ngIf="product.thumbnail"
                                                class="image-content shadow-2 flex-shrink-0 border-round"
                                                [src]="product.thumbnail | sanitizeUrl" alt="{{ product.name }}" />
                                        </div>
                                        <div class="flex-1 flex flex-column gap-2">
                                            <span class="font-bold">{{ product.title }}</span>
                                            <div class="flex align-items-center gap-2">
                                                <i class="pi pi-tag text-sm"></i>
                                                <span>{{ product.type }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </p-pickList>
                    </div>
                    <div *ngIf="isMobileDevice()">
                        <p-pickList [source]="loading ? skeletonSource : sourceProducts" [target]="targetProducts"
                            (onReorder)="onReorder($event)" [dragdrop]="isDragDrop"
                            targetHeader="Selecionados ({{targetProducts.length}})" [responsive]="true" class="z-2"
                            sourceFilterPlaceholder="Buscar por nome" targetFilterPlaceholder="Buscar por nome"
                            (onMoveToSource)="onMoveToSource($event)" (onMoveToTarget)="onMoveToTarget($event)">

                            <ng-template pTemplate="sourceHeader">
                                <div>
                                    Disponíveis ({{loading ? 0 : sourceProducts.length}})
                                    <i *ngIf="isDragDrop" class="pi pi-arrows-alt p-2"></i>
                                </div>
                            </ng-template>

                            <ng-template let-product pTemplate="item">
                                <div *ngIf="loading; else productContent"
                                    class="flex flex-wrap p-2 align-items-center gap-3">
                                    <p-skeleton width="100px" height="60px" styleClass="mr-2"></p-skeleton>
                                    <div class="flex flex-column ml-3" style="flex: 1;">
                                        <p-skeleton width="150px" height="1.5rem" class="mb-2"></p-skeleton>
                                        <p-skeleton width="100px" height="1.2rem"></p-skeleton>
                                    </div>
                                </div>

                                <ng-template #productContent>
                                    <div class="flex flex-wrap p-2 align-items-center gap-3 product-item">

                                        <!-- Ícone de arraste -->
                                        <div class="drag-handle" (touchstart)="onTouchStart($event)"
                                            (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd($event)">
                                            <i class="pi pi-arrows-alt p-2"></i>
                                        </div>

                                        <!-- Informações do Produto -->
                                        <div class="image-container-9-16">
                                            <img *ngIf="product.thumbnail"
                                                class="image-content shadow-2 flex-shrink-0 border-round"
                                                [src]="product.thumbnail | sanitizeUrl" alt="{{ product.name }}" />
                                        </div>

                                        <div class="flex-1 flex flex-column gap-2">
                                            <span class="font-bold">{{ product.title }}</span>
                                            <div class="flex align-items-center gap-2">
                                                <i class="pi pi-tag text-sm"></i>
                                                <span>{{ product.type }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </p-pickList>



                    </div>
                </div>
                <div class="flex align-items-center mb-4" *ngIf="selectedWidgetType === 'tags' && !isNewWidget">
                    <p-inputSwitch [(ngModel)]="limitShowItens" (onChange)="onSwitchChange($event)"
                        class="mr-2"></p-inputSwitch>
                    <label>Limitar quantidade exibida</label>
                </div>
                <div class="flex align-items-center mb-4" *ngIf="limitShowItens && selectedWidgetType === 'tags'">
                    <div *ngIf="limitShowItens" class="mt-2 w-full">
                        <div class="input-wrapper-vencimento w-full">
                            <p-inputNumber [(ngModel)]="manualValue" class="w-full ss-g-inputM" locale="pt-BR"
                                placeholder="Ex.: 2"></p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="flex flex-column w-full mt-2" *ngIf="selectedWidgetType === 'tags' && !isNewWidget">
                    <label class="w-full ss-g-globalClassLabel mb-2">Adicione Slicks por tags</label>
                    <div class="p-fluid bg-blue-200 p-2 shadow-none border-black-alpha-30 w-full mb-2">
                        <p-chips [(ngModel)]="valuesSlicksTags" separator="," placeholder="ex: a, b, c"
                            (ngModelChange)="showSlickClearFunction();"></p-chips>
                        <div class="flex-container" *ngIf="showClearSlicksTags">
                            <a class="ss-g-remove-coupon mt-2" (click)="clearSlickMaps()">
                                <i class="pi pi-times"></i> Limpar
                            </a>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
    <div *ngIf="activeOption === 1">
        <div class="card shadow-none">
            <div class="flex flex-column mb-4">
                <label class="w-full ss-g-globalClassLabel">Template</label>
                <div *ngFor="let template of filteredTemplates"
                    class="field-checkbox surface-border border-1 mb-4 card shadow-none p-0" [ngClass]="{
                        'selected-integration': template.key === selectedTemplate,
                        'disabled-template': isTemplateDisabled(template.key)
                    }" (click)="!isTemplateDisabled(template.key) && (selectedTemplate = template.key)">
                    <p-radioButton [inputId]="template.key" class="ml-2" name="template" [value]="template.key"
                        [(ngModel)]="selectedTemplate" [ngModelChange]="checkTempalte()"
                        [disabled]="isTemplateDisabled(template.key)"></p-radioButton>
                    <div class="p-col-fixed col-10 flex ss-l-template-opc" style="height: 70.5px;align-items: center;">
                        <i [ngClass]="template.icon" class="mr-2 text-4xl"></i>
                        <div style="display: flex;align-items: center;">
                            <label [for]="template.key" class="ml-2">{{ template.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-column mb-4" *ngIf="selectedTemplate === 'popup'  || selectedTemplate === 'carousel'">
                <label for="typeSelect" class="w-full ss-g-globalClassLabel">
                    Posição</label>
                <p-dropdown id="positionSelect" class="mt-2" [options]="popupPositions"
                    [(ngModel)]="selectedPopupPosition" placeholder="Selecione a posição"></p-dropdown>

            </div>
            <div class="flex flex-column mb-4" *ngIf="selectedTemplate === 'iframe' || selectedTemplate === 'inline'">
                <label for="resolutionSelect" class="w-full ss-g-globalClassLabel">
                    Resolução (pixels)
                </label>
                <p-dropdown [options]="resolutions" class="mt-2" [(ngModel)]="selectedResolution"
                    placeholder="Selecione uma resolução" (ngModelChange)="onResolutionChange($event)"></p-dropdown>

            </div>
            <div class="flex align-items-center mb-4"
                *ngIf="selectedTemplate === 'inline' || selectedTemplate === 'carousel'">
                <p-inputSwitch [(ngModel)]="showDescription" (onChange)="onSwitchChangeDescription($event)"
                    class="mr-2"></p-inputSwitch>
                <label>Título e Descrição</label>
            </div>

            <div class="flex flex-column mb-4" *ngIf="selectedTemplate === 'popup'">
                <label class="w-full ss-g-globalClassLabel">
                    Highlight</label>
                <div class="p-inputgroup mt-2">
                    <input type="text" id="highlight" pInputText class="w-full" [(ngModel)]="highlight"
                        placeholder="Ex.: Confira aqui!">
                </div>

            </div>
            <div class="flex flex-column mb-4" *ngIf="selectedTemplate === 'popup'">
                <label for="typeSelect" class="w-full ss-g-globalClassLabel">
                    Shape</label>
                <p-dropdown id="shapeSelect" class="mt-2" [options]="shapeOptions" [(ngModel)]="selectedShape"
                    placeholder="Selecione o shape"></p-dropdown>
            </div>
            <div class="flex align-items-center mb-4"
                *ngIf="selectedTemplate === 'inline'  || selectedTemplate === 'carousel'">
                <p-inputSwitch [(ngModel)]="showProducts" (onChange)="onSwitchChangeProducts($event)"
                    class="mr-2"></p-inputSwitch>
                <label>Exibir Produto</label>
            </div>
            <div class="flex flex-column mb-2" *ngIf="selectedTemplate === 'popup'  || selectedTemplate === 'carousel'">
                <label for="bgColor" class="ss-g-globalClassLabel">Cor do fundo</label>
                <div class="mt-2"></div>
                <p-colorPicker class="mt-2 mb-2" id="bgColor" [(ngModel)]="bg_color" format="hex"
                    [style]="{'width':'100%'}"></p-colorPicker>
            </div>
            <div class="flex flex-column mb-2" *ngIf="selectedTemplate === 'popup'  || selectedTemplate === 'carousel'">
                <label for="fontColor" class="ss-g-globalClassLabel">Cor da fonte</label>
                <div class="mt-2"></div>
                <p-colorPicker class="mt-2 mb-2" id="fontColor" [(ngModel)]="font_color" format="hex"
                    [style]="{'width':'100%'}"></p-colorPicker>
            </div>

        </div>
    </div>
    <div *ngIf="activeOption === 2">


        <p-tabView class="tabViewEmbbeding">
            <p-tabPanel *ngIf="hasIntegration">
                <ng-template pTemplate="header">
                    <img src="https://app.krabo.io/img/shopify_glyph.png" alt="Shopify Icon" class="icon-tab" />
                    <span>Shopify</span>
                </ng-template>
                <!---->
                <div class="card shadow-none">
                    <div class="flex flex-column mb-4"
                        *ngIf="selectedTemplate === 'carousel' || selectedTemplate === 'grid' || selectedTemplate === 'iframe' || selectedTemplate === 'inline' || selectedTemplate === 'stories'">
                        <p class="mt-4 mb-0"><b>Passo 1 - Adicione o widget às páginas</b></p>
                        <p>Adicione o script abaixo na parte do seu site que gostaria de exibir se widget.</p>

                        <div class="p-inputgroup ss-g-custom-inputgroup">
                            <input type="text" pInputText [(ngModel)]="widget_share" readonly class="custom-input">
                            <button pButton pRipple type="button" icon="pi pi-copy" (click)="copiarWidget()"
                                class="ss-l-custom-button"></button>

                        </div>
                        <div class="mt-2" *ngIf="selectedTemplate === 'iframe'"><b>Obs.:</b> É preciso definir largura e
                            altura
                            para este formato</div>
                    </div>
                    <div class="flex flex-column mb-4" *ngIf="selectedTemplate === 'iframe'">

                        <p>
                            <b>Passo 2 - </b> Para garantir que o iFrame funcione em sua página ou aplicação,
                            certifique-se de que seu domínio já foi incluído em:
                        </p>
                        <p-breadcrumb [model]="breadcrumbItems"></p-breadcrumb>


                    </div>
                    <div class="flex flex-column mb-4" *ngIf="selectedTemplate !== 'iframe'">
                        <p class="mt-4 mb-0">
                            <b>Passo
                                <span
                                    *ngIf="selectedTemplate === 'carousel' || selectedTemplate === 'grid' || selectedTemplate === 'inline' || selectedTemplate === 'stories' || selectedTemplate === 'iframe'; else stepTwo">
                                    2
                                </span>
                                <ng-template #stepTwo>1</ng-template>
                                - Defina em quais páginas exibir o widget
                            </b>
                        </p>
                        <p>Defina as URLs ou regras para exibição do widget em suas páginas.</p>



                        <!-- -->
                        <!-- Select Button para escolher entre 'igual' e 'contém' -->
                        <p-selectButton [options]="conditionOptions" [(ngModel)]="selectedCondition" optionLabel="label"
                            optionValue="value" class="mb-2 w-full"></p-selectButton>


                        <!-- Componente p-chips que aparece quando a condição é 'igual' -->
                        <div *ngIf="selectedCondition === 'igual'" class="mt-3 w-full">
                            <!-- Input que abre o modal -->
                            <!-- <input type="text" pInputText [(ngModel)]="valuesEqualTagsString"
                                placeholder="Digite valores para 'Igual'" class="w-full s-s-l-chips" readonly
                                (click)="showCommandMenuModal()" /> -->

                            <p-chips [(ngModel)]="valuesEqualTags" separator="," (click)="showCommandMenuModal()"
                                readonlyInput="true" removable="false" placeholder="Digite valores para 'Igual'"
                                class="w-full s-s-l-chips ss-l-no-remove-chips"
                                (ngModelChange)="showEqualClearFunction()"></p-chips>
                            <!-- <div class="flex-container " *ngIf="showClearEqualTags">
                                <a class="ss-g-remove-coupon mt-2" (click)="clearEqualTags()">
                                    <i class="pi pi-times"></i> Limpar
                                </a>
                            </div> -->
                        </div>

                        <!-- Componente p-chips que aparece quando a condição é 'contém' -->
                        <div *ngIf="selectedCondition === 'contem'" class="mt-3 w-full">
                            <p-chips [(ngModel)]="valuesContainTags" separator=","
                                placeholder="Digite valores para 'Contém'" class="w-full s-s-l-chips"
                                (ngModelChange)="showContainClearFunction()"></p-chips>
                            <div class="flex-container" *ngIf="showClearContainTags">
                                <a class="ss-g-remove-coupon mt-2" (click)="clearContainTags()">
                                    <i class="pi pi-times"></i> Limpar
                                </a>
                            </div>
                        </div>
                        <!-- -->

                    </div>
                    <div class="flex flex-column mb-4" *ngIf="selectedTemplate !== 'iframe'">
                        <label class="w-full ss-g-globalClassLabel"><b>Devices</b></label>

                        <div class="flex align-items-center mb-2">
                            <p-inputSwitch [(ngModel)]="showSmartphones" (onChange)="onSwitchChangeMobile($event)"
                                class="mr-2"></p-inputSwitch>
                            <label>Exibir em <b>Smartphones</b></label>
                        </div>

                        <div class="flex align-items-center">
                            <p-inputSwitch [(ngModel)]="showDesktops" (onChange)="onSwitchChangeDesktop($event)"
                                class="mr-2"></p-inputSwitch>
                            <label>Exibir em <b>Desktops</b></label>
                        </div>
                    </div>

                </div>
                <!---->
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="https://app.krabo.io/img/html.png" alt="HTML Icon" class="icon-tab" />
                    <span>HTML</span>
                </ng-template>
                <!-- Manter em HTML-->
                <div class="card shadow-none">
                    <div class="flex flex-column mb-4">
                        <label class="w-full ss-g-globalClassLabel">Passo 1 - Adicione o pixel Slicksell ao header de
                            seu
                            site</label>
                        <p>Você só precisa adicionar esse script apenas uma vez ao seu site. Se o script já foi
                            inserido, pule
                            esta etapa.</p>

                        <div class="p-inputgroup ss-g-custom-inputgroup mb-2">
                            <input type="text" pInputText [(ngModel)]="script_share" readonly class="custom-input">
                            <button pButton pRipple type="button" icon="pi pi-copy" (click)="copiarScript()"
                                class="ss-l-custom-button"></button>
                        </div>

                    </div>
                    <div class="flex flex-column mb-4"
                        *ngIf="selectedTemplate === 'carousel' || selectedTemplate === 'grid' || selectedTemplate === 'iframe' || selectedTemplate === 'inline' || selectedTemplate === 'stories'">
                        <p class="mt-4 mb-0"><b>Passo 2 - Adicione o widget às páginas</b></p>
                        <p>Adicione o script abaixo na parte do seu site que gostaria de exibir se widget.</p>

                        <div class="p-inputgroup ss-g-custom-inputgroup">
                            <input type="text" pInputText [(ngModel)]="widget_share" readonly class="custom-input">
                            <button pButton pRipple type="button" icon="pi pi-copy" (click)="copiarWidget()"
                                class="ss-l-custom-button"></button>

                        </div>
                        <div class="mt-2" *ngIf="selectedTemplate === 'iframe'"><b>Obs.:</b> É preciso definir largura e
                            altura
                            para este formato</div>
                    </div>
                    <div class="flex flex-column mb-4" *ngIf="selectedTemplate === 'iframe'">

                        <p>
                            <b>Passo 3 - </b> Para garantir que o iFrame funcione em sua página ou aplicação,
                            certifique-se de que seu domínio já foi incluído em:
                        </p>
                        <p-breadcrumb [model]="breadcrumbItems"></p-breadcrumb>


                    </div>
                    <div class="flex flex-column mb-4" *ngIf="selectedTemplate !== 'iframe'">
                        <p class="mt-4 mb-0"><b>Passo
                                <span
                                    *ngIf="selectedTemplate === 'carousel' || selectedTemplate === 'grid' || selectedTemplate === 'inline' || selectedTemplate === 'stories' || selectedTemplate === 'iframe'; else stepTwo">
                                    3
                                </span>
                                <ng-template #stepTwo>2</ng-template>
                                - Defina em quais páginas exibir o widget</b></p>
                        <p>Defina as URLs ou regras para exibição do widget em suas páginas.</p>



                        <!-- -->
                        <!-- Select Button para escolher entre 'igual' e 'contém' -->
                        <p-selectButton [options]="conditionOptions" [(ngModel)]="selectedCondition" optionLabel="label"
                            optionValue="value" class="mb-2 w-full"></p-selectButton>


                        <!-- Componente p-chips que aparece quando a condição é 'igual' -->
                        <div *ngIf="selectedCondition === 'igual'" class="mt-3 w-full">
                            <p-chips [(ngModel)]="valuesEqualTags" separator=","
                                placeholder="Digite valores para 'Igual'" class="w-full s-s-l-chips"
                                (ngModelChange)="showEqualClearFunction()"></p-chips>
                            <div class="flex-container " *ngIf="showClearEqualTags">
                                <a class="ss-g-remove-coupon mt-2" (click)="clearEqualTags()">
                                    <i class="pi pi-times"></i> Limpar
                                </a>
                            </div>
                        </div>

                        <!-- Componente p-chips que aparece quando a condição é 'contém' -->
                        <div *ngIf="selectedCondition === 'contem'" class="mt-3 w-full">
                            <p-chips [(ngModel)]="valuesContainTags" separator=","
                                placeholder="Digite valores para 'Contém'" class="w-full s-s-l-chips"
                                (ngModelChange)="showContainClearFunction()"></p-chips>
                            <div class="flex-container" *ngIf="showClearContainTags">
                                <a class="ss-g-remove-coupon mt-2" (click)="clearContainTags()">
                                    <i class="pi pi-times"></i> Limpar
                                </a>
                            </div>
                        </div>
                        <!-- -->

                    </div>
                    <div class="flex flex-column mb-4" *ngIf="selectedTemplate !== 'iframe'">
                        <label class="w-full ss-g-globalClassLabel"><b>Devices</b></label>

                        <div class="flex align-items-center mb-2">
                            <p-inputSwitch [(ngModel)]="showSmartphones" (onChange)="onSwitchChangeMobile($event)"
                                class="mr-2"></p-inputSwitch>
                            <label>Exibir em <b>Smartphones</b></label>
                        </div>

                        <div class="flex align-items-center">
                            <p-inputSwitch [(ngModel)]="showDesktops" (onChange)="onSwitchChangeDesktop($event)"
                                class="mr-2"></p-inputSwitch>
                            <label>Exibir em <b>Desktops</b></label>
                        </div>
                    </div>

                </div>
                <!-- Manter em HTML-->
            </p-tabPanel>
        </p-tabView>





    </div>

    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple label="Fechar" (click)="closeCopyTemplate(0)" class="p-button-outlined "></button>
            <!-- <button pButton pRipple label="Salvar" [loading]="isLoadingSave" (click)="createWidget()"
                [disabled]="saveBtnDisabled" class="ml-2 mr-2"></button> -->
            <button *ngIf="createWidgetBtn" pButton pRipple label="Criar" [loading]="isLoadingSave"
                (click)="createWidget()" class="ml-2 mr-2"></button>
            <button *ngIf="!createWidgetBtn" pButton pRipple label="Salvar" [loading]="isLoadingSave"
                (click)="editWidget(1)" class="ml-2 mr-2"></button>
        </div>
    </ng-template>
</p-sidebar>
<p-toast></p-toast>
<div class="flex justify-content-center align-items-center">
    <div class="col col-12 text-center p-0 m-0" #containerDiv [ngClass]="{
            'col-4': isCol4, 
            'col-12': !isCol4, 
            'max-w-25rem': isCol4
        }" [ngStyle]="selectedTemplate === 'inline' ? { 'min-width': '320px' } : {}" [style.justify-content]="'center'"
        [style.align-items]="'center'">
        <div class="select-button-wrapper mx-auto mb-4">
            <p-selectButton class="mt-5 ss-g-selectButton-50" [options]="landscapeOptions2" *ngIf="!isMobileDevice()"
                (onChange)="changeLandscape()" [(ngModel)]="valSelect1Video" optionLabel="name">
                <ng-template let-option pTemplate="item">
                    <div class="flex align-items-center justify-content-center w-full">
                        <i [ngClass]="option.icon" class="mr-2"></i>
                        <span class="font-bold">{{ option.name }}</span>
                    </div>
                </ng-template>
            </p-selectButton>
        </div>

        <div class="header-container mb-2 "
            style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
            <!-- Título com ícone de olho -->
            <div class="title-container" style="display: flex; align-items: center;">
                <i class="pi pi-eye mr-2" style="font-size: 1.5rem;"></i>
                <span style="font-weight: bold; font-size: 1.2rem;">Prévia - {{ capitalizeFirstLetter(selectedTemplate)
                    }}</span>
            </div>
            <!-- Botão alinhado à direita -->
            <div class="button-container">
                <button pButton pRipple type="button" icon="pi pi-pencil" (click)="onOpenSidebar()"
                    class="border-none button-tag"></button>
            </div>
        </div> 
        <app-widget-template [templateType]="selectedTemplate" [refreshTrigger]="refreshTrigger"
            (loadingComplete)="isLoadingSave = false"
            [popupPosition]="(selectedTemplate === 'popup' || selectedTemplate === 'carousel' || selectedTemplate === 'stories') ? (selectedPopupPosition || 'bottomleft') : 'bottomleft'"
            [highlight]="(selectedTemplate === 'popup' || selectedTemplate === 'carousel' || selectedTemplate === 'stories') ? highlight : undefined"
            [isAdmin]="isAdmin" [showSmartphones]="showSmartphones" [showDesktops]="showDesktops"
            [font_color]="(selectedTemplate === 'popup' || selectedTemplate === 'carousel') ? font_color : '#000000'"
            [height]="(selectedTemplate === 'iframe' || selectedTemplate === 'carousel' || selectedTemplate === 'stories' || selectedTemplate === 'popup' || selectedTemplate === 'inline') ? height : undefined"
            [aspectRatio]="(selectedTemplate === 'iframe' || selectedTemplate === 'inline') ? aspectRatio : undefined"
            [showProducts]="(selectedTemplate === 'inline' || selectedTemplate === 'stories'  || selectedTemplate === 'carousel') ? showProducts : false"
            [showDescription]="(selectedTemplate === 'inline' || selectedTemplate === 'carousel') ? showDescription : false"
            [selectedShape]="selectedTemplate === 'popup' ? selectedShape : undefined"
            [bg_color]="(selectedTemplate === 'popup' || selectedTemplate === 'carousel') ? bg_color : undefined"
            [widget_id]="widget_id" [store_id]="store_id" [template_uid]="template_uid" [widget_uid]="widget_uid"
            (openSidebarEvent)="openSidebar()" [containerDiv]="containerDivRef" [isCol4]="isCol4">
        </app-widget-template>
    </div>
</div>


<p-dialog header="Aguarde" [(visible)]="displayAguarde" [modal]="true" [closable]="true" [showHeader]="true"
    [dismissableMask]="true">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>


<p-dialog header="Header" [(visible)]="visible" position="right" [style]="{ width: '50vw' }">
    <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
    </p>
</p-dialog>



<!-- Modal que abre ao clicar no input -->
<p-dialog [(visible)]="displayCommandMenu" [modal]="true" [dismissableMask]="true"
    [contentStyle]="{ padding: '0', overflow: 'hidden' }" [style]="{ width: '40vw' }"
    class="custom-command-menu ss-l-commandMenu">

    <!-- Campo de busca no topo -->
    <div class="search-bar">
        <div class="p-inputgroup" style="margin: 0; padding: 0;">
            <span class="p-inputgroup-addon">
                <i class="pi pi-search"></i>
            </span>
            <input type="text" pInputText [(ngModel)]="searchQuery" placeholder="Buscar produtos..."
                (input)="filterProducts()" />
        </div>
    </div>

    <!-- Área de "Recentes" -->
    <div class="recent-section">
        <h4>Produtos</h4>
        <ul class="recent-list">
            <li *ngFor="let product of filteredProducts" class="recent-item cursor-pointer"
                (click)="addProductToChips(product)">
                <div class="product-container">
                    <img [src]="product.thumbnail" alt="Product Image" class="product-thumbnail">
                    <div class="product-details">
                        <h5>{{ product.title }}</h5>
                        <p>/products/{{ product.title | slugify }}</p>
                    </div>
                    <a [href]="product.product_url" target="_blank" class="product-link">
                        <i class="pi pi-external-link"></i>
                    </a>
                </div>
            </li>
        </ul>
    </div>

    <!-- Divisor com "OU" -->
    <div class="divider-with-text">OU</div>

    <!-- Área de inserção manual -->
    <div class="pt-0 main-section">
        <label class="w-full ss-g-globalClassLabel mb-2">Inserir página manualmente</label>
        <div class="mb-2"></div>
        <p-chips [(ngModel)]="valuesEqualTags" separator="," placeholder="Digite valores para 'Igual'"
            class="w-full s-s-l-chips mt-2"></p-chips>
        <div class="flex-container " *ngIf="showClearEqualTags">
            <a class="ss-g-remove-coupon mt-2" (click)="clearEqualTags()">
                <i class="pi pi-times"></i> Limpar
            </a>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple label="Fechar" (click)="closeCopyTemplate(1)" class="p-button-outlined "></button>
            <!-- <button pButton pRipple label="Salvar" [loading]="isLoadingSave" (click)="createWidget()"
                [disabled]="saveBtnDisabled" class="ml-2 mr-2"></button> -->
            <button *ngIf="createWidgetBtn" pButton pRipple label="Criar" [loading]="isLoadingSave"
                (click)="createWidget()" class="ml-2 mr-2"></button>
            <button *ngIf="!createWidgetBtn" pButton pRipple label="Salvar" [loading]="isLoadingSave"
                (click)="editWidget(1)" class="ml-2 mr-2"></button>
        </div>
    </ng-template>
</p-dialog>