import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, public layoutService: LayoutService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    //this.layoutService.checkTheme();
    //const accessToken = localStorage.getItem('access_token');
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      let tokenData = this.decodeJwtToken(accessToken);

      if (tokenData.exp && Date.now() >= tokenData.exp * 1000) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_ebanking');
        localStorage.removeItem('domain_store');
        localStorage.removeItem('SelectedStoreId');
        console.log('Access token é inválido');
        this.router.navigate(['/auth/login']);
        return false;
      } else {
        const currentTime = Date.now() / 1000; // Convertendo milissegundos para segundos
        const timeLeft = tokenData.exp - currentTime;
        return true;
      }

    } else {
      // Token não encontrado, redirecionar para a página de login
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
  decodeJwtToken(token: string): any {
    // Decodificar o token JWT para obter os dados
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map((c: string) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}
