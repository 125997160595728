<div class="card shadow-none" style="padding: 1rem;" *ngIf="isAdmin">
    <!-- Placeholder para o conteúdo principal -->
    <div class="skeleton-content" style="margin-top: 1rem;">
        <!-- Placeholder para imagem -->
        <p-skeleton shape="rectangle" width="30%" height="150px" [ngStyle]="{ 'margin-bottom': '1rem' }"></p-skeleton>

        <!-- Placeholder para duas colunas menores à direita -->
        <div style="display: flex; gap: 1rem;">
            <p-skeleton shape="rectangle" width="20%" height="50px"></p-skeleton>
            <p-skeleton shape="rectangle" width="50%" height="50px"></p-skeleton>
        </div>

        <!-- Placeholder para uma série de elementos de texto -->
        <div style="margin-top: 1rem;">
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="70%" height="20px"></p-skeleton>
            </div>
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="60%" height="20px"></p-skeleton>
            </div>
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="40%" height="20px"></p-skeleton>
            </div>
        </div>

        <!-- Placeholder para botões ou ações, como "ver mais" -->
        <div style="margin-top: 1rem; display: flex; gap: 0.5rem;">
            <p-skeleton shape="circle" size="40px"></p-skeleton>
            <p-skeleton shape="circle" size="40px"></p-skeleton>
            <p-skeleton shape="circle" size="40px"></p-skeleton>
        </div>

        <!-- Placeholder para o rodapé -->
        <div style="margin-top: 1.5rem;">
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="90%" height="20px" [ngStyle]="{ 'margin-bottom': '0.5rem' }"></p-skeleton>
            </div>
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="80%" height="20px" [ngStyle]="{ 'margin-bottom': '0.5rem' }"></p-skeleton>
            </div>
            <div style="margin-bottom: 0.5rem;">
                <p-skeleton width="40%" height="20px"></p-skeleton>
            </div>


        </div>
    </div>
</div>


<p-dialog
  #dialogContainer
  [(visible)]="visible"
  [position]="position"
  [draggable]="false"
  [style.pointer-events]="disableClicks ? 'none' : 'auto'"
  [ngClass]="[getDialogClass(), position]"
  (onShow)="onDialogShow()"
  (mouseenter)="onDialogMouseEnter()"
  (mouseleave)="onDialogMouseLeave()"

>
  <ng-template pTemplate="content">
    <div appDraggable [position]="position" [resetPosition]="resetPosition" (dragEnd)="onDragEnd()" class="ss-l-dialogBody" [style.pointer-events]="disableClicks ? 'none' : 'auto'">
      <div
        #highlightElement
        class="ss-l-highlight pr-3 pl-3 pb-1 pt-1"
        *ngIf="highlight && highlight.length > 0 && showHighlight"
        [ngStyle]="{ 'background-color': backgroundColor || '#ffffff', 'color': font_color || '#000000' }"
      >
        <b>{{ highlight }}</b>
      </div>
      <div class="dialog-content-wrapper">
        <div class="video-container" (click)="handleClick()" (touchend)="handleClick()">
          <p-skeleton *ngIf="loading" height="200px" width="100%"></p-skeleton>
          <video *ngIf="selectedVideoUrl" #videoPlayer [muted]="true" [autoplay]="true" [loop]="true" (loadedmetadata)="onVideoLoaded()">
            <source [src]="selectedVideoUrl.src" type="video/mp4" />
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>






<!-- Modal Carousel --> 
<app-video-modal *ngIf="!isWebComponent" [isAdmin]="isAdmin" #modalContainer [selectedVideo]="selectedVideoUrls[0]" [videoUrls]="videoUrls" [height]="height" [widget_id]="widget_id" [arraySlicksFull]="arraySlicksFull" [totalAssets]="totalAssets"
    [show]="isModalOpen" (close)="closeModal()">
</app-video-modal>