import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-shopping-cart',
  standalone: true,
  imports: [CommonModule, ButtonModule, InputNumberModule, FormsModule],
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnDestroy {
  cartItems$: Observable<any[]>;
  private cartItemsSubscription!: Subscription; // 🔥 Armazena a referência da Subscription
  subtotal = 0;
  total = 0;

  constructor(private shoppingCartService: ShoppingCartService) {
    this.cartItems$ = this.shoppingCartService.cartItems$;
    this.cartItemsSubscription = this.cartItems$.subscribe(cartItems => {
      this.calculateTotals(cartItems);
    });
  }

  calculateTotals(cartItems: any[]) {
    this.subtotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    this.total = this.subtotal;
  }

  updateQuantity(item: any, event: any) {
    const newQuantity = event.value || 1;
    this.shoppingCartService.updateCartItemQuantity(item.id, newQuantity);
  }

  removeItem(item: any) {
    this.shoppingCartService.removeFromCart(item.id);
  }

  // ✅ Encerra a Subscription ao destruir o componente
  ngOnDestroy(): void {
    if (this.cartItemsSubscription) {
      this.cartItemsSubscription.unsubscribe();
    }
  }
}
