import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, Output, ViewEncapsulation, EventEmitter, AfterViewInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ViewportService } from 'src/app/services/viewport.service';
import { DialogModule } from 'primeng/dialog';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { SkeletonModule } from 'primeng/skeleton';
import { DraggableDirective } from 'src/app/shared/directives/draggable.directive';
import { ViewportRuler } from '@angular/cdk/overlay';
import { Item } from 'src/app/models/liveshop';

export interface VideoItem {
  uid: string;
  id: number;
  src: string;   // URL do vídeo
  poster: string; // Poster do vídeo
  thumbnail?: string;
}
@Component({
  selector: 'app-popup',
  standalone: true,
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  imports: [CommonModule, ConfirmDialogModule, DialogModule, VideoModalComponent, SkeletonModule, DraggableDirective],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PopupComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() arraySlicksFull: Item[] = [];
  @Input() position: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' = 'center';
  @Input() highlight?: string;
  @Input() shape?: string;
  @Input() isAdmin: boolean = false;
  @Input() height?: number | undefined;
  @Input() backgroundColor?: string;
  @Input() font_color: string = "#000000";
  @Input() widget_id?: number | undefined;
  @Input() templateType: string | undefined;
  @Input() pageToVideos: number = 0;
  @Input() disableClicks: boolean = true;
  visible: boolean = true;
  selectedVideoUrl: VideoItem | undefined;
  @Input() videoUrls: VideoItem[] = [];
  @ViewChild('dialogContainer', { read: ElementRef }) dialogContainer!: ElementRef;
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  @Input() totalAssets: number = 0;
  private isDragging = false;
  private offsetX = 0;
  private offsetY = 0;
  @ViewChild('highlightElement') highlightElement!: ElementRef;

  constructor(
    private viewportService: ViewportService,
    private viewportRuler: ViewportRuler,
    private renderer: Renderer2,
    private host: ElementRef
  ) {

  }
  ngAfterViewInit(): void {
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.muted = true;
    }
    this.viewportService.setTemplateType(this.templateType);
    this.startFadeOutTimer();
  }
  fadeOutTimer: any;
  startFadeOutTimer() {
    clearTimeout(this.fadeOutTimer);
    this.fadeOutTimer = setTimeout(() => {
      if (this.highlightElement?.nativeElement) {
        this.renderer.addClass(this.highlightElement.nativeElement, 'fadeout');
      }
    }, 5000);
  }

  onDialogMouseEnter() {
    if (this.highlight && this.highlight.length > 0 && this.highlightElement?.nativeElement) {
      this.renderer.removeClass(this.highlightElement.nativeElement, 'fadeout');
      this.renderer.addClass(this.highlightElement.nativeElement, 'fadein');
    }
  }

  onDialogMouseLeave() {
    if (this.highlight && this.highlight.length > 0 && this.highlightElement?.nativeElement) {
      this.renderer.removeClass(this.highlightElement.nativeElement, 'fadein');
    }
    this.startFadeOutTimer();
  }
  // Método chamado quando o diálogo é exibido
  onDialogShow(): void {
    this.startFadeOutTimer();
    // Tenta acessar o `.p-dialog-content` diretamente no documento
    const dialogContentElement = document.querySelector('.p-dialog-content');
    if (dialogContentElement) {
      // eslint-disable-next-line @angular-eslint/no-input-rename
      this.renderer.setStyle(dialogContentElement, 'background-color', 'transparent');
      //  	  2this.renderer.setStyle(dialogContentElement, 'transform', 'translate("100px", 0)');
    } else {
      console.warn('Elemento `.p-dialog-content` não encontrado');
    }
  }

  @Output() openSidebar = new EventEmitter<void>();

  isModalOpen = false;
  selectedVideoUrls: VideoItem[] = [];
  isWebComponent: boolean = false;
  ngOnInit(): void {

    this.isWebComponent = !!document.querySelector('video-modal-widget');
    /////////////////////////////////////////

    this.startFadeOutTimer();
    console.log('Position:', this.position);
  }

  private wasDragged = false;

  onDragEnd() {
    this.wasDragged = true; // Seta a flag de arrasto
  }

  handleClick() {
    console.log('disparou clique')
    if (this.wasDragged) {
      this.wasDragged = false; // Reseta a flag após o clique
      return; // Ignora o clique
    }
    this.openModal();
  }

  openModal() {
    console.log('clicou em abrir modal');
    if (this.videoUrls && this.videoUrls.length > 0) {
      this.selectedVideoUid = this.videoUrls[0].uid;
      console.log('Abrindo modal com videoUrls:', this.videoUrls);
      this.isModalOpen = true;
      this.visible = false;

      this.selectedVideoUrls = this.videoUrls;

      // 🔹 Obtendo o Web Component no DOM
      const videoModal = document.getElementById('videoModal') as any;

      if (videoModal) {
        console.log('✅ Encontrou video-modal-widget no DOM');

        // 🔹 Definir atributos no Web Component
        videoModal.setAttribute('is-admin', this.isAdmin.toString());
        videoModal.setAttribute('widget-id', this.widget_id?.toString() || '');
        videoModal.setAttribute('height', this.height?.toString() || '');
        videoModal.setAttribute('total-assets', this.totalAssets.toString());
        videoModal.setAttribute('show', 'true');

        // 🔹 Passar propriedades diretamente para o Web Component
        videoModal.videoUrls = [...this.videoUrls];
        videoModal.selectedVideo = this.videoUrls[0];
        videoModal.arraySlicksFull = this.arraySlicksFull;
        videoModal.pageToVideos = this.pageToVideos;
        // videoModal.template_id = this.template_id;
        // videoModal.store_id = this.store_id;
        // videoModal.aspectRatio = this.aspectRatio;

        // 🔹 Chamar o método `showModal()` do Web Component, se existir
        if (typeof videoModal.showModal === 'function') {
          console.log('🎥 Chamando showModal() do video-modal-widget');
          videoModal.showModal();
        } else {
          console.error('❌ O método showModal() não está disponível no video-modal-widget.');
        }
      }
    }


  }
  resetPosition: boolean = false;
  showHighlight: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {
    this.visible = true;
    if (changes['videoUrls'] && this.videoUrls.length > 0) {
      this.selectedVideoUrl = this.videoUrls[0]; // Pegando apenas o primeiro vídeo
      console.log('Video URL selecionada:', this.selectedVideoUrl);
      console.log('PopupComponent - videoUrls atualizado:', changes['videoUrls'].currentValue);
      // Forçar recarregamento do vídeo
      if (this.videoPlayer) {
        const videoElement = this.videoPlayer.nativeElement;
        videoElement.load(); // Recarrega o vídeo quando a URL é alterada
      }

    }
    if (changes['position']) {
      this.resetPosition = true;
      console.log('Position foi atualizada:', changes['position'].currentValue);
      setTimeout(() => {
        this.resetPosition = false;
      }, 0);
    }
    if (changes['height']) {
      console.log('height foi atualizada:', changes['height'].currentValue);
    }
    if (changes['font_color']) {
      console.log('font_color foi atualizada:', changes['font_color'].currentValue);
    }
    if (changes['highlight']) {
      console.log('Highlight foi atualizado:', changes['highlight'].currentValue);
    }
    if (changes['shape']) {
      console.log('Shape foi atualizado:', changes['shape'].currentValue);
    }
    if (changes['backgroundColor']) {
      console.log('Cor de fundo foi atualizada:', changes['backgroundColor'].currentValue);
    }
  }

  getDialogClass(): string {
    switch (this.shape) {
      case 'portrait':
        return 'dialog-portrait';
      case 'landscape':
        return 'dialog-landscape';
      case 'circle':
        return 'dialog-circle';
      default:
        return 'dialog-circle';
    }
  }
  getDialogStyle() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : 'inherit'
    };
  }
  loading: boolean = true;
  onVideoLoaded(): void {
    this.loading = false; // Desativar o skeleton quando o vídeo for carregado
    this.showHighlight = true;
    // Garante que o vídeo está mutado quando os metadados carregam
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.muted = true;
    }
  }
  selectedVideoUid?: string;

  closeModal() {
    this.isModalOpen = false; // Reseta isModalOpen para permitir reabertura
    this.visible = true;
    //this.videoUrls = [];
  }
  onOpenSidebar(): void {
    this.openSidebar.emit();
  }
}
