import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  private cartKey = 'ss_shopping_cart';
  private cartItems: any[] = [];

  private cartItemsSubject = new BehaviorSubject<any[]>([]);
  cartItems$ = this.cartItemsSubject.asObservable();

  private cartCountSubject = new BehaviorSubject<number>(0);
  cartCount$ = this.cartCountSubject.asObservable();

  constructor() {
    this.loadCart();
  }

  private loadCart() {
    const storedCart = localStorage.getItem(this.cartKey);
    this.cartItems = storedCart ? JSON.parse(storedCart) : [];
    this.updateCartState();
  }

  private saveCart() {
    localStorage.setItem(this.cartKey, JSON.stringify(this.cartItems));
    this.updateCartState();
  }

  private updateCartState() {
    const newCartCount = this.cartItems.reduce((total, item) => total + item.quantity, 0);
  
    // 🔥 Somente emite se o valor mudou (evita loops desnecessários)
    if (newCartCount !== this.cartCountSubject.getValue()) {
      this.cartItemsSubject.next([...this.cartItems]); 
      this.cartCountSubject.next(newCartCount);
    }
  }

  addToCart(product: any, quantity: number = 1, mainImage: string) {
    const existingItem = this.cartItems.find(item => item.id === product.id);

    const productImage = mainImage || product.thumbnail || (product.images?.length ? product.images[0] : 'assets/images/default-product.png');

    if (existingItem) {
      existingItem.quantity += quantity;
    } else {
      this.cartItems.push({
        id: product.id,
        name: product.title || product.name,
        price: product.price,
        image: productImage, // 🔥 Agora garantindo que a imagem principal do produto seja salva
        stock: product.inventory_quantity || product.stock || 0,
        quantity: quantity
      });
    }
    this.saveCart();
  }

  getCartItems(): any[] {
    return [...this.cartItems]; // Retorna uma cópia para evitar mutações diretas
  }

  removeFromCart(productId: number) {
    this.cartItems = this.cartItems.filter(item => item.id !== productId);
    this.saveCart();
  }

  clearCart() {
    this.cartItems = [];
    this.saveCart();
  }

  updateCartItemQuantity(productId: number, newQuantity: number) {
    const item = this.cartItems.find(item => item.id === productId);
    if (item) {
      item.quantity = newQuantity;
      this.saveCart();
    }

    // 🔥 Garante que a contagem do carrinho seja atualizada corretamente
    this.cartCountSubject.next(this.cartItems.reduce((total, item) => total + item.quantity, 0));
  }

  // ✅ Método público para forçar atualização do estado do carrinho
  refreshCartState() {
    this.updateCartState();
  }
}
