import { RouteGuard } from './route-guard.guard';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './auth.guard';
import { LivesComponent } from './components/lives/lives.component';
import { VendasComponent } from './components/vendas/vendas.component';
import { ShortsComponent } from './components/shorts/shorts.component';
import { WidgetsComponent } from './components/widgets/widgets.component';
import { EditWidgetComponent } from './components/edit-widget/edit-widget.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { LeadsComponent } from './components/leads/leads.component';
import { ConfigurationsComponent } from './components/configurations/configurations.component';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { HelpComponent } from './components/help/help.component';
import { ContactComponent } from './components/contact/contact.component';
import { TmpComponent } from './components/tmp/tmp.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProductComponent } from './components/product/product.component';
import { ShowProductsComponent } from './components/show-products/show-products.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { CollectionComponent } from './components/collection/collection.component';
import { CategoryComponent } from './components/category/category.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { PromotionComponent } from './components/promotion/promotion.component';
import { StoreComponent } from './components/store/store.component';
import { PlayerComponent } from './components/player/player.component';
import { TagsComponent } from './components/tags/tags.component';
import { AppsComponent } from './components/apps/apps.component';
import { EmailResetComponent } from './components/email-reset/email-reset.component';
import { ExtratoComponent } from './components/extrato/extrato.component';
import { SlickDetailComponent } from './components/slick-detail/slick-detail.component';
//import { PVideoPlayerComponent } from './components/p-video-player/p-video-player.component';
import { PAccessComponent } from './components/p-access/p-access.component';
import { ShopifyAuthComponent } from './components/shopify-auth/shopify-auth.component';
import { routeGuardVideoGuard } from '../app/route-guard-video.guard';


const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled', // Para restaurar a posição de rolagem ao voltar para uma rota
    useHash: false // Essa é a parte importante, useHash: false remove o # da URL
};
const routes: Routes = [
    {
        path: '', component: AppLayoutComponent, canActivate: [AuthGuard],
        children: [
            { path: '', loadChildren: () => import('./components/dashboards/dashboards.module').then(m => m.DashboardsModule) },
            //{ path: 'uikit', data: { breadcrumb: 'UI Kit' }, loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
            { path: 'vendas', component: VendasComponent, data: { breadcrumb: 'Vendas' } },
            { path: 'lives', component: LivesComponent, data: { breadcrumb: 'Slicks' }, },
            { path: 'slick/:uuid', component: SlickDetailComponent, data: { breadcrumb: 'Gerenciar Slick' } },
            { path: 'widgets', component: WidgetsComponent, data: { breadcrumb: 'Widgets' } },
            { path: 'edit_widgets/:uid', component: EditWidgetComponent, data: { breadcrumb: 'Gerenciar Widget' } },
            { path: 'create_widget', component: EditWidgetComponent, data: { breadcrumb: 'Criar Widget' } },
            { path: 'inventario', component: InventarioComponent },
            { path: 'product', component: ProductComponent },
            { path: 'collection', component: CollectionComponent },
            { path: 'category', component: CategoryComponent },
            { path: 'promotion', component: PromotionComponent },
            { path: 'leads', component: LeadsComponent },
            
            { path: 'configurations', component: ConfigurationsComponent, data: { breadcrumb: 'Configurações' } },
            { path: 'store', component: StoreComponent },
            { path: 'player', component: PlayerComponent, data: { breadcrumb: 'Player' } },
            { path: 'tags', component: TagsComponent },
            { path: 'integrations', component: IntegrationsComponent, data: { breadcrumb: 'Integrações' } },
            { path: 'help', component: HelpComponent, data: { breadcrumb: 'Suporte' } },
            { path: 'contact', component: ContactComponent, data: { breadcrumb: 'Fale Conosco' } },
            { path: 'extrato', component: ExtratoComponent },
            { path: 'profile', component: ProfileComponent, data: { breadcrumb: 'Perfil' } },
            { path: 'apps', component: AppsComponent, data: { breadcrumb: 'Apps' } },
            
        ]
    },
    {
        path: 'auth/shopify', 
        component: ShopifyAuthComponent, 
        data: { breadcrumb: 'Shopify Authentication' }
    },
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule) },
    { path: 'register', component: RegisterComponent },
    { path: 'product-overview', component: ShowProductsComponent },
    { path: 'shopping-cart', component: ShoppingCartComponent },
    { path: 'email-reset', component: EmailResetComponent },
    { path: 'p-access', component: PAccessComponent, loadChildren: () => import('./components/dashboards/dashboards.module').then(m => m.DashboardsModule) },
    { path: ':nomeDaLoja', loadComponent: () => import('./components/p-player/p-player.component').then(m => m.PPlayerComponent) },
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
