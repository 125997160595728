import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { PedidosService } from 'src/app/services/pedidos.service';
import { MenuItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FocusService } from './../../services/focus.service';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { HttpClient } from '@angular/common/http';
import { OrderItem } from './../../models/pedido';
import { UploadResponse } from './../../models/UploadResponse';
import { PayableWithCard } from './../../models/billingDetails';
import { Transaction } from './../../models/billingDetails';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as QRCode from 'qrcode';
import {
  FormBuilder,
  FormGroup
} from '@angular/forms';
interface Pedido {
  id: number;
  store_id: number;
  total_amount: number;
  customer_name: string;
  created_at: Date;
}
export interface IBillingGroupSimulate {
  amount: number;
  fees_on_buyer: number;
  interest_free_installments?: number;
}
interface Filter {
  value: string | string[];
  matchMode: string;
}
interface Severities {
  [key: string]: string;
}
interface IDiscount {
  discount_percentage?: number;
  discount_days?: number;
}
interface Billing {
  billing_uniq_id: string;
  billing_link: string;
  billing_id: number;
  installments_plan: Installment[];
}
interface Installment {
  n: number;
  tid: string;
  expiration_date: string;
  status: number;
  payment_link: string;
  amount: string;
}
interface Filters {
  [key: string]: Filter;
}
interface ResponsePedidos {
  totalRecords: number;
  order: Pedido[];
}
interface Usuario {
  user_id: number;
  user_name: string;
  user_surname: string;
  wallet_id: number;
}
interface UsuarioLP {
  name: string;
  surname: string;
  birth_date: string;
  phone_number: string;
  email: string;
  cpf: string;
  ssn: string;
  company_name: string;
  cnpj: string;
}
interface InstPrice {
  id: number;
  name: string;
  key: string;
  cost: string;
  price: string;
  status: number;
}
interface Address {
  id: number;
  nickname: string;
  country: string;
  state: string;
  city: string;
  postal_code: string;
  address_district: string;
  address_street: string;
  address_number: string;
  address_complement: string;
}
interface Carrinho {
  shopping_cart_id: number;
  soma_items: string;
  valor_frete: string;
  total: string;
  cart_type: string;
  valor_desc_voucher_frete: string;
  valor_desc_voucher_cart: string;
  valor_desc_promo_cart: string;
}
interface DeliveryMethods {
  id: number;
  method: string;
  Valor: string;
  Prazo: string;
}
interface Produto {
  id: number;
  item_group_id: number;
  name: string;
  stock_qty: number;
  track_inventory: number;
  status: number;
  min_qty: number;
  inputQty: number;
  price: string;
  onsale_price: string;
  price_exchange: string;
  thumbnail: string;
  isDisabled?: boolean;
}
interface ItemProduto extends Produto {
  label: string;
  value: number;
}
interface ProdutoAgrupado {
  label: string;
  items?: ItemProduto[];
  thumbnail?: string;
}
interface DeliveryMethodOption {
  id: number;
  name: string;
  value: string;
}
interface PaymentMethod {
  id: number;
  name: string;
  value: string;
} //
interface SimulationResponseItem {
  credit_card?: CreditCardData[];
  slip?: SlipData[];
  pix?: PixData[];
}

interface PixData {
  n: number;
  charged_amount: string;
  received_amount: string;
}

interface SlipData {
  n: number;
  charged_amount: string;
  received_amount: string;
}

interface CreditCardData {
  n: number;
  charged_amount: string;
  received_amount: string;
}
@Component({
  selector: 'app-vendas',
  templateUrl: './vendas.component.html',
  styleUrls: ['./vendas.component.scss']
})
export class VendasComponent implements OnInit, AfterViewInit {
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  qrCodeUrl?: string;
  goToNextInput() {
    this.multiSelect?.nativeElement.focus();
  }
  private productAdded = new Subject<any>();
  private productRemoved = new Subject<any>();
  orders: any[] = [];
  totalRecords: number = 0;
  loading: boolean = true;
  loadingCupom: boolean = false;
  loadingFinish: boolean = false;
  //calcCartId 
  totalAprovadas = 0;
  totalAguardando = 0;
  totalExpiradas = 0;
  totalCanceladas = 0;

  countAprovadas = 0;
  countAguardando = 0;
  countExpiradas = 0;
  countCanceladas = 0;

  porcentagemAprovadas: number | null = null;
  porcentagemAguardando: number | null = null;
  porcentagemExpiradas: number | null = null;
  porcentagemCanceladas: number | null = null;
  manualValue: string | null = null;
  hasZipCode: boolean = false;
  hasShippingMethod: boolean = true;
  deliveryOptions?: any[];
  fullDeliveryMethods?: any[];
  paymentOptions?: any[];


  loadingDeliveryOptions = false;
  @ViewChild('multiSelect') multiSelect!: ElementRef;
  onSliderChange2(): void {

    this.simular();

  }
  //slider
  jurosOptions = [
    { label: 'Percentual', value: true },
  ];
  multaOptions = [
    { label: 'Percentual', value: true },
  ];


  valorJurosOptions = [
    { label: 'Não aplicar juros', value: 0 },
    { label: '0.25', value: 0.25 },
    { label: '0.50', value: 0.50 },
    { label: '0.75', value: 0.75 },
    { label: '1.00 (Máx permitido por lei)', value: 1.00 },
    // Outras opções...
  ];
  interestHandler = [
    { label: 'Não aplicar juros', value: 0 },
    { label: '0.25', value: 0.25 },
    // Outras opções...
  ];
  late_fee_daily: number | null = null;
  late_fee: number | null = null;
  fineHandler = [
    { label: 'Não aplicar juros', value: 0 },
    { label: '0.25', value: 0.25 },
  ];
  fineHandler2: number = 1;
  multaOptionsValor = [
    { label: 'Não aplicar multa', value: 0 },
    { label: '0.25', value: 0.25 },
    { label: '0.50', value: 0.50 },
    { label: '0.75', value: 0.75 },
    { label: '1.00', value: 1.00 },
    { label: '1.25', value: 1.25 },
    { label: '1.50', value: 1.50 },
    { label: '1.75', value: 1.75 },
    { label: '2.00 (Máx permitido por lei)', value: 2.00 },
  ];



  descontosOptions = [
    { label: 'Não aplicar desconto', value: 1 },
    { label: 'No dia do vencimento', value: 2 },
    { label: '5 dias antes do vencimento', value: 5 },
    { label: '10 dias antes do vencimento', value: 10 },
    { label: '15 dias antes do vencimento', value: 15 },
    { label: '20 dias antes do vencimento', value: 20 },
    { label: '25 dias antes do vencimento', value: 25 },
    { label: '30 dias antes do vencimento', value: 30 },
  ];


  descontosOptionsValor = [
    { label: 'Não aplicar desconto', value: 0 },
    { label: '5% de desconto', value: 5 },
    { label: '10% de desconto', value: 10 },
    { label: '15% de desconto', value: 15 },
    { label: '20% de desconto', value: 20 },
    { label: '25% de desconto', value: 25 },
    { label: '30% de desconto', value: 30 },
    { label: '35% de desconto', value: 35 },
    { label: '40% de desconto', value: 40 },
    { label: '45% de desconto', value: 45 },
    { label: '50% de desconto', value: 50 },
    { label: '55% de desconto', value: 55 },
    { label: '60% de desconto', value: 60 },
    { label: '65% de desconto', value: 65 },
    { label: '70% de desconto', value: 70 },
    { label: '75% de desconto', value: 75 },
    { label: '80% de desconto', value: 80 },
    { label: '85% de desconto', value: 85 },
    { label: '90% de desconto', value: 90 },
    { label: '95% de desconto', value: 95 },
  ];
  discount_percentage: any;
  previousStep() {
    this.activeIndex = 0;
  }
  discount: IDiscount = {
    discount_days: 0,
    discount_percentage: 0
  }
  getDeliveryMethodValueById(deliveryMethodId: number | undefined): number | null {
    if (deliveryMethodId === undefined) {
      return null;
    }

    const deliveryMethod = this.fullDeliveryMethods?.find(method => method.id === deliveryMethodId);

    //// chamar novamente popular método de entrega no carrinho 
    console.log('antes ddddd')
    if (deliveryMethod) {
      console.log('dentro ddddd')
      this.issueForm = this.fb.group({
        "shipping_method": this.shipping_method_id,
        "shipping_method_name": this.shipping_method_name,
        "value": deliveryMethod.Valor
      });
      this.pedidosService.addShippingMethodToCart(this.issueForm.value, this.shopping_cart_id).subscribe({
        next: (res) => {
          console.log('add método de entrega')

          this.displayDialog = false;
          this.hasShippingMethod = true;
          /////////////
          this.calcCartId();
          /////////////



        },
        error: (err) => {
          // Exiba uma mensagem de erro

          this.displayDialog = false;
        }
      });
    }



    return deliveryMethod ? deliveryMethod.Valor : null;
  }
  order_id: number = 0;
  billing_uniq_id: string = '';
  tid: string = '';
  billing_id: number = 0;
  finishProcess() {
    if (this.selectedPaymentMethodsCob && this.selectedPaymentMethodsCob.length > 0) {
      //console.log("Formas de pagamento selecionadas:", this.selectedPaymentMethodsCob);
    } else {
      this.erro5 = true;
      this.hasError5 = true;
    }
    // erro6
    if (!this.dataSelecionada) {
      this.erro6 = true;
      this.hasError6 = true;
    }

    if (this.selecaoParcelamento) {
      //console.log("Quantidade de cobranças selecionada:", this.selecaoParcelamento);
    } else {
      this.erro7 = true;
      this.hasError7 = true;
    }

    if (this.erro6 || this.erro5 || this.erro7) {
      return;
    }

    this.loadingFinish = true;
    // if (!this.selecaoParcelamento) {
    //   // Mostrar mensagem de erro para o usuário
    //   this.erro5 = true;
    //   this.hasError5 = true;
    //   return; // Impede a continuação do método
    // }



    let store_id = localStorage.getItem("SelectedStoreId") || '';
    // criar pedido rascunho createOrder
    this.issueForm = this.fb.group({
      "store_id": store_id,
      "shopping_cart_id": this.shopping_cart_id,
      "tid": "",
      "subtotal": this.subtotal,
      "voucher_discount": this.valorCupom,
      "items_cost": null,
      "taxes_cost": null,
      "shipping_price": this.valorFrete,
      "total_amount": this.valorTotal,
      "invoice": null,
      "sale_channel": 1,
      "payment_status": 1,
      "fullfilment_status": 1,
      "shipping_tracker_id": null,
      "shipping_tracker_link": null,
      "note": null,
      "currency_pairs_quotations": null,
      "status": 0,
      "comment_log": ""
    });
    this.pedidosService.createOrder(this.issueForm.value).subscribe({
      next: (response) => {
        //console.log(response);
        //console.log('criou pedido');
        // atualizar status pedido para salvo (1)
        if (response && response.order) {
          console.log('Order Id:', response.order.id);
          this.order_id = response.order.id;
          if (this.order_id !== 0) {
            //////////////////////////////////
            this.pedidosService.detailUser(this.user_id).subscribe({
              next: (response) => {
                //console.log('detalhou user');
                //console.log(response);
                if (!this.selectedClientLP) {
                  this.selectedClientLP = {} as UsuarioLP;
                }
                this.selectedClientLP.name = response.name;
                this.selectedClientLP.surname = response.surname;
                this.selectedClientLP.birth_date = response.birth_date;
                this.selectedClientLP.phone_number = response.phone_number;
                this.selectedClientLP.email = response.email;
                this.selectedClientLP.cpf = response.ssn;
                this.selectedClientLP.company_name = "";
                this.selectedClientLP.cnpj = "";


                //console.log('método selecionados');
                //console.log(this.selectedPaymentMethodsCob);


                ///////////////////////////////////////////////

                //console.log(this.issueForm.value);
                /////////////////////////////////////////////
                /////////////////////////////////
                this.issueForm = this.fb.group({
                  "invoice": null,
                  "payment_status": 1,
                  "tid": '',
                  "billing_id": '',
                  "status": 1,
                  "fullfilment_status": 1,
                  "shipping_tracker_id": null,
                  "shipping_tracker_link": null,
                  "note": ""
                });
                this.pedidosService.updateOrder(this.issueForm.value, this.order_id).subscribe({
                  next: (response) => {
                    //console.log(response);
                    //console.log('atualizou pedido');
                    // atualizar status pedido para salvo (1)
                    if (response && response.order) {
                      this.issueForm = this.fb.group({
                        "transaction_category": 2,
                        "store_id": localStorage.getItem("SelectedStoreId"),
                        "payable_with": {
                          payable_with_card: this.selectedPaymentMethodsCob.some(method => method.value === 'cartao') ? 1 : 0,
                          payable_with_slip: this.selectedPaymentMethodsCob.some(method => method.value === 'boleto') ? 1 : 0,
                          payable_with_tef: 0,
                          payable_with_pix: this.selectedPaymentMethodsCob.some(method => method.value === 'pix') ? 1 : 0
                        },
                        "user": this.selectedClientLP,
                        "address": this.selectedAddress,
                        "fine": {
                          late_fee: this.multasJurosDescontos ? (this.late_fee == 0 ? null : this.late_fee) : null,
                          late_fee_daily: this.multasJurosDescontos ? (this.late_fee_daily == 0 ? null : this.late_fee_daily) : null,
                          late_fee_days: this.multasJurosDescontos ? 1 : null,
                        },
                        "discount": {
                          discount_days: this.multasJurosDescontos ? (this.discount.discount_days == 0 ? null : this.discount.discount_days) : null,
                          discount_percentage: this.multasJurosDescontos ? (this.discount.discount_percentage == 0 ? null : this.discount.discount_percentage) : null,
                        },
                        "fees_on_buyer": this.cobrarTaxas ? 1 : 0,
                        "extra_amount": 0,
                        "amount": this.valorTotal,
                        "billing_installments": this.selecaoParcelamento,
                        "interest_free_installments": this.valSlider,
                        "description": "",
                        "refer_code": this.order_id,
                        "expiration_date": this.obterDataVencimento(),
                        "payment_limit": this.obterDataFinal(),
                        "switch_status": 1
                      });
                      //console.log('TID:', response.order.id);
                      ///// createLP
                      this.pedidosService.createLP(this.issueForm.value).subscribe({
                        next: (response) => {
                          console.log('criou billing');
                          //.log(response);
                          // update order
                          if (response) {
                            this.billing_uniq_id = response.billing_uniq_id;
                            this.billing_id = response.billing_id;
                            ///// detailLP
                            this.pedidosService.detailLP(this.billing_uniq_id).subscribe({
                              next: (response: Billing) => {
                                console.log(response);
                                console.log('detalhou billing');
                                // atualizar status pedido para salvo (1)
                                if (response && response.installments_plan && response.installments_plan.length > 0) {
                                  const firstTid = response.installments_plan[0].tid;
                                  const billing_link = response.billing_link;
                                  console.log('Primeira TID:', firstTid);
                                  this.tid = firstTid;
                                  /////////////////////////////////
                                  this.issueForm = this.fb.group({
                                    "invoice": null,
                                    "payment_status": 1,
                                    "tid": this.tid,
                                    "billing_id": this.billing_id,
                                    "fullfilment_status": 1,
                                    "status": 1,
                                    "shipping_tracker_id": null,
                                    "shipping_tracker_link": null,
                                    "note": ""
                                  });
                                  this.pedidosService.updateOrder(this.issueForm.value, this.order_id).subscribe({
                                    next: (response) => {
                                      console.log(response);
                                      console.log('atualizou pedido');
                                      // atualizar status pedido para salvo (1)
                                      if (response && response.order) {
                                        //console.log('TID:', response.order.id);

                                      }
                                      // inserir na tabela loadOrders
                                      this.addOrder(response.order);
                                      this.gerarQRCode(billing_link)
                                      this.loadingFinish = false;
                                      //////////////////////////////////////////

                                      //////////////////////////////////////////
                                      this.activeIndex = 2;

                                    },
                                    error: (error) => {
                                      this.loadingFinish = false;
                                      let message = '';
                                      if (error.status === 400 || error.status === 403 || error.status === 401) {
                                        message = error.error.message;
                                      } else if (error.status === 500) {
                                        message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
                                      }

                                      if (message) {
                                        this.showErrorDialog(message);
                                      }
                                    }
                                  });
                                  /////////////////////////////////


                                }
                              },
                              error: (error) => {
                                this.loadingFinish = false;
                                let message = '';
                                if (error.status === 400 || error.status === 403 || error.status === 401) {
                                  message = error.error.message;
                                } else if (error.status === 500) {
                                  message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
                                }

                                if (message) {
                                  this.showErrorDialog(message);
                                }
                              }
                            });
                            //////////////////////////////////

                            //////////////////////////////////
                          }
                        },
                        error: (error) => {
                          this.loadingFinish = false;
                          let message = '';
                          if (error.status === 400 || error.status === 403 || error.status === 401) {
                            message = error.error.message;
                          } else if (error.status === 500) {
                            message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
                          }

                          if (message) {
                            this.showErrorDialog(message);
                          }
                        }
                      });
                      ///// createLP
                    }
                  },
                  error: (error) => {
                    this.loadingFinish = false;
                    let message = '';
                    if (error.status === 400 || error.status === 403 || error.status === 401) {
                      message = error.error.message;
                    } else if (error.status === 500) {
                      message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
                    }

                    if (message) {
                      this.showErrorDialog(message);
                    }
                  }
                });
                /////////////////////////////////
                //



              },
              error: (error) => {
                this.loadingFinish = false;
                let message = '';
                if (error.status === 400 || error.status === 403 || error.status === 401) {
                  message = error.error.message;
                } else if (error.status === 500) {
                  message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
                }

                if (message) {
                  this.showErrorDialog(message);
                }
              }
            });
            /////////////////////////////////
          }
        }
      },
      error: (error) => {
        this.loadingFinish = false;
        if (error.error && error.error.products) {
          this.activeIndex = 0;
          console.log('Erro recebido:', error.error);

          // Extrair os IDs dos produtos fora de estoque
          const outOfStockProductIds = new Set(error.error.products.map((p: Produto) => p.id));
          console.log('IDs dos produtos fora de estoque:', Array.from(outOfStockProductIds));

          // Verificar e filtrar this.itemsProdutos
          if (this.itemsProdutos) {
            console.log('Produtos antes da remoção:', this.itemsProdutos);

            this.itemsProdutos.forEach((group: ProdutoAgrupado) => {
              if (group.items) {
                group.items.forEach((item: ItemProduto) => {
                  if (outOfStockProductIds.has(item.id)) {
                    item.isDisabled = true;
                  }
                });
              }
            });

            // Filtrar grupos vazios, se necessário
            //this.itemsProdutos = this.itemsProdutos.filter((group: ProdutoAgrupado) => group.items && group.items.length > 0);

            console.log('Produtos após a remoção:', this.itemsProdutos);
          }

          //Verificar e filtrar this.selectedItems
          if (this.selectedItems) {
            console.log('Itens selecionados antes da remoção:', this.selectedItems);

            // Filtrando com base na propriedade 'id' de cada objeto em selectedItems
            this.selectedItems = this.selectedItems.filter(item => !outOfStockProductIds.has(item.id));

            console.log('Itens selecionados após a remoção:', this.selectedItems);
          }
          // selectedItems

          //////////////////////
          this.getDeliveryMethod();
          this.erro4 = true;

          // Exibir o diálogo
          this.showDialog(error.error.message, error.error.products.map((p: Produto) => p.name));
        }
      }
    });


    console.log('data vencimento ' + this.obterDataVencimento())
    console.log('data limite ' + this.obterDataFinal())

  }
  showErrorDialog(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Erro',
      icon: 'pi pi-times',
      accept: () => {
        // Lógica de aceitação, se necessário
      }
    });
  }
  dialogVisible: boolean = false;
  dialogMessage: string = '';
  dialogProductList: string[] = [];
  // showDialog(message: string, products: string[]) {
  //   this.dialogMessage = message;
  //   this.dialogProductList = products;
  //   this.dialogVisible = true;
  // }

  showDialog(message: string, products: string[]) {
    this.dialogMessage = message;

    this.dialogProductList = products;
    this.confirmationService.confirm({
      message: `<ul>${this.dialogProductList.map(product => `<li>${product}</li>`).join('')}</ul>`,
      header: this.dialogMessage,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // Lógica para aceitar no caso complexo
      },
      rejectVisible: false,
    });
  }

  formatarData(data: Date): string {
    const ano = data.getFullYear();
    const mes = data.getMonth() + 1; // getMonth() retorna mês de 0-11
    const dia = data.getDate();

    return `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
  }
  adicionarDias(data: Date, dias: number): Date {
    const novaData = new Date(data);
    novaData.setDate(novaData.getDate() + dias);
    return novaData;
  }
  obterDataFinal(): string {
    if (!this.dataSelecionada) {
      return '';
    }
    let dataFinal = this.dataSelecionada;

    if (this.pagarVencimento && this.payment_limit) {
      dataFinal = this.adicionarDias(this.dataSelecionada, this.payment_limit);
    }

    return this.formatarData(dataFinal);
  }
  obterDataVencimento(): string {
    if (!this.dataSelecionada) {
      return '';
    }
    let dataFinal = this.dataSelecionada;

    return this.formatarData(dataFinal);
  }
  visible: boolean = true;
  hasSelectedCreditCard: boolean = false;
  hasSelectedBoletoPix: boolean = false;
  onDateChange(event: Date) {
    if (this.dataSelecionada) {
      this.erro6 = false;
      this.hasError6 = false;
    }


    const today = new Date();
    today.setHours(0, 0, 0, 0); // Zerar a hora para comparar apenas as datas
    const diffInDays = Math.ceil((event.getTime() - today.getTime()) / (1000 * 3600 * 24));

    this.descontosOptions = [
      { label: 'Não aplicar desconto', value: 1 },
    ];

    if (event > today) {
      this.descontosOptions.push({ label: 'No dia do vencimento', value: 2 });
    }

    if (diffInDays > 5) {
      this.descontosOptions.push({ label: '5 dias antes do vencimento', value: 5 });
    }
    if (diffInDays > 10) {
      this.descontosOptions.push({ label: '10 dias antes do vencimento', value: 10 });
    }
    if (diffInDays > 15) {
      this.descontosOptions.push({ label: '15 dias antes do vencimento', value: 15 });
    }
    if (diffInDays > 20) {
      this.descontosOptions.push({ label: '20 dias antes do vencimento', value: 20 });
    }
    if (diffInDays > 25) {
      this.descontosOptions.push({ label: '25 dias antes do vencimento', value: 25 });
    }
    if (diffInDays > 30) {
      this.descontosOptions.push({ label: '30 dias antes do vencimento', value: 30 });
    }
  }
  isSecondDropdownDisabled: boolean = true;
  onFirstDropdownChange() {
    // Se a opção selecionada for "Não aplicar desconto" (value = 1), desative o segundo dropdown
    this.isSecondDropdownDisabled = this.fineHandler2 === 1;
  }
  quantidadeCobs() {
    this.simular();
    if (this.selecaoParcelamento) {
      this.hasError7 = false;
      this.erro7 = false;
      console.log(this.selecaoParcelamento)


    }
  }
  addOrder(newOrder: any) {
    // Adiciona a nova order ao início do array de orders
    this.orders.unshift(newOrder);

    // Incrementa o total de registros, uma vez que adicionamos uma nova order
    this.totalRecords++;

    // Aqui você pode optar por recarregar os dados da tabela para garantir consistência
    // Isso é útil se a ordenação ou filtros podem afetar a posição onde a nova order deve aparecer
    // Você pode chamar o método loadOrders novamente ou ajustar a lógica conforme necessário
    //this.loadOrders();
  }
  display: boolean = false;
  updateDeliveryOptions() {
    // Atualiza deliveryOptions com as novas opções

    // Verifica se o método de entrega selecionado ainda é válido
    const correspondingOption = this.deliveryOptions?.find(option =>
      option.value.id === this.selectedDeliveryMethod?.id
    );

    console.log('this.selectedDeliveryMethod')
    console.log(this.selectedDeliveryMethod)
    console.log('this.deliveryOptions')
    console.log('correspondingOption')
    console.log(correspondingOption)

    if (correspondingOption) {
      // Atualiza selectedDeliveryMethod para o objeto correspondente em deliveryOptions
      this.selectedDeliveryMethod = correspondingOption.value;
    } else {
      // Caso contrário, reseta selectedDeliveryMethod
      this.selectedDeliveryMethod = null;
    }
  }
  //// simulador
  parcelarJuros: boolean = false;
  openSimulador() {

    this.display = true;
  }
  simulationResponse: SimulationResponseItem[] = [];
  valorReceiveUntilCard: string = '';
  valorReceiveUntilPix: string = '';
  valorReceiveUntilBoleto: string = '';

  simular() {
    this.issueForm = this.fb.group({
      "amount": this.valorTotal,
      "fees_on_buyer": this.cobrarTaxas ? 1 : 0,
      "interest_free_installments": this.parcelarJuros ? this.valSlider2 : 0,
    });
    this.pedidosService.simularVenda(this.issueForm.value).subscribe({
      next: (res: SimulationResponseItem[]) => {
        this.simulationResponse = res;

        this.currentTableDataPix = this.simulationResponse[2]?.pix || [];
        this.currentTableDataCard = this.simulationResponse[0]?.credit_card || [];
        this.currentTableDataSlip = this.simulationResponse[1]?.slip || [];
        //this.currentTableData = this.simulationResponse[2]?.pix || [];


        this.valorReceiveUntilCard = this.simulationResponse[0]?.credit_card?.[0]?.received_amount ?? '';
        this.valorReceiveUntilPix = this.simulationResponse[2]?.pix?.[this.selecaoParcelamento ? this.selecaoParcelamento - 1 : 0]?.received_amount ?? '';
        this.valorReceiveUntilBoleto = this.simulationResponse[1]?.slip?.[this.selecaoParcelamento ? this.selecaoParcelamento - 1 : 0]?.received_amount ?? '';

        this.updateTableDataBasedOnActiveTab();
        if (this.selecaoParcelamento == undefined) {
          this.selecaoParcelamento = 1;
        }
        const formatadorReais = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        });

        // card
        let valorParcelaFormatado: string;
        let valorNumerico = parseFloat(this.valorReceiveUntilCard);

        if (!isNaN(valorNumerico)) {
          const formatadorReais = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });

          valorParcelaFormatado = formatadorReais.format(valorNumerico);
        } else {
          const formatadorReais = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });

          valorParcelaFormatado = formatadorReais.format(0);
        }
        // card
        //pix
        let valorParcelaFormatadoPix: string;
        let valorNumericoPix = parseFloat(this.valorReceiveUntilPix);

        if (!isNaN(valorNumericoPix)) {
          const formatadorReais = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });

          valorParcelaFormatadoPix = formatadorReais.format(valorNumericoPix);
        } else {
          const formatadorReais = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });

          valorParcelaFormatadoPix = formatadorReais.format(0);
        }
        //pix
        //slip
        let valorParcelaFormatadoSlip: string;
        let valorNumericoSlip = parseFloat(this.valorReceiveUntilBoleto);

        if (!isNaN(valorNumericoSlip)) {
          const formatadorReais = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });

          valorParcelaFormatadoSlip = formatadorReais.format(valorNumericoSlip);
        } else {
          const formatadorReais = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });

          valorParcelaFormatadoSlip = formatadorReais.format(0);
        }
        //slip
        this.paymentOptionsCob = [
          {
            value: 'cartao',
            label: 'Cartão de crédito ',
            icon: 'pi pi-credit-card',
            additionalText: ' receba até ' + valorParcelaFormatado,
            duration: '7 dias úteis'
          },
          {
            value: 'pix',
            label: 'Pix ',
            icon: 'pi pi-bolt',
            additionalText: ' receba até ' + valorParcelaFormatadoPix,
            duration: '7 dias úteis'
          },
          {
            value: 'boleto',
            label: 'Boleto ',
            icon: 'pi pi-file',
            additionalText: ' receba até ' + valorParcelaFormatadoSlip,
            duration: '7 dias úteis'
          }
        ];

        this.selectedPaymentMethodsCob = [];
      },
      error: (err) => {
        // Tratamento de erro
      }
    });
  }
  updateTableDataBasedOnActiveTab() {

    console.log("uuu");
    console.log(this.activeItemSimulador?.label);
    // Define um valor padrão para activeTabLabel caso esteja undefined
    const activeTabLabel = this.activeItemSimulador?.label ?? 'Pix';
    console.log('activeTabLabel');
    console.log(activeTabLabel);
    // Agora activeTabLabel é garantidamente uma string
    this.onTabChange(activeTabLabel);
  }

  itemsSimulador?: MenuItem[];
  activeItemSimulador?: MenuItem;

  currentTableData: any[] = []; // Armazena os dados atuais da tabela
  currentTableDataCard: any[] = [];
  currentTableDataPix: any[] = [];
  currentTableDataSlip: any[] = [];

  onTabChange(tabName: string) {
    // Atualiza os dados da tabela com base no nome da aba
    if (tabName === 'Pix') {
      this.currentTableData = this.currentTableDataPix || [];
    } else if (tabName === 'Boleto') {
      this.currentTableData = this.currentTableDataSlip || [];
    } else if (tabName === 'Cartão') {
      this.currentTableData = this.currentTableDataCard || [];
    }

    // Atualiza o activeItemSimulador para refletir a aba ativa
    this.activeItemSimulador = this.itemsSimulador?.find(item => item.label === tabName);
  }
  url_share?: string = "www.google.com";
  //// simulador
  gerarQRCode(texto: string) {
    this.url_share = texto;
    QRCode.toDataURL(texto)
      .then(url => {
        this.qrCodeUrl = url;
      })
      .catch(err => {
        console.error('Error generating QR Code', err);
      });
  }

  copyAddress() {
    if (this.addressSelected) {
      navigator.clipboard.writeText(this.addressSelected).then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Endereço copiado',
          detail: 'Endereço copiado com sucesso!'
        });
      }).catch(err => {
        console.error('Erro ao Endereço:', err);
      });
    }
  }

  copiarLink() {
    if (this.url_share) {
      navigator.clipboard.writeText(this.url_share).then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Link copiado',
          detail: 'Link copiado com sucesso!'
        });
        console.log('Link copiado com sucesso!');
      }).catch(err => {
        console.error('Erro ao copiar o link:', err);
      });
    } else {
      console.error('Erro: O link está vazio ou indefinido.');
    }
  }
  compartilharLink() {
    if (navigator.share) {
      navigator.share({
        title: 'Compartilhar Link',
        url: this.url_share
      }).then(() => {
        console.log('Link compartilhado com sucesso!');
      }).catch(err => {
        console.error('Erro ao compartilhar:', err);
      });
    } else {
      console.error('A Web Share API não é suportada neste navegador.');
    }
  }
  getUserAddress(wallet_id: number) {
    this.pedidosService.getUserAddress(wallet_id).subscribe((data: Address[]) => {

      this.originalResultsAddress = data.map((address: Address) => ({
        id: address.id,
        nickname: address.nickname,
        country: address.country,
        state: address.state,
        city: address.city,
        postal_code: address.postal_code,
        address_district: address.address_district,
        address_street: address.address_street,
        address_complement: address.address_complement,
        address_number: address.address_number
      }));
      this.filteredResultsAddress = [...this.originalResultsAddress];
    });
  }
  getUserAddressEdit(wallet_id: number) {
    this.pedidosService.getUserAddress(wallet_id).subscribe((data: Address[]) => {

      this.originalResultsAddressEdit = data.map((address: Address) => ({
        id: address.id,
        nickname: address.nickname,
        country: address.country,
        state: address.state,
        city: address.city,
        postal_code: address.postal_code,
        address_district: address.address_district,
        address_street: address.address_street,
        address_complement: address.address_complement,
        address_number: address.address_number
      }));
      this.filteredResultsAddressEdit = [...this.originalResultsAddressEdit];
    });
  }

  selectedOrder: any;
  getActionMenu(order: any) {
    return [
      { label: 'Editar', icon: 'pi pi-file-edit', command: () => this.editOrder(order.id) },
      { label: 'Deletar', icon: 'pi pi-trash', command: () => this.confirmDeletion(order.id) },
    ];
  }
  formatarComoReais(valor: any) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
  }

  getMonthAbbreviation(month: number): string {
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    return months[month];
  }


  onStateSave(event: any) {
    event.value = {
      first: event.first,
      rows: event.rows,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };
  }

  selectedDate!: Date;
  paymentMethodOptions!: any[];
  selectedTotalAmount!: number;
  selectedFullfilmentStatus!: string;
  paymentStatusOptions!: any[];
  fullfilmentStatusOptions!: any[];
  selectedPaymentStatus!: string;
  selectedPaymentStatusDialog!: string | null;
  selectedShippingStatusDialog!: string | null;

  filter(field: string, value: any) {
    // Implemente a lógica de filtragem aqui
    // 'field' é o campo pelo qual filtrar (por exemplo, 'payment_method')
    // 'value' é o valor selecionado para filtrar

    // Aqui você pode chamar um serviço, atualizar uma variável, etc., para realizar o filtro
  }


  cpfFilterValue!: string;

  onCpfFilter() {
    // Limpa o valor do CPF para conter apenas números
    const cleanCpfValue = this.cpfFilterValue.replace(/\D/g, '');

    // Aqui você chamaria o serviço para filtrar os dados com base no CPF
    // Isso depende de como você está carregando e filtrando seus dados.
    // Por exemplo, se você está usando um serviço HTTP para buscar dados:
    // this.seuDataService.filtrarPorCpf(cleanCpfValue).subscribe(data => {
    //     // Atualiza os dados da tabela com os dados filtrados
    //     this.orders = data;
    // });
  }

  clearFilter() {
    // Limpe a seleção do calendário
    this.rangeDates = null;

    // Recarregue os pedidos para o estado inicial
    this.loadOrders();
  }

  formatCpf(cpf: string): string {
    // Verifica se o CPF não é nulo e tem 11 dígitos
    if (cpf && cpf.length === 11) {
      return `${cpf.substr(0, 3)}.${cpf.substr(3, 3)}.${cpf.substr(6, 3)}-${cpf.substr(9, 2)}`;
    }
    return cpf;
  }

  onUpload(event: any) {
    // Adicionar as imagens ao array product.images
  }

  removeImage(file: any, index: number) {
    // Remover a imagem do array product.images
  }

  getLabelForProductShippingStatus(value: string | null): string {
    const status = this.statuses2.find(status => status.value === value);
    return status ? status.label : '-';
  }
  getLabelForPaymentStatus(value: string | null): string {
    const status = this.statuses.find(status => status.value === value);
    return status ? status.label : '-';
  }



  statuses2 = [
    { label: 'Parcialmente Enviado', value: '2' },
    { label: 'Enviado', value: '3' },
    { label: 'Pronto p/ Retirada', value: '4' },
    { label: 'Não Enviado', value: '1' },
    { label: 'Devolvido', value: '5' },
  ];
  getSeverity2(label: string): string {
    const severities: Severities = {
      'Enviado': 'success',
      'Parcialmente Enviado': 'warning',
      'Não Enviado': 'info',
      'Pronto p/ Retirada': 'primary',
      'Devolvido': 'danger',
    };
    return severities[label] || 'info';
  }


  statuses = [
    { label: 'Pago', value: '2' },
    { label: 'Aguardando', value: '1' },
    { label: 'Expirado', value: '3' },
    { label: 'Estornado', value: '4' },
    { label: 'Cancelado', value: '5' },

  ];
  getSeverity(label: string): string {
    const severities: Severities = {
      'Pago': 'success',
      'Aguardando': 'warning',
      'Expirado': 'info',
      'Estornado': 'primary',
      'Cancelado': 'danger',
    };
    return severities[label];
  }


  onQuantityChange(newQty: number, product: any) {
    const previousQty = product.inputQty;
    if (newQty > product.inputQty) {
      // Lógica para incremento AdicionarItem
      this.issueForm = this.fb.group({
        "item_qty": 1,
      });
      this.pedidosService.AdicionarItem(this.issueForm.value, this.shopping_cart_id, product.id).subscribe({
        next: (data) => {
          // Sucesso (status 200)
          this.getDeliveryMethod();
          this.calcCartId();











        },
        error: (error) => {
          product.inputQty = previousQty;
          let mensagemErro = error.message || 'Erro ao adicionar produto.'; // Mensagem padrão

          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: mensagemErro
          });

          //console.error('Erro ao add produto:', error);
          //console.error('Erro ao add produto:', error);
        }
      });
    } else if (newQty < product.inputQty) {
      // Lógica para decremento
      this.issueForm = this.fb.group({
        "item_qty": 1,
      });
      this.pedidosService.removerItem(this.issueForm.value, this.shopping_cart_id, product.id).subscribe({
        next: (data) => {
          // Sucesso (status 200)
          //this.getDeliveryMethod();
          this.calcCartId();

        },
        error: (error) => {
          product.inputQty = previousQty;
          // Erro

          // console.error('Erro ao adicionar produto:', error);
        }
      });
    }

    this.pedidosService.deatilCartId(this.shopping_cart_id).subscribe({
      next: (carrinho) => {
        // Encontrar o produto correspondente no carrinho e atualizar inputQty
        const updatedProduct = carrinho.products.find(p => p.id === product.id);
        if (updatedProduct) {
          product.inputQty = updatedProduct.qtd; // Atualiza com a nova quantidade
        }
      },
      error: (error) => {
        // Tratar possíveis erros da chamada da API
      }
    });

    this.pedidosService.deatilCartId(this.shopping_cart_id).subscribe({
      next: (carrinho) => {
        // Encontrar o produto correspondente no carrinho e atualizar inputQty
        const updatedProduct = carrinho.products.find(p => p.id === product.id);
        if (updatedProduct) {
          product.inputQty = updatedProduct.qtd; // Atualiza com a nova quantidade
        }
      },
      error: (error) => {
        // Tratar possíveis erros da chamada da API
      }
    });
  }
  cart_type: string = '';
  removeItemFromCart(product: any) {
    this.selectedItems = this.selectedItems.filter(item => item.id !== product.id);
    this.processSelectionChange();
    // Supondo que você tenha um serviço com um método chamado 'removerItem'
    this.issueForm = this.fb.group({
      "item_qty": product.inputQty,
    });
    this.pedidosService.removerItem(this.issueForm.value, this.shopping_cart_id, product.id).subscribe({
      next: (response) => {

        this.productRemoved.next(product);
        this.getDeliveryMethod();
        //this.productAdded.next(product);
        // Remover o item da lista 'selectedItems'
        const index = this.selectedItems.indexOf(product);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
        this.selectedItems = this.selectedItems.filter(item => item.id !== product.id);
        this.calcCartId();


        if (!this.selectedDeliveryMethod && this.cart_type != 'digital') {
          this.erro4 = true;
          this.hasError4 = true;
        } else {
          this.hasError4 = false;
        }

      },
      error: (error) => {

        console.error('Erro ao remover produto:', error);
      }
    });
  }
  cupom: string = '';
  cupomAplicado: boolean = false;
  aplicarCupom() {
    this.loadingCupom = true;
    this.pedidosService.aplicarCupom(this.cupom, this.shopping_cart_id).subscribe({
      next: (response) => {


        this.cupomAplicado = true;
        this.messageService.add({
          severity: 'success',
          summary: 'Cumpom Adicionado',
          detail: 'Cumpom Adicionado ao carrinho com sucesso!'
        });
        //
        this.calcCartId();
        this.loadingCupom = false;
      },
      error: (error) => {
        let mensagemErro = error.message || 'Erro ao adicionar cupom.'; // Mensagem padrão

        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: mensagemErro
        });

        console.error('Erro ao add cupom:', error);
        this.loadingCupom = false;
      }
    });
  }
  removerCupom() {
    this.pedidosService.removerCupom(this.shopping_cart_id).subscribe({
      next: (response) => {
        this.cupomAplicado = false;
        this.cupom = '';
        this.messageService.add({
          severity: 'success',
          summary: 'Cumpom Removido',
          detail: 'Cumpom removido do carrinho com sucesso!'
        });
        //
        this.calcCartId();
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Erro ao adicionar cumpom.'
        });
        console.error('Erro ao remover produto:', error);
      }
    });
  }
  erro1: boolean = false;
  erro2: boolean = false;
  erro3: boolean = false;
  erro4: boolean = false;
  erro5: boolean = false;
  erro6: boolean = false;
  erro7: boolean = false;
  hasError1: boolean = false;
  hasError2: boolean = false;
  hasError3: boolean = false;
  hasError4: boolean = false;
  hasError5: boolean = false;
  hasError6: boolean = false;
  hasError7: boolean = false;

  hasStep1Finished: boolean = false;
  hasStep2Finished: boolean = false;
  proximoStep() {
    // if (this.validacao.invalid) {
    //   Object.keys(this.validacao.controls).forEach(key => {
    //     const control = this.validacao.get(key);
    //     if (control) {
    //       control.markAsTouched();
    //     }
    //   });
    //   return;
    // }

    if (!this.selectedClient) {
      this.erro1 = true;
      this.hasError1 = true;
    } else {
      this.hasError1 = false;
    }
    if (this.selectedItems.length === 0) {
      this.erro2 = true;
      this.hasError2 = true;
    } else {
      this.hasError2 = false;
    }

    if (!this.selectedAddress && this.cart_type != 'digital') {
      this.erro3 = true;
      this.hasError3 = true;
    } else {
      this.hasError3 = false;
    }

    if (!this.selectedDeliveryMethod && this.cart_type != 'digital') {
      this.erro4 = true;
      this.hasError4 = true;
    } else {
      this.hasError4 = false;
    }

    // if (this.selectedDeliveryMethod?.id == 7 && this.manualValue == null) { // manualValue
    //   this.erro4 = true;
    //   this.hasError4 = true;
    // } else {
    //   this.hasError4 = false;
    // }

    if (this.erro1 || this.erro2 || this.erro3 || this.erro4) {
      return;
    }
    this.hasError1 = false;
    this.hasError2 = false;
    this.hasError3 = false;
    this.hasError4 = false;

    this.calcularOpcoesParcelamento();
    //if (this.items && this.activeIndex < this.items.length - 1) {
    this.hasStep1Finished = true;
    this.simular();
    this.quantidadeCobs();
    this.ValorManual();
    this.calcularOpcoesParcelamento();
    this.updateValorTotalSlider();
    this.goToNextStep(1);
  }
  goToNextStep(step: number) {
    if (this.canGoToStep(step)) {
      this.activeIndex = step;
    }
  }
  canGoToStep(step: number): boolean {
    // Aqui você coloca a lógica para verificar se pode ir para o próximo passo
    // Por exemplo, verifique se todos os campos do passo atual estão preenchidos
    // Vou dar um exemplo simples:
    if (step === 1) {
      if (this.hasStep1Finished === true) {
        return true;
      }
    } else if (step === 2) {
      if (this.hasStep2Finished === true) {
        return true;
      }
    }
    return false;
  }
  // Step 2
  opcoesParcelamento: any[] = [];
  //selecaoParcelamento?: string; valorFormatado?: string; value?: number;
  selecaoParcelamento?: number = 1;
  valorParcelaFormatado?: string;
  calcularOpcoesParcelamento() {
    this.opcoesParcelamento = [];
    const formatadorReais = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    for (let i = 1; i <= 12; i++) {
      const valorParcela = this.valorTotal / i;
      const valorParcelaFormatado = formatadorReais.format(valorParcela);
      if (i == 1) {
        this.opcoesParcelamento.push({
          cobrancas: `${i} cobrança de`,
          valorFormatado: valorParcelaFormatado,
          value: i
        });
      } else {
        this.opcoesParcelamento.push({
          cobrancas: `${i} cobranças de`,
          valorFormatado: valorParcelaFormatado,
          value: i
        });
      }

    }
  }
  cobrarTaxas: boolean = false;
  parcelamentoJuros: boolean = false;
  multasJurosDescontos: boolean = false;
  pagarVencimento: boolean = false;
  valSlider: number = 2;
  valSlider2: number = 2;
  selectedPaymentMethodCob: any;
  paymentOptionsCob = [
    {
      value: 'cartao',
      label: 'Cartão de crédito',
      icon: 'pi pi-credit-card',
      additionalText: ' receba em até X',
      duration: '7 dias úteis'
    },
    {
      value: 'pix',
      label: 'Pix',
      icon: 'pi pi-bolt',
      additionalText: ' receba em até X',
      duration: '7 dias úteis'
    },
    {
      value: 'boleto',
      label: 'Boleto',
      icon: 'pi pi-file',
      additionalText: ' receba em até X',
      duration: '7 dias úteis'
    }
  ];
  onMethodChange() {
    // Método chamado quando uma opção de pagamento é selecionada
    console.log(this.selectedPaymentMethodCob);
  }
  onClearAddress() {
    this.selectedAddress = null;
    this.deliveryOptions = [];
  }
  selectedPaymentMethodsCob: any[] = [];


  dataSelecionada?: Date;


  disabledDates: Date[] = [];
  onMethodsChange() {
    if (this.selectedPaymentMethodsCob && this.selectedPaymentMethodsCob.length > 0) {
      this.erro5 = false;
      this.hasError5 = false;
    }


    const isBoletoSelected = this.selectedPaymentMethodsCob.some(method => method.value === 'boleto');
    const isPixSelected = this.selectedPaymentMethodsCob.some(method => method.value === 'pix');
    const isCardSelected = this.selectedPaymentMethodsCob.some(method => method.value === 'cartao');
    if (isCardSelected) {
      this.hasSelectedCreditCard = true;
    } else {
      this.hasSelectedCreditCard = false;
    }
    if (isBoletoSelected || isPixSelected) {
      this.hasSelectedBoletoPix = true;
    } else {
      this.hasSelectedBoletoPix = false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Definir para meia-noite de hoje

    if (isBoletoSelected) {
      // Verificar se hoje já está na lista de datas desabilitadas
      if (!this.disabledDates.some(date => date.getTime() === today.getTime())) {
        // Adicionar hoje ao array de datas desabilitadas
        this.disabledDates = [...this.disabledDates, today];
      }
    } else {
      // Remover hoje do array de datas desabilitadas, se estiver presente
      this.disabledDates = this.disabledDates.filter(date => date.getTime() !== today.getTime());
    }
  }
  payment_limit?: number | null;
  numericOnly(event: KeyboardEvent): void {
    const allowedRegex = /^[0-9]*$/;
    if (!allowedRegex.test(event.key)) {
      event.preventDefault();
    }
  }
  calcularTotaisNew(
    payment_status: number,
    date_from: any,
    date_to: any,
    user_id?: number,
    match_mode_user_id?: string,
    user_name?: string,
    match_mode_user_name?: string,
    user_cpf?: string,
    match_mode_user_cpf?: string,
    forma_pagamento?: number,
    forma_entrega?: number
  ) {
    if (date_from && date_to) {
      // Defina as datas para o intervalo anterior com base no atual
      const previousInterval = this.calculatePreviousInterval(date_from, date_to);

      // Carregue os dados para o intervalo atual
      this.loadDataForInterval(payment_status, date_from, date_to, user_id, match_mode_user_id, user_name, match_mode_user_name, user_cpf, match_mode_user_cpf, forma_pagamento, forma_entrega, (currentData: any) => {

        //Carregue os dados para o intervalo anterior
        this.loadDataForInterval(payment_status, previousInterval.date_from, previousInterval.date_to, user_id, match_mode_user_id, user_name, match_mode_user_name, user_cpf, match_mode_user_cpf, forma_pagamento, forma_entrega, (previousData: any) => {

          // Calcule as porcentagens e atualize o estado
          this.updatePercentages(payment_status, currentData, previousData);

        });
      });
    } else {
      this.loadDataForInterval(payment_status, date_from, date_to, user_id, match_mode_user_id, user_name, match_mode_user_name, user_cpf, match_mode_user_cpf, forma_pagamento, forma_entrega, (currentData: any) => {

      });
    }
  }



  loadDataForInterval(
    payment_status: number,
    date_from: any,
    date_to: any,
    user_id?: number,
    match_mode_user_id?: string,
    user_name?: string,
    match_mode_user_name?: string,
    user_cpf?: string,
    match_mode_user_cpf?: string,
    forma_pagamento?: number,
    forma_entrega?: number,
    callback?: any) {
    this.pedidosService.listarPedidosVendasStore(payment_status, date_from, date_to, user_id, match_mode_user_id, user_name, match_mode_user_name, user_cpf, match_mode_user_cpf, forma_pagamento, forma_entrega)
      .subscribe((data: any) => {
        if (payment_status == 2) {
          this.totalAprovadas = data.pedidos_vendas.total_vendas;
          this.countAprovadas = data.pedidos_vendas.total_pedidos;
        } else if (payment_status == 1) {
          this.totalAguardando = data.pedidos_vendas.total_vendas;
          this.countAguardando = data.pedidos_vendas.total_pedidos;
        } if (payment_status == 3) {
          this.totalExpiradas = data.pedidos_vendas.total_vendas;
          this.countExpiradas = data.pedidos_vendas.total_pedidos;
        } if (payment_status == 5) {
          this.totalCanceladas = data.pedidos_vendas.total_vendas;
          this.countCanceladas = data.pedidos_vendas.total_pedidos;
        }
        callback(data);
      }, error => {
        // Trate o erro conforme necessário
      });
  }



  updatePercentages(payment_status: number, currentData: any, previousData?: any) {
    // Calcule as porcentagens aqui e atualize os estados relevantes
    const currentTotal = currentData.pedidos_vendas.total_vendas;
    const previousTotal = previousData.pedidos_vendas.total_vendas;

    let percentageChange: number | null = null;

    // Verifica se previousTotal não é zero para evitar a divisão por zero
    if (previousTotal !== 0) {
      // Cálculo da porcentagem de variação
      percentageChange = ((currentTotal - previousTotal) / previousTotal) * 100;
      // Verifica se o resultado não é infinito
      percentageChange = isFinite(percentageChange) ? percentageChange : null;
    }

    // Atualize os estados de acordo com o payment_status
    if (payment_status == 2) {
      this.porcentagemAprovadas = percentageChange;
    }
    if (payment_status == 1) {
      this.porcentagemAguardando = percentageChange;
    }
    if (payment_status == 3) {
      this.porcentagemExpiradas = percentageChange;
    }
    if (payment_status == 5) {
      this.porcentagemCanceladas = percentageChange;
    }
  }


  calculatePreviousInterval(date_from: any, date_to: any) {
    // Converter as strings de data para objetos Date
    const startDate = new Date(date_from);
    const endDate = new Date(date_to);

    // Calcular a diferença em dias entre as datas
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime()); // Corrigido aqui
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


    // Calcular a data de início do intervalo anterior
    const previousStartDate = new Date(startDate);
    previousStartDate.setDate(startDate.getDate() - diffDays);

    // Calcular a data de término do intervalo anterior (que será um dia antes do início do intervalo atual)
    const previousEndDate = new Date(startDate);
    previousEndDate.setDate(startDate.getDate() - 1);

    // Formatar as datas de volta para strings se necessário
    const formattedStart = `${previousStartDate.getFullYear()}-${previousStartDate.getMonth() + 1}-${previousStartDate.getDate()}`;
    const formattedEnd = `${previousEndDate.getFullYear()}-${previousEndDate.getMonth() + 1}-${previousEndDate.getDate()}`;

    return { date_from: formattedStart, date_to: formattedEnd };
  }


  confirmDeletion(orderId: any) {
    this.confirmationService.confirm({
      message: 'Você realmente deseja remover esta venda?',
      header: 'Confirmação de Exclusão',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deletar(orderId);
      },
      reject: () => {
      }
    });
  }
  order_id_current!: number | null;
  products!: OrderItem[];
  tidSelected!: string;
  tipoPagamento: string | null = null;
  dadosCartao: PayableWithCard | null = null;
  identificarTipoPagamento(transaction: Transaction | undefined) {
    if (transaction) {
      if ('payable_with_card' in transaction) {
        this.tipoPagamento = 'cartao';
        this.dadosCartao = transaction['payable_with_card'] ?? null;
      } else if ('payable_with_slip' in transaction) {
        this.tipoPagamento = 'boleto';
      } else if ('payable_with_pix' in transaction) {
        this.tipoPagamento = 'pix';
      }
    } else {
      this.tipoPagamento = null;
    }
  }
  isImage(filePath: any): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(filePath);
  }


  ////////////
  showDeleteButton: boolean = false;

  removeInvoice(event: MouseEvent) {
    event.stopPropagation();
    // Implemente a lógica para remover o arquivo selecionado
    this.fileUpload.files = [];
    console.log('invoiceSelected')
    console.log(this.invoiceSelected)
    if (this.invoiceSelected !== null) {
      this.invoiceSelected = null;

      this.issueForm = this.fb.group({
        "invoice": "",
      });
      this.pedidosService.updateOrderInvoice(this.issueForm.value, this.order_id_current).subscribe({
        next: (response) => {

          console.log('Order atualizada com successo!', response);
          // this.displayDialogSidebar = false;
          // this.loadingFinishUpload = false;
          // this.messageService.add({
          //   severity: 'success',
          //   summary: 'Nota Fiscal',
          //   detail: 'Nota Fiscal atualizada com sucesso!'
          // });
        },
        error: (error) => {
          let mensagemErro = error.message || 'Erro ao remover nota fiscal.'; // Mensagem padrão
          this.loadingFinishUpload = false;
          this.displayDialogSidebar = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: mensagemErro
          });
          console.log('Erro ao atualizar Order!', error);
          this.displayDialogSidebar = false;
        }
      });
    }
    // chamar service p/ remover invoice




  }
  onFileSelect(event: any): void {
    if (event.files && event.files.length > 0) {
      const file = event.files[0];
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.invoiceSelected = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  @ViewChild('fileUpload') fileUpload!: FileUpload;
  myUploadMethod(event: any) {
    console.log('chamou myUploadMethod');
    const formData: FormData = new FormData();

    // Vou assumir que você quer enviar o primeiro arquivo selecionado
    const file = event.files[0];
    formData.append('mime', file, file.name);

    // O campo 'folder' é fixo e deve ser enviado junto, como você mostrou no Postman
    formData.append('folder', 'thumbnail');

    this.http.post<UploadResponse>('https://app.krabo.io/api/utilities/upload_store_images', formData).subscribe(
      response => {
        // Trate a resposta aqui UploadResponse
        console.log('Upload concluído com sucesso!', response);
        this.invoiceSelected = response.imagePath ?? null;
        // imagePath updateOrder
        this.issueForm = this.fb.group({
          "invoice": response.imagePath,
        });
        this.pedidosService.updateOrderInvoice(this.issueForm.value, this.order_id_current).subscribe({
          next: (response) => {

            console.log('Order atualizada com successo!', response);
            this.displayDialogSidebar = false;
            this.loadingFinishUpload = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Nota Fiscal',
              detail: 'Nota Fiscal atualizada com sucesso!'
            });
          },
          error: (error) => {
            let mensagemErro = error.message || 'Erro ao adicionar produto.'; // Mensagem padrão
            this.loadingFinishUpload = false;
            this.displayDialogSidebar = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: mensagemErro
            });
            console.log('Erro ao atualizar Order!', error);
            this.displayDialogSidebar = false;
          }
        });
        // imagePath updateOrder



      },
      error => {
        // Verifica se a resposta de erro tem um formato esperado
        let mensagemErro = 'Erro no upload.'; // Mensagem padrão

        if (error.error instanceof ErrorEvent) {
          // Erro do lado do cliente ou problema de rede
          mensagemErro = `Erro do lado do cliente: ${error.error.message}`;
        } else {
          // O backend retornou uma resposta de erro bem-sucedida.
          // O corpo da resposta pode conter pistas sobre o que deu errado,
          mensagemErro = error.error.message || error.message || mensagemErro;
        }

        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: mensagemErro
        });

        console.error('Erro no upload:', error);
        this.displayDialogSidebar = false;
      }
    );
  }

  loadingFinishUpload: boolean = false;
  enviarArquivo() {
    // Verifica se algum arquivo foi selecionado
    if (this.fileUpload.files.length === 0) {
      // Nenhum arquivo selecionado, mostra o toast de erro
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Selecione uma imagem ou arquivo pdf antes de enviar.'
      });
    } else {
      // Arquivo selecionado, prossegue com o upload
      this.loadingFinishUpload = true;
      this.displayDialogSidebar = true;
      this.fileUpload.upload();
    }
  }
  showDialogEditOrder: boolean = false;
  //@ViewChild('paymentStatus') paymentStatus!: ElementRef;

  isElementVisible(el: ElementRef): boolean {
    const input = el.nativeElement.querySelector('input');
    return input && (input.offsetWidth > 0 || input.offsetHeight > 0);
  }
  onDialogOpened() {
    console.log('dialog opened');
    // Agora o diálogo está aberto, então chamamos triggerFocus
    if (this.showDialogEditOrder) {
      this.focusService.triggerFocus();
    }
  }
  handleDialogShow() {
    // Aplicar foco ao p-dropdown
    setTimeout(() => {
      const dropdownInput = this.paymentStatus.nativeElement.querySelector('.p-dropdown-label');
      if (dropdownInput) {
        dropdownInput.focus();
      }
    }, 0); // O atraso pode ser necessário para a UI se acomodar
  }
  // pagamente
  statusPagamento: boolean = false;
  statusEntrega: boolean = false;
  linkRas: boolean = false;
  enderecoCliente: boolean = false;
  opc(n: number) {
    this.changeOrder = true;
    console.log('onchange ooooo')
    if (n === 1) {
      this.statusPagamento = true;
    }
    if (n === 2) {
      this.statusEntrega = true;
    }
    if (n === 3) {
      console.log('onchnage update rastreio');
      this.linkRas = true;
    }
    if (n === 4) {
      this.enderecoCliente = true;
    }

  }
  @ViewChild('paymentStatus', { static: false }) paymentStatus!: ElementRef;
  @ViewChild('shippingStatus') shippingStatus!: ElementRef;
  @ViewChild('linkRastreio') linkRastreio!: ElementRef;
  @ViewChild('address') address!: ElementRef;
  @ViewChild('paymentDropdown') paymentDropdown!: ElementRef;

  cliclouPagamento: boolean = false;
  addClassToDropdown: boolean = false;
  addClassToDropdownShippingStatus: boolean = false;
  addClassLinkRastreio: boolean = false;
  addClassAddress: boolean = false;
  changeOrder: boolean = false;
  editarOrder(orderType: number) {
    this.showDialogEditOrder = true;
    if (orderType === 2) {
      this.addClassLinkRastreio = true;
      this.cdr.detectChanges();
    } else {
      this.addClassLinkRastreio = false;
    }
    if (orderType === 1) {
      console.log('entrega')
      this.addClassToDropdownShippingStatus = true;
      this.cdr.detectChanges();
    } else {
      this.addClassToDropdownShippingStatus = false;
    }
    if (orderType === 3) {
      this.addClassToDropdown = true;
      this.cdr.detectChanges();
    } else {
      this.addClassToDropdown = false;
    }
    if (orderType === 4) {
      this.addClassAddress = true;
      this.cdr.detectChanges();
    } else {
      this.addClassAddress = false;
    }

    // selectedAddressEdit
    // Detalhar usuário vinculado a loja
    this.pedidosService.detailUserFromStore(this.customerIdSelected).subscribe({
      next: (data) => {
        console.log(data[0].wallet_id)
        this.getUserAddressEdit(data[0].wallet_id);

      },
      error: (err) => {
        // Exiba uma mensagem de erro
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Erro ao detalhar venda.'
        });
        this.displayDialog = false;
      }
    });

  }
  trackingLink: string = '';
  loadingFinishSaveOrder: boolean = false;
  formatAddressPart(part: string | null): string {
    return part ? part : '';
  }
  onSelectAddressEdit(event: any) {
    this.changeOrder = true;
    this.enderecoCliente = true;

    const address = event;
    this.addressSelected = `${this.formatAddressPart(address.nickname)} (${this.formatAddressPart(address.address_street)} - ${this.formatAddressPart(address.address_number)} - ${this.formatAddressPart(address.address_district)} - ${this.formatAddressPart(address.address_complement)} - ${this.formatAddressPart(address.city)}, ${this.formatAddressPart(address.state)} - ${this.formatAddressPart(address.country)} - ${this.formatAddressPart(address.postal_code)})`;
  }
  clearEditForm() {
    this.showDialogEditOrder = false;
    console.log('chamou close form edit')
    this.selectedPaymentStatusDialog = null;
    this.selectedShippingStatusDialog = null;
    this.trackingLink = '';
    this.selectedAddressEdit = null;
    this.changeOrder = false;
  }
  saveOrder() {
    // loadingFinishSaveOrder loadingFinishSaveOrder
    if (this.statusPagamento || this.statusEntrega || this.linkRas) { // update order
      console.log('update rastreio');
      if (this.linkRas) {
        console.log('update rastreio');
        this.loadingFinishSaveOrder = true;
        this.issueForm = this.fb.group({
          "shipping_tracker_id": "1",
          "shipping_tracker_link": this.trackingLink
        });
        this.pedidosService.updateLinkRas(this.issueForm.value, this.order_id_current).subscribe({
          next: (response) => {
            this.loadingFinishSaveOrder = false;
            this.changeOrder = false;
            this.linkRas = false;
            console.log(response);
            this.shipping_tracker_linkSelected = this.trackingLink;
            console.log('atualizou pedido');

            //////////////////////////////////////////
            this.messageService.add({
              severity: 'success',
              summary: 'Link de Rastreio',
              detail: 'Link de Rastreio atualizado com sucesso!'
            });
            //////////////////////////////////////////

          },
          error: (error) => {
            this.loadingFinishSaveOrder = false;
            this.loadingFinish = false;
            let message = '';
            if (error.status === 400 || error.status === 403 || error.status === 401) {
              message = error.error.message;
            } else if (error.status === 500) {
              message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            }

            if (message) {
              this.showErrorDialog(message);
            }
          }
        });
      }
      if (this.statusPagamento || this.statusEntrega) { // update order
        this.loadingFinishSaveOrder = true;
        if (this.statusPagamento && this.statusEntrega) {
          this.issueForm = this.fb.group({
            "invoice": this.invoiceSelected,
            "payment_status": this.selectedPaymentStatusDialog==null?this.paymentStatusStatusSelected:this.selectedPaymentStatusDialog,
            "tid": this.tidSelected,
            "billing_id": this.billingIdSelected,
            "status": this.statusSelected,
            "fullfilment_status": this.selectedShippingStatusDialog==null?this.shippingMethodSelectedId:this.selectedShippingStatusDialog,
            "shipping_tracker_id": this.shippingTrackerSelected,
            "shipping_tracker_link": this.shippingTrackerLinkSelected,
            "note": this.noteSelected
          });
        } else if (this.statusPagamento) {
          //this.loadingFinishSaveOrder = true;
          this.issueForm = this.fb.group({
            "invoice": this.invoiceSelected,
            "payment_status": this.selectedPaymentStatusDialog==null?this.paymentStatusStatusSelected:this.selectedPaymentStatusDialog,
            "tid": this.tidSelected,
            "billing_id": this.billingIdSelected,
            "status": this.statusSelected,
            "fullfilment_status": this.shippingMethodSelectedId,
            "shipping_tracker_id": this.shippingTrackerSelected,
            "shipping_tracker_link": this.shippingTrackerLinkSelected,
            "note": this.noteSelected
          });
        } else {
          this.issueForm = this.fb.group({
            "invoice": this.invoiceSelected,
            "payment_status": this.paymentStatusStatusSelected,
            "tid": this.tidSelected,
            "billing_id": this.billingIdSelected,
            "status": this.statusSelected,
            "fullfilment_status": this.selectedShippingStatusDialog==null?this.shippingMethodSelectedId:this.selectedShippingStatusDialog,
            "shipping_tracker_id": this.shippingTrackerSelected,
            "shipping_tracker_link": this.shippingTrackerLinkSelected,
            "note": this.noteSelected
          });
        }

        console.log(this.issueForm.value)
        this.loadingFinishSaveOrder = true;
        this.pedidosService.updateOrder(this.issueForm.value, this.order_id_current).subscribe({
          next: (response) => {
            this.loadingFinishSaveOrder = false;
            this.changeOrder = false;
            this.loadingFinish = false;
            console.log(response);
            console.log('atualizou pedido');
            if (this.statusPagamento && this.statusEntrega) {
              this.paymentStatusStatusSelected = this.selectedPaymentStatusDialog;
              this.statusPagamento = false;
              this.productShippingStatusSelected = this.selectedShippingStatusDialog;
              this.statusEntrega = false;
              if(this.paymentStatusStatusSelected == '5'){
                this.cancelOrderDisabled = true;
              }else{
                this.cancelOrderDisabled = false;
              }
            } else if (this.statusPagamento) {
              this.paymentStatusStatusSelected = this.selectedPaymentStatusDialog;
              this.statusPagamento = false;
              if(this.paymentStatusStatusSelected == '5'){
                this.cancelOrderDisabled = true;
              }else{
                this.cancelOrderDisabled = false;
              }
            }
            if (this.statusEntrega) {
              this.productShippingStatusSelected = this.selectedShippingStatusDialog;
              this.statusEntrega = false;
            }
            //////////////////////////////////////////
            this.messageService.add({
              severity: 'success',
              summary: 'Venda Cancelada',
              detail: 'A venda foi cancelada com sucesso!'
            });
            //////////////////////////////////////////

          },
          error: (error) => {
            this.loadingFinishSaveOrder = false;
            this.loadingFinish = false;
            let message = '';
            if (error.status === 400 || error.status === 403 || error.status === 401) {
              message = error.error.message;
            } else if (error.status === 500) {
              message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            }

            if (message) {
              this.showErrorDialog(message);
            }
          }
        });

      }
    }
    if (this.enderecoCliente) { // update address updateAddress
      console.log('endereco cliente')
      if (this.selectedAddressEdit) {
        this.loadingFinishSaveOrder = true;
        this.pedidosService.updateAddress(this.billingIdSelected, this.selectedAddressEdit.id).subscribe({
          next: (response) => {
            this.changeOrder = false;
            this.loadingFinishSaveOrder = false;
            console.log(response);
            console.log('atualizou pedido');
            if (this.enderecoCliente) {
              // this.addressSelected = this.selectedAddressEdit?.nickname;
              // this.addressSelected = this.selectedAddressEdit.address.address_street + " - " + data.address.address_number + " - " + data.address.city + ", " + data.address.state + " - " + data.address.address_district + " - CEP " + data.address.postal_code + " - " + data.address.address_complement;
              // {{ address.nickname }}({{ address.address_street }} - {{ address.address_number }} - {{
              //   address.address_district }} - {{ address.address_complement }} - {{ address.city }}, {{
              //   address.state }} - {{ address.country }} - {{ address.postal_code }})

              this.enderecoCliente = false;
            }
            //////////////////////////////////////////
            this.messageService.add({
              severity: 'success',
              summary: 'Endereço',
              detail: 'Endereço do cliente atualizado com sucesso!'
            });
            //////////////////////////////////////////

          },
          error: (error) => {
            this.loadingFinishSaveOrder = false;
            this.loadingFinish = false;
            let message = '';
            if (error.status === 400 || error.status === 403 || error.status === 401) {
              message = error.error.message;
            } else if (error.status === 500) {
              message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            }

            if (message) {
              this.showErrorDialog(message);
            }
          }
        });
      }

    }

    // loadingFinishSaveOrder
    //this.displaySidebarEdit = true;
    //this.showDialogEditOrder = false;
    // this.order_id_current
  }

  cancelOrderDisabled:boolean = false;
  cancelOrder() {

    this.confirmationService.confirm({
      message: 'Você realmente deseja cancelar esta venda?',
      header: 'Confirmação de Cancelamento',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.displayDialogSidebar = true;
        //////////////////
        this.issueForm = this.fb.group({
          "invoice": this.invoiceSelected,
          "payment_status": 5,
          "tid": this.tidSelected,
          "billing_id": this.billingIdSelected,
          "status": this.statusSelected,
          "fullfilment_status": this.productShippingStatusSelected,
          "shipping_tracker_id": this.shippingTrackerSelected,
          "shipping_tracker_link": this.shippingTrackerLinkSelected,
          "note": this.noteSelected
        });
        this.pedidosService.updateOrder(this.issueForm.value, this.order_id_current).subscribe({
          next: (response) => {
            console.log(response);
            console.log('atualizou pedido');
            this.paymentStatusStatusSelected = "5";
            //////////////////////////////////////////
            if(this.paymentStatusStatusSelected == '5'){
              this.cancelOrderDisabled = true;
            }else{
              this.cancelOrderDisabled = false;
            }
            this.messageService.add({
              severity: 'success',
              summary: 'Venda Cancelada',
              detail: 'A venda foi cancelada com sucesso!'
            });
            //////////////////////////////////////////
            this.displayDialogSidebar = false;
          },
          error: (error) => {
            this.displayDialogSidebar = false;
            this.loadingFinish = false;
            let message = '';
            if (error.status === 400 || error.status === 403 || error.status === 401) {
              message = error.error.message;
            } else if (error.status === 500) {
              message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            }

            if (message) {
              this.showErrorDialog(message);
            }
          }
        });
        //////////////////
      },
      reject: () => {
      }
    });


  }
  shippingContent:boolean = true;
  editOrder(orderId: any) {
    console.log("Edit Order");
    this.displayDialogSidebar = true;
    this.displaySidebarEdit = true;
    this.order_id_current = orderId;
    console.log(orderId)
    // detailBilling

    this.pedidosService.detailOrder(orderId).subscribe({
      next: (res) => {
        console.log(res)
        this.tidSelected = res.tid;
        ///////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////
        this.products = res.order_items;

        this.invoiceSelected = res.invoice;
        //this.date_current = formatDate(res.created_at, 'dd/MM/yyyy', 'en-US');
        this.date_current = formatDate(res.created_at, 'dd/MM/yyyy \'às\' HH:mm', 'en-US');

        this.customerNameSelected = res.customer_name;

        this.customerSurnameSelected = res.customer_surname;
        //this.walletIdSelected = res.wallet_id;
        this.customerIdSelected = res.customer_id;

        this.billingIdSelected = res.billing_id;
        this.statusSelected = res.status;
        this.shippingTrackerSelected = res.shipping_tracker_id;
        this.shippingTrackerLinkSelected = res.shipping_tracker_link;
        this.noteSelected = res.note;

        this.subtotalSelected = res.subtotal;
        this.shipping_tracker_linkSelected = res.shipping_tracker_link;
        this.valorFreteSelected = res.shipping_price;
        this.valorPromoSelected = 0;
        this.valorCupomSelected = res.voucher_discount;
        this.valorTotalSelected = res.total_amount;
        this.shippingContent = true;
        if (res.shipping_method == 1) {
          this.shippingMethodSelected = 'Correios - Sedex';
          this.shippingMethodSelectedId = 1;
        } else if (res.shipping_method == 2) {
          this.shippingMethodSelected = 'Correios - PAC';
          this.shippingMethodSelectedId = 2;
        } else if (res.shipping_method == 3) {
          this.shippingMethodSelected = 'Melhor Envio';
          this.shippingMethodSelectedId = 3;
        } else if (res.shipping_method == 4) {
          this.shippingMethodSelected = 'Retirada em Loja';
          this.shippingMethodSelectedId = 4;
        } else if (res.shipping_method == 5) {
          this.shippingMethodSelected = 'Entrega Local - Por Bairro';
          this.shippingMethodSelectedId = 5;
        } else if (res.shipping_method == 6) {
          this.shippingMethodSelected = 'Entrega Local - Por Km';
          this.shippingMethodSelectedId = 6;
        } else if (res.shipping_method == 7) {
          this.shippingMethodSelected = 'Personalizada';
          this.shippingMethodSelectedId = 7;
        }else{
          this.shippingContent = false;
        }

        console.log('cont st ent')
        console.log(res.fullfilment_status)

        if (res.fullfilment_status != null) {
          this.productShippingStatusSelected = res.fullfilment_status.toString();
        } else {
          this.productShippingStatusSelected = null;
        }
        if (res.payment_status != null) {
          this.paymentStatusStatusSelected = res.payment_status.toString();
          if(this.paymentStatusStatusSelected == '5'){
            this.cancelOrderDisabled = true;
          }else{
            this.cancelOrderDisabled = false;
          }
        } else {
          this.paymentStatusStatusSelected = null;
        }

        // buscar endereço
        this.pedidosService.detailBilling(this.tidSelected).subscribe({
          next: (data) => {
            console.log(data)
            this.addressSelected = data.address.address_street + " - " + data.address.address_number + " - " + data.address.city + ", " + data.address.state + " - " + data.address.address_district + " - CEP " + data.address.postal_code + " - " + data.address.address_complement;
            this.identificarTipoPagamento(data.transaction);
            this.displayDialogSidebar = false;

          },
          error: (err) => {
            // Exiba uma mensagem de erro
            // this.messageService.add({
            //   severity: 'error',
            //   summary: 'Erro',
            //   detail: 'Erro ao detalhar venda.'
            // });
            // this.displayDialog = false;
          }
        });
        // buscar endereço

      },
      error: (err) => {
        // Exiba uma mensagem de erro
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Erro ao detalhar venda.'
        });
        this.displayDialog = false;
      }
    });



  }

  loadingDelete = false;
  displayDialog = false;

  displaySidebar: boolean = false;
  displaySidebarEdit: boolean = false;
  closeSidebarDetail() {

    this.order_id_current = null;
    this.date_current = null;
    this.subtotalSelected = null;
    this.valorFreteSelected = null;
    this.valorCupomSelected = null;
    this.valorTotalSelected = null;
    this.shippingMethodSelected = null;
    this.shippingMethodSelectedId = null;
    this.shipping_tracker_linkSelected = null;
    this.addressSelected = null;
    this.productShippingStatusSelected = '';
    this.paymentStatusStatusSelected = '';
    this.products = [];
    this.displaySidebarEdit = false;
  }
  closeSidebar() { // selectedPaymentMethodsCob
    console.log('chamou fechar')
    console.log('chamou fechar')
    this.activeIndex = 0;

    this.cupomAplicado = false;
    this.selectedClient = undefined;
    this.parcelamentoJuros = false;
    this.selecaoParcelamento = 1;
    this.dataSelecionada = undefined;
    this.pagarVencimento = false;
    this.multasJurosDescontos = false;
    this.payment_limit = null;
    this.interestHandler = [];
    this.fineHandler = [];
    this.fineHandler2 = 1;
    this.late_fee_daily = null;
    this.late_fee = null;
    this.discount.discount_percentage = undefined;


    this.selectedItems = [];
    this.processSelectionClose();
    this.selectedAddress = null;
    this.selectedDeliveryMethod = null;
    this.valorFrete = 0;
    this.manualValue = null;
    this.cupom = '';

    this.selectedPaymentMethodsCob = [];

    this.selecaoParcelamento = undefined;
    this.cobrarTaxas = false;
    this.selectedPaymentMethodsCob = [];
    this.parcelamentoJuros = false;
    this.dataSelecionada = undefined;
    this.pagarVencimento = false;
    this.payment_limit = undefined;
    this.multasJurosDescontos = false;
    this.qrCodeUrl = '';
    this.url_share = '';

    this.erro1 = false;
    this.erro2 = false;
    this.erro3 = false;
    this.erro4 = false;
    this.erro5 = false;
    this.erro6 = false;
    this.erro7 = false;

    this.hasError1 = false;
    this.hasError2 = false;
    this.hasError3 = false;
    this.hasError4 = false;
    this.hasError5 = false;
    this.hasError6 = false;
    this.hasError7 = false;




    this.hasStep1Finished = false;
    this.hasStep2Finished = false;
    this.shopping_cart_id = 0;
    this.shopping_cart_id = 0;
    this.subtotal = 0;
    this.valorTotal = 0;

    this.valorFrete = 0;
    this.valorPromo = 0;
    this.valorCupom = 0;
    this.valorTotal = 0;
    this.displaySidebar = false;


  }
  displayDialogSidebar = false;
  openSidebar() {
    this.issueForm = this.fb.group({
      "key": "installment_interest",
    });
    this.pedidosService.installmentInterest(this.issueForm.value).subscribe((data: InstPrice[]) => {
      console.log('InstPrice')
      console.log(data)
      if (data && data.length > 0) {
        console.log('price antes0')
        console.log(data[0].price)
        this.price = parseFloat(data[0].price);
        console.log('price antes')
        console.log(this.price)
        this.updateValorTotalSlider();
      }
    });
    this.displayDialogSidebar = true;
    this.displaySidebar = true;
    this.pedidosService.getTodosProdutos().subscribe((data: Produto[]) => {
      console.log('produtodos')
      console.log(data)
      this.itemsProdutos = this.processarProdutos(data);
    })
    this.pedidosService.GetUsuariosVinculadosLoja().subscribe((data: Usuario[]) => {
      console.log('data');
      console.log(data);
      this.originalResults = data.map((user: Usuario) => ({
        name: user.user_name,
        surname: user.user_surname,
        id: user.user_id,
        wallet_id: user.wallet_id
      }));
      this.filteredResults = [...this.originalResults];
      this.displayDialogSidebar = false;
    });

  }

  verVenda() { /* ... */ }
  editar() { /* ... */ }
  deletar(orderId: any): void {
    this.displayDialog = true;
    this.pedidosService.DeletePedido(orderId).subscribe({
      next: (res) => {
        // Remova o pedido da lista de pedidos
        this.orders = this.orders.filter(order => order.id !== orderId);

        // Exiba uma mensagem de sucesso
        this.messageService.add({
          severity: 'success',
          summary: 'Venda Deletada',
          detail: 'A venda foi deletada com sucesso!'
        });
        this.displayDialog = false;
      },
      error: (err) => {
        // Exiba uma mensagem de erro
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Erro ao deletar venda.'
        });
        this.displayDialog = false;
      }
    });
  }
  imprimir() { /* ... */ }
  verTransacao() { /* ... */ }



  selectedPaymentMethod: any;
  filteredOrders: any[] = [];
  rangeDates: Date[] | null = null;

  itemsButtons: MenuItem[] = [];
  itemsButtonsActions: MenuItem[] = [];
  onDateSelect() {
    // Quando uma nova data é selecionada, recarregue a tabela.
    this.loadOrders();
  }

  loadOrders(event: any = {}) {
    this.loading = true;

    const { first = 0, rows = 10, filters = {} }: { first?: number, rows?: number, filters: Filters } = event || {};
    const page = first / rows + 1;

    const adaptedFilters: Filters = {};
    for (const [key, filter] of Object.entries(filters)) {
      adaptedFilters[key] = {
        value: filter.value,
        matchMode: filter.matchMode
      };
    }

    const filterParams = this.prepareFilterParams(adaptedFilters);

    // Verifique se rangeDates está definido e tem exatamente dois valores (início e fim)
    if (this.rangeDates && this.rangeDates.length === 2) {
      // Verifique se ambas as datas estão preenchidas
      const [start, end] = this.rangeDates;
      if (start instanceof Date && !isNaN(start.valueOf()) && end instanceof Date && !isNaN(end.valueOf())) {


        if (start && end) {
          //
          adaptedFilters['created_at'] = {
            value: [start.toISOString(), end.toISOString()],
            matchMode: 'range'
          };
          this.pedidosService.GetPedidosPorParmeters(page, rows, adaptedFilters)
            .subscribe((data: any) => {

              const responseData: ResponsePedidos = data;
              this.orders = responseData.order || [];

              this.totalRecords = responseData.totalRecords;
              //listarPedidosVendasStore
              //this.calcularTotais();
              const formattedStart = start.toISOString().split('T')[0];
              const formattedEnd = end.toISOString().split('T')[0];
              /////////////////////
              if (filterParams.payment_status === null || typeof filterParams.payment_status === 'undefined') {
                [2, 1, 3, 5].forEach(status => {
                  this.calcularTotaisNew(
                    status,
                    filterParams.user_id,
                    filterParams.match_mode_user_id,
                    filterParams.user_name,
                    filterParams.match_mode_user_name,
                    filterParams.user_cpf,
                    filterParams.match_mode_user_cpf,
                    filterParams.payment_status,
                    filterParams.fullfilment_status
                  );
                });
              } else {
                if (filterParams.payment_status == 1) {
                  this.countAprovadas = 0;
                  this.totalAprovadas = 0;

                  this.countExpiradas = 0;
                  this.totalExpiradas = 0;

                  this.countCanceladas = 0;
                  this.totalCanceladas = 0;
                } else if (filterParams.payment_status == 2) {
                  this.countAguardando = 0;
                  this.totalAguardando = 0;

                  this.countExpiradas = 0;
                  this.totalExpiradas = 0;

                  this.countCanceladas = 0;
                  this.totalCanceladas = 0;
                } else if (filterParams.payment_status == 3) {
                  this.countAguardando = 0;
                  this.totalAguardando = 0;

                  this.countAprovadas = 0;
                  this.totalAprovadas = 0;

                  this.countCanceladas = 0;
                  this.totalCanceladas = 0;
                } else if (filterParams.payment_status == 5) {
                  this.countAguardando = 0;
                  this.totalAguardando = 0;

                  this.countAprovadas = 0;
                  this.totalAprovadas = 0;

                  this.countExpiradas = 0;
                  this.totalExpiradas = 0;
                }
                [filterParams.payment_status].forEach(status => {

                  this.calcularTotaisNew(
                    status,
                    filterParams.user_id,
                    filterParams.match_mode_user_id,
                    filterParams.user_name,
                    filterParams.match_mode_user_name,
                    filterParams.user_cpf,
                    filterParams.match_mode_user_cpf,
                    filterParams.payment_status,
                    filterParams.fullfilment_status
                  );
                });
              }
              /////////////////////
              this.loading = false;

            }, error => {

              console.error("Erro ao carregar pedidos: ", error);
              this.loading = false;

              if (error.status === 404) {
                // Quando o status é 404, defina 'orders' como uma lista vazia.
                this.orders = [];
                this.totalRecords = 0;
              }
              //listarPedidosVendasStore
              //this.calcularTotais();
              const formattedStart = start.toISOString().split('T')[0];
              const formattedEnd = end.toISOString().split('T')[0];
              this.countAguardando = 0;
              this.totalAguardando = 0;

              this.countExpiradas = 0;
              this.totalExpiradas = 0;

              this.countCanceladas = 0;
              this.totalCanceladas = 0;

              this.countAprovadas = 0;
              this.totalAprovadas = 0;
            });
        } else {
          // Se apenas uma data estiver preenchida, não faça a chamada e desative o carregamento
          this.loading = false;
        }
      } else {
        // Datas inválidas, não faça a chamada da API e desative o carregamento
        this.loading = false;
        return;
      }
    } else {
      // Se o filtro de data não estiver sendo utilizado, faça a chamada padrão
      this.pedidosService.GetPedidosPorParmeters(page, rows, adaptedFilters)
        .subscribe((data: any) => {

          const responseData: ResponsePedidos = data;
          this.orders = responseData.order || [];

          this.totalRecords = responseData.totalRecords;
          //listarPedidosVendasStore
          if (filterParams.payment_status === null || typeof filterParams.payment_status === 'undefined') {
            [2, 1, 3, 5].forEach(status => {
              this.calcularTotaisNew(
                status,
                filterParams.user_id,
                filterParams.match_mode_user_id,
                filterParams.user_name,
                filterParams.match_mode_user_name,
                filterParams.user_cpf,
                filterParams.match_mode_user_cpf,
                filterParams.payment_status,
                filterParams.fullfilment_status
              );
            });
          } else {
            if (filterParams.payment_status == 1) {
              this.countAprovadas = 0;
              this.totalAprovadas = 0;

              this.countExpiradas = 0;
              this.totalExpiradas = 0;

              this.countCanceladas = 0;
              this.totalCanceladas = 0;
            } else if (filterParams.payment_status == 2) {
              this.countAguardando = 0;
              this.totalAguardando = 0;

              this.countExpiradas = 0;
              this.totalExpiradas = 0;

              this.countCanceladas = 0;
              this.totalCanceladas = 0;
            } else if (filterParams.payment_status == 3) {
              this.countAguardando = 0;
              this.totalAguardando = 0;

              this.countAprovadas = 0;
              this.totalAprovadas = 0;

              this.countCanceladas = 0;
              this.totalCanceladas = 0;
            } else if (filterParams.payment_status == 5) {
              this.countAguardando = 0;
              this.totalAguardando = 0;

              this.countAprovadas = 0;
              this.totalAprovadas = 0;

              this.countExpiradas = 0;
              this.totalExpiradas = 0;
            }
            [filterParams.payment_status].forEach(status => {

              this.calcularTotaisNew(
                status,
                filterParams.user_id,
                filterParams.match_mode_user_id,
                filterParams.user_name,
                filterParams.match_mode_user_name,
                filterParams.user_cpf,
                filterParams.match_mode_user_cpf,
                filterParams.payment_status,
                filterParams.fullfilment_status
              );
            });
          }


          // this.calcularTotais();
          this.loading = false;
        }, error => {

          console.error("Erro ao carregar pedidos: ", error);
          this.loading = false;

          if (error.status === 404) {
            // Quando o status é 404, defina 'orders' como uma lista vazia.
            this.orders = [];
            this.totalRecords = 0;
          }
          this.countAguardando = 0;
          this.totalAguardando = 0;

          this.countExpiradas = 0;
          this.totalExpiradas = 0;

          this.countCanceladas = 0;
          this.totalCanceladas = 0;

          this.countAprovadas = 0;
          this.totalAprovadas = 0;
          //listarPedidosVendasStore
          //this.calcularTotais();
          // [2, 1, 3, 5].forEach(status => {
          //   this.calcularTotaisNew(
          //     status,
          //     filterParams.user_id,
          //     filterParams.match_mode_user_id,
          //     filterParams.user_name,
          //     filterParams.match_mode_user_name,
          //     filterParams.user_cpf,
          //     filterParams.match_mode_user_cpf,
          //     filterParams.payment_status,
          //     filterParams.fullfilment_status
          //   );
          // });
          ///////
        });
    }
  }


  prepareFilterParams(adaptedFilters: Filters): any {
    let params: any = {};

    // Extrai e formata todos os parâmetros e matchModes que podem ser usados
    if (adaptedFilters['created_at']) {
      params.date_from = adaptedFilters['created_at'].value[0];
      params.date_to = adaptedFilters['created_at'].value[1];
    }
    if (adaptedFilters['id']) {
      params.user_id = adaptedFilters['id'].value;
      params.match_mode_user_id = adaptedFilters['id'].matchMode;
    }
    if (adaptedFilters['customer_name']) {
      params.user_name = adaptedFilters['customer_name'].value;
      params.match_mode_user_name = adaptedFilters['customer_name'].matchMode;
    }
    if (adaptedFilters['cpf']) {
      params.user_cpf = adaptedFilters['cpf'].value;
      params.match_mode_user_cpf = adaptedFilters['cpf'].matchMode;
    }
    if (adaptedFilters['payment_status']) {
      params.payment_status = adaptedFilters['payment_status'].value;
    }
    if (adaptedFilters['fullfilment_status']) {
      params.fullfilment_status = adaptedFilters['fullfilment_status'].value;
    }

    return params;
  }


  payments: any[] = [];
  getPaymentMethods(paymentMethod: any): { method: string, icon: string }[] {
    let methods = [];

    if (paymentMethod.payable_with_card) {
      methods.push({ method: 'Cartão', icon: 'pi-credit-card' });
    } else if (paymentMethod.payable_with_slip) {
      methods.push({ method: 'Boleto', icon: 'pi-file-pdf' });
    } else if (paymentMethod.payable_with_pix) {
      methods.push({ method: 'Pix', icon: 'pi-bolt' });
    } else {
      methods.push({ method: '-', icon: '' });
    }

    // Retorna as formas de pagamento como um array de objetos
    return methods;
  }

  getPaymentValue(paymentStatus: number): { severity: string, text: string } {
    switch (paymentStatus) {
      case 1:
        return { severity: 'warning', text: 'Aguardando' };
      case 2:
        return { severity: 'success', text: 'Pago' };
      case 3:
        return { severity: 'info', text: 'Expirado' };
      case 4:
        return { severity: 'primary', text: 'Estornado' };
      case 5:
        return { severity: 'danger', text: 'Cancelado' };
      default:
        return { severity: 'default', text: '-' };
    }
  }

  getTagClass(paymentStatus: number): string {
    switch (paymentStatus) {
      case 1:
        return 'ui-tag-warning';
      case 2:
        return 'ui-tag-success';
      case 3:
        return 'ui-tag-info';
      case 4:
        return 'ui-tag-primary';
      case 5:
        return 'ui-tag-danger';
      default:
        return '';
    }
  }




  getFullfilment(payment: number): { severity: string, text: string } {
    switch (payment) {
      case 1:
        return { severity: 'info', text: 'Não Enviado' };
      case 2:
        return { severity: 'warning', text: 'Parcialmente Enviado' };
      case 3:
        return { severity: 'success', text: 'Enviado' };
      case 4:
        return { severity: 'primary', text: 'Pronto p/ Retirada' };
      case 5:
        return { severity: 'danger', text: 'Devolvido' };
      default:
        return { severity: 'default', text: '-' };
    }
  }


  selectedOrders: any[] = [];
  onRowSelect(event: any) {
    console.log("Pedido selecionado:", event.data);
  }

  // Use este método para lidar com a mudança na desseleção
  onRowUnselect(event: any) {
    console.log("Pedido desselecionado:", event.data);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (!date) return '';

    const day = date.getDate();
    const month = this.getMonthAbbreviation(date.getMonth());

    return `${day}/${month}`;
  }
  manual: boolean = false;
  ValorManual() {
    this.erro4 = false;
    this.hasError4 = false;
    this.manual = true;
    console.log('jkjjk')
    // salvar cep carrinho  d
    this.issueForm = this.fb.group({
      "shipping_method": this.shipping_method_id,
      "shipping_method_name": this.shipping_method_name,
      "value": this.manualValue
    });
    this.pedidosService.addShippingMethodToCart(this.issueForm.value, this.shopping_cart_id).subscribe({
      next: (res) => {
        console.log('add método de entrega')

        this.displayDialog = false;
        this.hasShippingMethod = true;
        /////////////
        this.calcCartId();
        /////////////



      },
      error: (err) => {
        // Exiba uma mensagem de erro

        this.displayDialog = false;
      }
    });
  }
  //////////
  shipping_method_id?: number;
  shipping_method_name?: string;
  shipping_method_value?: string;
  onDeliveryMethodChange() {
    if (this.selectedDeliveryMethod) {
      console.log(this.selectedDeliveryMethod.id);
      if (this.selectedDeliveryMethod.id != 7) {
        console.log("alterou valor manual");
        this.manual = false;
        this.manualValue = null;
      }
      this.shipping_method_id = this.selectedDeliveryMethod.id;
      this.shipping_method_name = this.selectedDeliveryMethod.name;
      this.shipping_method_value = this.selectedDeliveryMethod.value;

      this.valorTotal = this.subtotal + this.valorFrete;
      if (this.manual) {
        this.issueForm = this.fb.group({
          "shipping_method": this.shipping_method_id,
          "shipping_method_name": this.shipping_method_name,
          "value": this.valorFrete
        });
      } else {
        this.issueForm = this.fb.group({
          "shipping_method": this.shipping_method_id,
          "shipping_method_name": this.shipping_method_name,
          "value": this.shipping_method_value
        });
      }

      // salvar cep carrinho  d
      this.pedidosService.addShippingMethodToCart(this.issueForm.value, this.shopping_cart_id).subscribe({
        next: (res) => {
          this.hasError4 = false;
          console.log('add método de entrega')

          this.displayDialog = false;
          this.hasShippingMethod = true;
          this.erro4 = false;
          /////////////
          this.calcCartId();
          /////////////



        },
        error: (err) => {
          // Exiba uma mensagem de erro

          this.displayDialog = false;
        }
      });
      //

    }
  }




  //////////
  onSelectAddress(event: any) {
    this.loadingDeliveryOptions = true;
    this.manual = false;
    this.manualValue = null;
    //////////////////
    if (this.shopping_cart_id != 0) {
      this.issueForm = this.fb.group({
        "shipping_method": null,
        "shipping_method_name": null,
        "value": null
      });
      this.pedidosService.addShippingMethodToCart(this.issueForm.value, this.shopping_cart_id).subscribe({
        next: (res) => {
          console.log('add método de entrega null')
          this.selectedDeliveryMethod = null;
          /////////////

          this.calcCartId();
          /////////////
        },
        error: (err) => {
          // Exiba uma mensagem de erro
          this.loadingDeliveryOptions = false;
          this.displayDialog = false;
        }
      });
    }
    //////////////////
    const selectedUserPostalCode = event.id; // Assumindo que 'id' é uma propriedade dos objetos de usuário
    this.issueForm = this.fb.group({
      "zipcode": selectedUserPostalCode
    });
    // Chame o serviço aqui, passando o uid e o ID do usuário selecionado
    this.pedidosService.addZipCodeToCart(this.issueForm.value, this.shopping_cart_id).subscribe({
      next: (res) => {
        // Exiba uma mensagem de sucesso

        this.hasZipCode = true;
        this.erro3 = false;
        this.displayDialog = false;

        /////////////
        this.issueForm = this.fb.group({
          "shopping_cart_id": this.shopping_cart_id
        });
        this.hasError3 = false;
        this.pedidosService.listDeliveryAddress(this.issueForm.value, this.shopping_cart_id).subscribe({
          next: (res) => {
            // Mapeia os métodos de entrega para deliveryOptions
            const deliveryMethods = res;
            this.deliveryOptions = deliveryMethods.map((method: DeliveryMethods) => {
              return {
                label: `${method.method} - R$${method.Valor} - Prazo: ${method.Prazo} dias`,
                value: {
                  id: method.id,
                  name: method.method,
                  value: method.Valor
                }
              };
            });
            this.fullDeliveryMethods = deliveryMethods;
            if (this.hasZipCode && this.hasShippingMethod) {
              this.calcCartId();
            }
            this.loadingDeliveryOptions = false;
          },
          error: (err) => {
            this.loadingDeliveryOptions = false;
            // Exiba uma mensagem de erro

            this.displayDialog = false;
          }
        });
        /////////////


      },
      error: (err) => {
        // Exiba uma mensagem de erro

        this.displayDialog = false;
      }
    });
  }
  calcCartId() {
    this.pedidosService.calcCartId(this.shopping_cart_id).subscribe({
      next: (res) => {
        /////////////
        console.log('calculo carrinho atualizado')
        this.subtotal = parseFloat(res.soma_items);
        this.valorFrete = parseFloat(res.valor_frete);
        this.valorTotal = parseFloat(res.total);
        this.cart_type = res.cart_type;
        this.valorCupom = parseFloat(res.valor_desc_voucher_frete) + parseFloat(res.valor_desc_voucher_cart);
        this.valorPromo = parseFloat(res.valor_desc_promo_cart);
        if (this.cart_type == 'digital') {
          // ENTÃO
          // Salvar método de entrega e valor como "null" V
          // Remover "address" do Carrinho V
          // Display OFF em
          // "Selecione o endereço do cliente"
          // "Selecione a forma de entrega" + "input manual"
          this.selectedAddress = null;
          this.issueForm = this.fb.group({
            "shipping_method": null,
            "shipping_method_name": '',
            "value": 0
          });
          this.pedidosService.addShippingMethodToCart(this.issueForm.value, this.shopping_cart_id).subscribe({
            next: (res) => {
              this.hasShippingMethod = false;

            },
            error: (err) => {
            }
          });
        }

        /////////////
      }, error: (err) => {

        this.displayDialog = false;
      }
    });
    //

  }


  generateUID() {
    return uuidv4();
  }
  search(event: any) {
    this.filteredResults = this.originalResults.filter(user =>
      user.name.toLowerCase().includes(event.query.toLowerCase())
    );
  }
  searchAddress(event: any) {
    const query = event.query.toLowerCase();
    this.filteredResultsAddress = this.originalResultsAddress.filter(address => {
      const fullAddress = [
        address.nickname,
        address.address_street,
        address.address_number,
        address.address_district,
        address.address_complement,
        address.city,
        address.state,
        address.country,
        address.postal_code
      ].filter(Boolean).join(' ').toLowerCase(); // Filtrar elementos nulos ou indefinidos e juntá-los em uma string

      // Verificar se a string contém a query de busca
      return fullAddress.includes(query);
    });
  }
  searchAddressEdit(event: any) {
    const query = event.query.toLowerCase();
    this.filteredResultsAddressEdit = this.originalResultsAddressEdit.filter(address => {
      const fullAddress = [
        address.nickname,
        address.address_street,
        address.address_number,
        address.address_district,
        address.address_complement,
        address.city,
        address.state,
        address.country,
        address.postal_code
      ].filter(Boolean).join(' ').toLowerCase(); // Filtrar elementos nulos ou indefinidos e juntá-los em uma string

      // Verificar se a string contém a query de busca
      return fullAddress.includes(query);
    });
  }
  onDropdownClick(event: any) {
    this.filteredResults = [...this.originalResults];
  }
  onDropdownClickAddress(event: any) {
    this.filteredResultsAddress = [...this.originalResultsAddress];
  }
  processarProdutos(produtos: Produto[]): any[] {
    let grupos = new Map<number, any[]>();
    let itemsProdutos: any[] = [];

    // Primeira passada: agrupar produtos por item_group_id
    produtos.forEach(produto => {
      if (produto.stock_qty > 0 || produto.track_inventory == 0 && produto.status == 1) {
        if (produto.item_group_id !== null) {
          if (!grupos.has(produto.item_group_id)) {
            grupos.set(produto.item_group_id, []);
          }
          let grupo = grupos.get(produto.item_group_id);
          if (grupo) { // onsale_price: string;
            grupo.push({
              label: produto.name + " (Estoque: " + produto.stock_qty + " uni)",
              value: produto.id,
              price: produto.onsale_price,
              id: produto.id,
              min_qty: produto.min_qty,
              isDisabled: produto.stock_qty === 0 // Adicionando flag para desabilitar
            });
          }
        }
      }
    });

    // Segunda passada: criar os grupos e tratar produtos sem grupo
    produtos.forEach(produto => {
      if (produto.stock_qty > 0 || produto.track_inventory == 0 && produto.status == 1) {
        if (produto.item_group_id === null) {
          let grupo = grupos.get(produto.id);
          if (grupo) {
            itemsProdutos.push({ label: produto.name + " Estoque: " + produto.stock_qty + " uni", items: grupo, thumbnail: produto.thumbnail });
          } else {
            itemsProdutos.push({ label: produto.name, items: [{ label: produto.name, value: produto.id, price: produto.onsale_price, id: produto.id, min_qty: produto.min_qty, thumbnail: produto.thumbnail }] });
          }
        }
      }
    });

    return itemsProdutos;
  }




  subtotal: number = 0;
  subtotalSelected!: string | null;
  invoiceSelected: SafeResourceUrl | null = null;
  date_current!: string | null;
  customerNameSelected!: string | null;
  customerSurnameSelected!: string | null;
  billingIdSelected!: number;
  customerIdSelected!: number;
  statusSelected!: number | null;
  shippingTrackerSelected!: string | null;
  shippingTrackerLinkSelected!: string | null;
  noteSelected!: string | null;
  shipping_tracker_linkSelected!: string | null;
  valorFreteSelected!: string | null;
  valorPromoSelected: number = 0;
  productShippingStatusSelected!: string | null;
  paymentStatusStatusSelected!: string | null;
  valorCupomSelected!: string | null;
  valorTotalSelected!: string | null;
  shippingMethodSelected!: string | null;
  shippingMethodSelectedId!: number | null;
  addressSelected!: string | null;

  ///////////////////////
  previousSelectedItems: any[] = [];
  addProductToCart(product: Produto): void {
    console.log(product);
    this.issueForm = this.fb.group({
      "item_qty": product.min_qty,
    });
    this.pedidosService.AdicionarItem(this.issueForm.value, this.shopping_cart_id, product.id).subscribe({
      next: (data) => {
        // Sucesso (status 200)
        this.productAdded.next(product);
        //this.selectedDeliveryMethod = null;
        this.manualValue = null;
        console.log('Produto adicionado:', data);
      },
      error: (error) => {
        // Erro

        console.error('Erro ao adicionar produto:', error);
      }
    });

  }
  removeProductFromCart(product: Produto): void {
    console.log(product);
    this.issueForm = this.fb.group({
      "item_qty": product.min_qty,
    });
    this.pedidosService.removerItem(this.issueForm.value, this.shopping_cart_id, product.id).subscribe({
      next: (data) => {
        // Sucesso (status 200)
        this.productRemoved.next(product);
        console.log('Produto adicionado:', data);
        //this.selectedDeliveryMethod = null;
        this.manualValue = null;
        //this.calcCartId();
      },
      error: (error) => {
        // Erro

        // console.error('Erro ao adicionar produto:', error);
      }
    });
  }
  valorFrete: number = 0;
  valorPromo: number = 0;
  valorCupom: number = 0;
  valorTotal: number = 0;


  onKeydown(event: KeyboardEvent) {
    if (event.key === 'KEYCODE_NEXT' || event.keyCode === 9) {
      let multiselect = document.getElementById('multiselect-itens');
      if (multiselect) {
        event.preventDefault();
        multiselect.focus();
      }
    }
  }
  processSelectionClose() { // selectedPaymentMethodsCob
    this.selectedItems = this.selectedItems.filter(item => !item.isDisabled);

    // Atualizar o estado anterior
    this.previousSelectedItems = [...this.selectedItems];

    this.hasError2 = false;
    this.erro2 = false;
  }
  getDeliveryMethod() {
    this.manualValue = null;
    this.issueForm = this.fb.group({
      "shopping_cart_id": this.shopping_cart_id
    });

    console.log('m s a b')
    console.log(this.selectedDeliveryMethod)

    this.pedidosService.listDeliveryAddress(this.issueForm.value, this.shopping_cart_id).subscribe({
      next: (res) => {
        const deliveryMethods = res;
        this.deliveryOptions = deliveryMethods.map((method: DeliveryMethods) => {
          return {
            label: `${method.method} - R$${method.Valor} - Prazo: ${method.Prazo} dias`,
            value: {
              id: method.id,
              name: method.method,
              value: method.Valor
            }
          };
        });
        this.fullDeliveryMethods = deliveryMethods;
        this.updateDeliveryOptions();
        // Reatribuir selectedDeliveryMethod para manter a seleção
        if (this.selectedDeliveryMethod) {

          const matchingMethod = this.deliveryOptions.find(option => option.value.id === this.selectedDeliveryMethod?.id);
          if (matchingMethod) {
            this.selectedDeliveryMethod.value = matchingMethod.value;
            this.shipping_method_value = matchingMethod.value;
            //// aaaaaa
            if (this.manual) {
              this.manualValue = null;
              this.issueForm = this.fb.group({
                "shipping_method": this.selectedDeliveryMethod.id,
                "shipping_method_name": this.selectedDeliveryMethod.name,
                "value": null
              });
            } else {
              this.issueForm = this.fb.group({
                "shipping_method": this.selectedDeliveryMethod.id,
                "shipping_method_name": this.selectedDeliveryMethod.name,
                "value": this.selectedDeliveryMethod.value
              });
            }
            this.pedidosService.addShippingMethodToCart(this.issueForm.value, this.shopping_cart_id).subscribe({
              next: (res) => {
                console.log('add método de entrega')
                /////////////
                this.calcCartId();
                /////////////
              },
              error: (err) => {
              }
            });
            //// aaaaaa

          }

        }

        if (this.hasZipCode && this.hasShippingMethod) {
          this.manualValue = null;
          this.calcCartId();
        }
      },
      error: (err) => {
        this.displayDialog = false;
      }
    });

  }
  processSelectionChange() {
    this.selectedItems = this.selectedItems.filter(item => !item.isDisabled);
    if (this.shopping_cart_id == 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Selecionar Cliente antes de selecionar Itens.'
      });

      // Restaura a seleção anterior para desfazer a seleção atual
      this.selectedItems = [...this.previousSelectedItems];
      return; // Sai do método para não continuar processando
    }

    const addedItems = this.selectedItems.filter(item => !this.previousSelectedItems.includes(item));
    const removedItems = this.previousSelectedItems.filter(item => !this.selectedItems.includes(item));

    addedItems.forEach(itemId => {
      const product = this.findProduct(itemId.id);
      if (product) {
        product.min_qty = product.min_qty || 1;
        product.inputQty = product.min_qty;
        this.addProductToCart(product); // Chamar seu serviço para adicionar ao carrinho
      }
    });

    // Processar itens removidos
    removedItems.forEach(itemId => {
      const product = this.findProduct(itemId.id);
      if (product) {
        this.removeProductFromCart(product); // Chamar seu serviço para remover do carrinho
      }
    });

    // Atualizar subtotal
    this.subtotal = this.calculateSubtotal();

    // Atualizar o estado anterior
    this.previousSelectedItems = [...this.selectedItems];
    // se existir zipcode
    //this.calcCartId();
    if (this.hasZipCode && this.hasShippingMethod) {

    }
    this.hasError2 = false;
    this.erro2 = false;


  }

  calculateSubtotal() {
    console.log('entrou no calc sub total')
    let subtotal = 0;
    console.log(this.selectedItems)
    if (this.selectedItems) {
      console.log('entrou no calc sub selectedItems')




      this.selectedItems.forEach(selectedItem => {
        console.log(selectedItem)
        this.itemsProdutos?.forEach(group => {
          const item = group.items.find((item: Produto) => item === selectedItem);
          console.log(item)
          if (item) {
            subtotal += parseFloat(item.price);
          }
        });
      });
      this.calcCartId();
    }
    return subtotal;
  }

  findProduct(itemId: any): Produto | undefined {
    if (this.itemsProdutos) {
      for (const group of this.itemsProdutos) {
        // Supondo que `item.value` (não `item.id`) corresponde ao `itemId`
        const foundItem = group.items.find((item: Produto) => item.id === itemId);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return undefined;
  }
  ////////////////////
  removeFromCart(productId: any): void {

  }
  calculateTotal() {

  }
  minDate: Date;
  price: any;
  valorTotalSlider: any;
  ngOnInit() {
    //this.qrCodeUrl = "teste.com";
    //this.displaySidebar = true;
    // this.activeIndex = 1;
    //this.display = true;
    //this.displayDialogSidebar=true;

    this.minDate = new Date();
    // this.confirmationService.confirm({
    //   message: 'Você realmente deseja cancelar esta venda?',
    //   header: 'Confirmação de Cancelamento',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //     //////////////////
    //     //////////////////
    //   },
    //   reject: () => {
    //   }
    // });


    this.items = [
      { label: 'Carrinho' },
      { label: 'Pagamento' },
      { label: 'Compartilhar' }
    ];
    this.paymentMethodOptions = [
      { label: 'Opção 1', value: 'opcao1' },
      { label: 'Opção 2', value: 'opcao2' },
      // ... outras opções ...
    ];


    this.itemsButtons = [
      { label: 'Exportar relatório', icon: 'pi pi-eye' },
    ];

    this.calcularOpcoesParcelamento();
    /////////
    merge(this.productAdded, this.productRemoved)
      .pipe(
        tap(() => {
          this.calcCartId();
        })
      )
      .subscribe();



    this.itemsSimulador = [
      { label: 'Pix', icon: 'pi pi-bolt', command: () => { this.onTabChange('Pix'); } },
      { label: 'Boleto', icon: 'pi pi-fw pi-file', command: () => { this.onTabChange('Boleto'); } },
      { label: 'Cartão', icon: 'pi pi-fw pi-credit-card', command: () => { this.onTabChange('Cartão'); } }
    ];

    this.activeItemSimulador = this.itemsSimulador[0];

  }
  //slider
  valSliderJuros: number = 2;
  valSliderJuros2: any;
  updateValorTotalSlider(): void { // sisi
    console.log('atualiza slider')
    // Calcula valSliderJuros e converte para uma string com duas casas decimais
    let valSliderJurosStr = ((this.valSlider - 1) * this.price).toFixed(2);

    // Converte de volta para número, se necessário
    this.valSliderJuros = parseFloat(valSliderJurosStr);

    this.valorTotalSlider = (this.valorTotal / 100) * this.valSliderJuros;

    console.log('valorTotalSlider')
    console.log(this.valorTotalSlider)
    console.log(this.valSliderJuros)
  }

  // Chame este método sempre que o valor do slider mudar
  onSliderChange(): void {
    if (this.valSlider !== undefined) {
      this.updateValorTotalSlider();
    }
  }

  onAutoCompleteBlur() {
    setTimeout(() => {
      if (this.multiSelect && this.multiSelect.nativeElement) {
        this.multiSelect.nativeElement.focus();
      }
    }, 100);
  }


  //validacao: FormGroup;
  constructor(
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private pedidosService: PedidosService,
    private focusService: FocusService,
    private messageService: MessageService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    public fb: FormBuilder
  ) {
    this.issueForm = this.fb.group({
      // defina os controles do formulário aqui
      // exemplo: 'name': ['']
    });
    // this.validacao = new FormGroup({
    //   selectedClient: new FormControl(null, Validators.required),
    //   selectedItems: new FormControl([], Validators.required),
    //   selectedAddress: new FormControl(null, Validators.required),
    //   selectedDeliveryMethod: new FormControl(null, Validators.required)
    // });
    this.selectedClientLP = {
      name: '',
      surname: '',
      birth_date: '',
      phone_number: '',
      email: '',
      cpf: '',
      ssn: '',
      company_name: '',
      cnpj: ''
    };
    this.minDate = new Date();
  }
  selectedDeliveryMethod: DeliveryMethodOption | null = null;
  items?: any[];
  itemsProdutos?: any[];
  selectedItems: any[] = [];
  activeIndex: number = 0;
  shopping_cart_id: number = 0;
  wallet_id: number = 0;
  user_id: number = 0;
  selectedAddress: Address | null = null;
  selectedAddressEdit: Address | null = null;
  date_from!: Date;
  date_to!: Date;
  fullfilmentStatus!: number;
  originalResults: any[] = [];
  filteredResults: any[] = [];
  originalResultsAddress: any[] = [];
  originalResultsAddressEdit: any[] = [];
  filteredResultsAddress: any[] = [];
  filteredResultsAddressEdit: any[] = [];
  filteredResultsItens: any[] = [];
  issueForm: FormGroup;
  itens: any[] = [];
  selectedClient?: Usuario;
  selectedClientLP?: UsuarioLP;
  uuidClient: string = '';
  onSelect(event: any) {
    this.selectedDeliveryMethod = null;
    this.selectedAddress = null;
    this.manualValue = null;
    //////////////////
    console.log('carrinho')
    console.log(this.shopping_cart_id)
    if (this.shopping_cart_id == 0) {
      this.issueForm = this.fb.group({
        "shipping_method": null,
        "shipping_method_name": null,
        "value": null
      });
      this.pedidosService.addShippingMethodToCart(this.issueForm.value, this.shopping_cart_id).subscribe({
        next: (res) => {
          this.hasError4 = false;
          console.log('add método de entrega')

          this.displayDialog = false;
          this.hasShippingMethod = true;
          this.erro4 = false;
          /////////////
          this.calcCartId();
          /////////////



        },
        error: (err) => {
          // Exiba uma mensagem de erro

          this.displayDialog = false;
        }
      });
    }
    //////////////////



    if (this.shopping_cart_id == 0) {
      if (this.shopping_cart_id == 0) {
        this.uuidClient = this.generateUID();
      }
      this.selectedClient = event;
      const uid = this.uuidClient;
      const selectedUserId = event.id;

      // Assumindo que 'id' é uma propriedade dos objetos de usuário
      console.log(uid, selectedUserId); //
      this.user_id = selectedUserId;

      this.filteredResultsAddress = [];
      this.selectedAddress = null;

      this.issueForm = this.fb.group({
        "guest_id": uid
      });
      // Chame o serviço aqui, passando o uid e o ID do usuário selecionado
      this.pedidosService.gerarSessaoVisitante(this.issueForm.value).subscribe(response => {
        console.log('gerou carrinho novo')
        if (this.shopping_cart_id == 0) {
          console.log('21111')
          this.shopping_cart_id = response.shopping_cart_id;
          console.log('novo carrinho abaixo')
          console.log(this.shopping_cart_id)
        }

        console.log(response.shopping_cart_id);

        // find in original 

        // Encontrar o usuário com base em selectedUserId e obter sua wallet_id
        const selectedUser = this.originalResults.find(user => user.id === selectedUserId);
        if (selectedUser) {
          this.wallet_id = selectedUser.wallet_id;
          this.getUserAddress(this.wallet_id);
        } else {
          // Tratar caso em que o usuário não é encontrado
          console.error('Usuário não encontrado');
        }
        this.hasShippingMethod = true;
        this.erro1 = false;
        this.hasError1 = false;
        this.calcCartId();
        // Faça algo com a resposta
      });
    }





  }
}