<div class="layout-content-wrapper">
    <div class="card">
        <div class="flex flex-column align-items-center mb-6">
            <div class="text-900 text-4xl mb-4 font-medium">O total do seu carrinho é R${{ total | number: '1.2-2' }}</div>
            <button pButton label="Comprar Agora" class="p-button"></button>
        </div>

        <ul class="list-none p-0 m-0">
            <li *ngFor="let item of cartItems$ | async"
                class="flex flex-column md:flex-row py-6 border-top-1 border-bottom-1 surface-border md:align-items-center">
                <img [src]="item.image" class="w-12rem flex-shrink-0 mx-auto md:mx-0 border-round" alt="Imagem do Produto"> 
                <div class="flex-auto py-5 md:pl-5">
                    <div class="flex flex-wrap align-items-start sm:align-items-center sm:flex-row sm:justify-content-between surface-border pb-6">
                        <div class="w-full sm:w-6 flex flex-column">
                            <span class="text-900 text-xl font-medium mb-3">{{ item.name }}</span>
                        </div>
                        <div class="w-full sm:w-6 flex align-items-start justify-content-between mt-3 sm:mt-0">
                            <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between">
                                <p-inputNumber [(ngModel)]="item.quantity" [showButtons]="true"
                                    buttonLayout="horizontal" spinnerMode="horizontal"
                                    inputStyleClass="w-3rem text-center" decrementButtonClass="p-button-text"
                                    incrementButtonClass="p-button-text" [min]="1" [max]="item.stock"
                                    (onInput)="updateQuantity(item, $event)"
                                    class="p-element p-inputwrapper p-inputwrapper-filled"
                                    [ngStyle]="{ width: '5rem' }">
                                </p-inputNumber>
                            </div>
                            <div class="flex flex-column sm:align-items-end">
                                <span class="text-900 text-xl font-medium mb-2 sm:mb-3">R${{ (item.price * item.quantity) | number: '1.2-2' }}</span>
                                <a class="cursor-pointer text-pink-500 font-medium text-sm hover:text-pink-600 transition-colors transition-duration-300"
                                    (click)="removeItem(item)">
                                    Remove
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="flex">
            <div class="w-12rem hidden md:block"></div>
            <ul class="list-none py-0 pr-0 pl-0 md:pl-5 mt-6 mx-0 mb-0 flex-auto">
                <li class="flex justify-content-between mb-4">
                    <span class="text-xl text-900 font-semibold">Subtotal</span>
                    <span class="text-xl text-900">R${{ subtotal | number: '1.2-2' }}</span>
                </li>
                <li class="flex justify-content-between border-top-1 surface-border mb-4 pt-4">
                    <span class="text-xl text-900 font-bold text-3xl">Total</span>
                    <span class="text-xl text-900 font-bold text-3xl">R${{ total | number: '1.2-2' }}</span>
                </li>
                <li class="flex justify-content-end">
                    <button pButton label="Comprar Agora" class="p-button"></button>
                </li>
            </ul>
        </div>
    </div>
</div>
