import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MemoryCleanupService {
  private mutationObserver!: MutationObserver;
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Inicia o monitoramento de mudanças no DOM
   */
  startObserving(targets: HTMLElement[]) {
    if (!targets || targets.length === 0) {
      console.warn("⚠️ Nenhum elemento fornecido para observação.");
      return;
    }
  
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.removedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            this.cleanupDetachedNode(node);
          }
        });
      });
    });
  
    // ✅ Observa todos os elementos passados no array
    targets.forEach((target) => {
      this.mutationObserver.observe(target, {
        childList: true,
        subtree: true,
      });
    });
  
    console.log("👀 Observando elementos:", targets);
  }
  

  /**
   * Limpa referências de nós removidos do DOM
   */
  cleanupDetachedNode(node: HTMLElement) {
    // Remove referências de vídeos
    const videos = node.querySelectorAll('video');
    videos.forEach((video) => {
      (video as HTMLVideoElement).src = ''; // Remove buffer de mídia
    });

    // Remove event listeners para evitar vazamento
    node.querySelectorAll('.overlayContainer').forEach((element) => {
      const clone = this.renderer.createElement(element.tagName);
      this.renderer.setProperty(clone, 'innerHTML', element.innerHTML);
      element.replaceWith(clone);
    });

    console.log(`Node ${node.nodeName} limpo da memória.`);
  }

  /**
   * Para a observação do DOM e remove o MutationObserver
   */
  stopObserving() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }
}