import { Injectable } from '@angular/core';
import { SVirtualIndexCalculationService } from './s-virtual-index-calculation.service'; // Certifique-se de importar o serviço necessário
@Injectable({
  providedIn: 'root'
})
export class SSwiperEventsService {

  constructor(private virtulIndexService: SVirtualIndexCalculationService) { }

  // Método para gerenciar o evento de 'transitionStart' e atualizar `isTransitioning` via callback
  async onTransitionStart(
    swiper: any,
    directionMove: string | undefined,
    setIsTransitioning: (value: boolean) => void // Adiciona um callback para setar `isTransitioning`
  ): Promise<void> {
    if (directionMove !== undefined) {
      // Atualizar `isTransitioning` no componente
      setIsTransitioning(true);

      //await this.blockMove(swiper, setIsTransitioning);
    }
  }

  private async blockMove(swiper: any, setIsTransitioning: (value: boolean) => void): Promise<void> {
    setIsTransitioning(true);
    swiper.allowSlideNext = false;
    swiper.allowTouchMove = false;
    swiper.allowSlidePrev = false;
  }

  onSliderMove(
    swiper: any,
    virtualIndex: number,
    slicksLength: number,
    blockOpacity: boolean,
    updateGlobals: (updatedValues: {
      progressBarOpacity: number,
      blockOpacity: boolean,
      currentVideoIndex: number
    }) => void
  ): void {
    // Atualiza o índice do slide atual
    const currentVideoIndex = parseInt(swiper.slides[swiper.activeIndex]?.getAttribute('data-swiper-slide-index'), 10);

    const isNextSlideValid = (virtualIndex > 0 && virtualIndex < slicksLength - 1) ||
      (virtualIndex === 0 && slicksLength > 1) ||
      (virtualIndex === slicksLength - 1 && slicksLength > 1);

    const swipeDirection = swiper.swipeDirection;
    const isHorizontal = swiper.isHorizontal();
    const progress = swiper.progress;
    const sensitivity = 0.22;
    // Variáveis temporárias para manipulação
    let tempBlockOpacity = blockOpacity;
    let progressBarOpacity = 1; // Valor inicial de opacidade

    // Condições para ocultar a barra de progresso (aplicando 0 de opacidade)
    if (progress !== 0 && (typeof swipeDirection !== 'undefined' || swipeDirection == 'prev') && isNextSlideValid && !isHorizontal) {
      if (!tempBlockOpacity) {
        // Se estamos movendo e a opacidade não está bloqueada, ajusta a opacidade
        if (virtualIndex > 0 && (progress > sensitivity || progress < -sensitivity) || swipeDirection == 'prev') {
          progressBarOpacity = 0; // Oculta a barra de progresso
          tempBlockOpacity = true; // Bloqueia a opacidade para evitar mudanças desnecessárias
        } else if (virtualIndex === 0 && (progress > sensitivity || progress < -sensitivity)) {
          progressBarOpacity = 0;
          tempBlockOpacity = true;
        }
      }
    } else if (Math.abs(progress) < sensitivity && tempBlockOpacity) {
      // Restaura a opacidade quando o progresso estiver próximo de 0 (pequeno movimento)
      progressBarOpacity = 1; // Restaura a opacidade total da barra
      tempBlockOpacity = false; // Desbloqueia a opacidade para novas alterações
    }
    // Retorna as variáveis globais atualizadas para o componente via callback
    updateGlobals({
      progressBarOpacity,
      blockOpacity: tempBlockOpacity,
      currentVideoIndex
    });
  }


  // Método slideChangeTransitionStart
  slideChangeTransitionStart(
    swiper: any,
    directionMove: string | undefined,
    slickInPlay: number,
    virtualIndex: number,
    checkPlayerCallback: () => HTMLVideoElement | null // Agora retorna um vídeo HTML5
  ): void {
    if (directionMove !== undefined) {
      // 🔥 Executar a verificação do player através do callback fornecido pelo componente
      const videoElement = checkPlayerCallback();
      
      if (videoElement && videoElement instanceof HTMLVideoElement) {
        // 🔥 Agora utilizamos `.currentTime = 0` corretamente para HTML5
        if (
          (slickInPlay + 1) === virtualIndex ||
          (slickInPlay - 1) === virtualIndex ||
          slickInPlay === virtualIndex
        ) {
          videoElement.currentTime = 0; // 🔥 Reseta o tempo do vídeo ao início
        }
      } else {
        console.error("slideChangeTransitionStart: O player não é um HTMLVideoElement válido.");
      }
    }
  }
  
  // Novo método para `slideChangeTransitionEnd`
  slideChangeTransitionEnd(
    updateGlobals: (updatedValues: { progressBarOpacity: number, blockOpacity: boolean }) => void
  ): void {
    // Atualiza as variáveis `progressBarOpacity` e `blockOpacity`
    updateGlobals({
      progressBarOpacity: 1,
      blockOpacity: false
    });
  }
  private isFirstSlideChange: boolean = true;
  // Novo método para `slideChange`
  async slideChange(
    swiper: any,
    realActiveIndex: number,
    lastRealIndex: number,
    virtualIndex: number,
    loadedSlides: number,
    setGlobals: (updatedValues: { lastRealIndex: number }) => void,
    removeListeners: () => void,
    pauseActiveVisibleSlide: () => void
  ) {
    // Calcular o novo virtualIndex
    if (!((lastRealIndex === 0 && realActiveIndex === 0) || (lastRealIndex === 2 && realActiveIndex === 2))) {
      virtualIndex = await this.virtulIndexService.getVirtualActiveIndex(loadedSlides, virtualIndex, realActiveIndex, lastRealIndex);
    }

    let directionMove: string | undefined;
    if (this.isFirstSlideChange) {
      directionMove = undefined;
      this.isFirstSlideChange = false; // Atualiza o sinalizador para não redefinir mais
    } else {
      // Lógica para detectar a direção após a primeira execução
      if (realActiveIndex === 0 && lastRealIndex === 2) {
        directionMove = 'next';
      } else if (realActiveIndex === 2 && lastRealIndex === 0) {
        directionMove = 'prev';
      } else {
        if (realActiveIndex != lastRealIndex) {
          directionMove = realActiveIndex > lastRealIndex ? 'next' : 'prev';
        }

      }
    }


    lastRealIndex = realActiveIndex;

    setGlobals({ lastRealIndex });

    if (directionMove !== undefined) {
      removeListeners();
    }

    if (directionMove !== undefined) {
      pauseActiveVisibleSlide();
    }
  }
  getLastIndex(swiper: any, directionMove: string | undefined): number {
    let lastIndex: number = 0;
    if (directionMove === 'next') {
      lastIndex = swiper.realIndex === 0 ? 2 : swiper.realIndex - 1; // Ajuste aqui para considerar o loop
    } else if (directionMove === 'prev') {
      lastIndex = swiper.realIndex === 2 ? 0 : swiper.realIndex + 1; // Ajuste aqui para considerar o loop
    }

    if (lastIndex < 0) {
      lastIndex = 0; // Caso o índice anterior tenha ficado negativo, ajusta para 0
    }

    const maxIndex = 2; // Baseado nos seus 3 índices (0, 1 e 2)
    lastIndex = lastIndex > maxIndex ? maxIndex : lastIndex;

    return lastIndex;
  }

  // Método para obter o slide index a partir do localStorage
  getSlideIndexFromLocalStorage(): number | undefined {
    const playerDataString = localStorage.getItem('player_data');
    if (playerDataString) {
      const playerData = JSON.parse(playerDataString);
      return playerData.current_asset.slide_index; // Retorna o slide index
    }
    return undefined; // Retorna undefined se não encontrar o dado no localStorage
  }


}
