import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
//import { io, Socket } from 'socket.io-client';
declare const io: any;
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root'
})
export class ChatWebSocketService implements OnDestroy {
  private socket: any | undefined;

  constructor() { }

  private messagesMap = new Map<string, any[]>();
  generateUID() {
    return uuidv4();
  }
  connectSocket(chatId: string, user_chat?: string) {
    user_chat = localStorage.getItem('user_chat') ?? undefined;
    if (!user_chat) {
      const newUID = this.generateUID();
      localStorage.setItem('user_chat', newUID);
      user_chat = newUID;
    }
    if (this.socket) {
      console.log('Encerrando conexão anterior.');
      this.socket.disconnect(); // Encerre conexões anteriores.
      this.socket = undefined;
    }

    this.chatId = chatId;
    const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    this.socket = io(`${protocol}slicksell.click`, { query: { chatId, user_chat } });

    // Eventos de conexão
    this.socket.on('connect', () => {
      console.log('Conectado ao WebSocket com ID:', this.socket.id);
    });

    this.socket.on('connect_error', (err: any) => {
      console.error('Erro ao conectar ao WebSocket:', err.message);
    });

    this.socket.on('disconnect', (reason: string) => {
      console.warn('Desconectado do WebSocket. Motivo:', reason);
    });

    // Eventos personalizados
    this.socket.on('load messages', (messages: any[]) => {
      console.log('Mensagens carregadas:', messages);
      this.messagesMap.set(chatId, messages);
    });

    // this.socket.on('chat message', (message: any) => {
    //   console.log('Mensagem recebida no cliente:', message);
    //   this.addMessageToChat(chatId, message);
    // });
  }


  username: string = '';



  getStoredMessages(chatId: string): any[] {
    return this.messagesMap.get(chatId) || [];
  }



  sendMessage(chatId: string, message: string, user_chat?: string): void {
    user_chat = localStorage.getItem('user_chat') ?? undefined;
    if (!this.socket) {
      this.connectSocket(chatId, user_chat);
    }
    if (this.socket) {
      this.socket.emit('chat message', { chatId, content: message, username: this.username, user_chat: user_chat });
    } else {
      console.log('não conectado');
    }
  }

  joinRoom(chatId: string, username: string, user_chat?: string): void {
    this.username = username;
    this.connectSocket(chatId, user_chat);  // Sempre reconecte com o novo ID do chat
    this.socket?.emit('new user', { name: username, id: chatId, user_chat: user_chat }, (response: boolean) => {
      if (response) {
        console.log(`User ${username} joined chat ${chatId}`);
      }
    });

    // this.socket?.on('your-ip', (ip: string) => {
    //   localStorage.setItem('user_chat', ip);
    //   localStorage.setItem('user_ip', ip);
    // });

    // this.socket?.on('chat message', (message: any) => {

    //   console.log('CCCheou mensagem', message);

    //   this.addMessageToChat(chatId, message);
    // });
  }
  receiveMessages(): Observable<any> {
    return new Observable(observer => {
      this.socket?.on('chat message', (message: any) => {

        //this.messagesMap.set(message.chatId, messages);
        this.addMessageToChat(message.chatId, message);
        observer.next(message);
      });
    });
  }
  addMessageToChat(chatId: string, message: any): void {
    let messages = this.messagesMap.get(chatId) || [];
    messages.push(message);
    this.messagesMap.set(chatId, messages);
  }
  getNumUsers(chatId: string): Observable<number> {
    this.connectSocket(chatId); // Desliga o ouvinte anterior para evitar múltiplas respostas
    return new Observable(observer => {
      this.socket?.on('num users', (numUsers: number) => {
        observer.next(numUsers);
      });
    });
  }

  chatId: string = '';

  getInitialMessages(chatId: string): Observable<any[]> {
    console.log('chamou método de loading messages');
    // Checa se já existem mensagens para este chatId no mapa
    chatId = this.chatId;
    let cachedMessages = this.messagesMap.get(chatId);

    if (!cachedMessages || cachedMessages.length === 0) {
      // Se não houver mensagens, solicita ao servidor
      this.socket?.emit('getMessages', chatId);
    }

    return new Observable(observer => {
      // Retorna as mensagens já carregadas de imediato
      observer.next(cachedMessages || []);

      // Escuta por novas mensagens vindas do servidor
      this.socket?.on('messages', (newMessages: any[]) => {
        // Atualiza o cache com as novas mensagens
        let updatedMessages = this.messagesMap.get(chatId) || [];
        updatedMessages.push(...newMessages);
        this.messagesMap.set(chatId, updatedMessages);

        // Emite as mensagens atualizadas para os assinantes
        observer.next(updatedMessages);
        observer.complete(); // Completa o Observable após receber os dados
      });
    });
  }

  receiveInitialMessages(): Observable<any[]> {
    return new Observable(observer => {
      this.socket?.on('messages', (messages: any[]) => {
        observer.next(messages);
      });
    });
  }

  disconnect() {
    // if (this.socket) {
    //   console.log('chamou disconnect');
    //   this.socket.disconnect();
    //   this.socket = undefined;  // Limpa a instância após desconectar
    // }
    //this.cleanupSocket();
  }
  ngOnDestroy(): void {
    console.log('ChatWebSocketService destruído. Encerrando WebSocket.');
    this.cleanupSocket(); // Certifique-se de limpar o WebSocket
  }
  private cleanupSocket(): void {
    if (this.socket) {
      console.log('Encerrando conexões e removendo listeners do WebSocket.');
  
      // Remover todos os listeners do WebSocket
      this.socket.off('connect');
      this.socket.off('connect_error');
      this.socket.off('disconnect');
      this.socket.off('load messages');
      this.socket.off('chat message');
      this.socket.off('num users');
      this.socket.off('messages');
  
      // Desconectar o WebSocket
      this.socket.disconnect();
      this.socket = undefined; // Limpa a instância do socket
    }
  }
}
