import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class SUpdateStyleService {

  constructor() { }

  updateDynamicStyle(componentInstance: any, height?: number): void {
    // componentInstance.isFullScreen = true;

    // // Detecta dispositivo móvel
    // const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // height = undefined;
    // // Define a altura em pixels de acordo com o dispositivo e contexto
    // const heightInPixels = height 
    //   ? height // Usa a altura passada, se disponível
    //   : (isMobileDevice && window.visualViewport ? window.visualViewport.height : window.innerHeight); // Condição para mobile ou desktop

    // componentInstance.dynamicStyle = componentInstance.isFullScreen 
    //   ? { 'height': `${heightInPixels}px`, 'border-radius': '0px' } 
    //   : {};

    // // Calcula a posição top da barra de progresso
    // const progressBarTop = heightInPixels - 70;
    // document.documentElement.style.setProperty('--progress-bar-top-time', `${progressBarTop - 150}px`);
    //document.documentElement.style.setProperty('--swiper-slide-height', `${heightInPixels}px`);
}




  calculateDynamicStyle(isFullScreen: boolean): { dynamicStyle: any, swiperSlideHeight: number } {
    const heightInPixels = window.visualViewport ? window.visualViewport.height : window.innerHeight;

    const dynamicStyle = isFullScreen
      ? { 'height': `${heightInPixels}px` }
      : {};

    // Calcula a posição top da barra de progresso
    const swiperSlideHeight = heightInPixels;

    return {
      dynamicStyle,
      swiperSlideHeight
    };
  }

  calculateMaxWidth(containerWidth: number): void {
    let maxWidth: string;
    if (containerWidth < 600) {
      maxWidth = '75%';
    } else if (containerWidth < 960) {
      maxWidth = '50%';
    } else if (containerWidth < 1280) {
      maxWidth = '30%';
    } else {
      maxWidth = '25%';
    }

    const currentWidth = getComputedStyle(document.documentElement).getPropertyValue('--swiper-slide-max-width');
    if (currentWidth !== maxWidth) {
      document.documentElement.style.setProperty('--swiper-slide-max-width', maxWidth);
    }
  }

  // Método para calcular o sumTotal e retornar as variáveis ajustadas
  calculateGridTemplateRows(
    top1: number,
    top2: number,
    center: number,
    bottom0: number,
    bottom1: number,
    bottom2: number,
    bottom3: number
  ): { center: number, gridTemplateRows: string, sumTotal: number } {
    const sumTotal = top1 + top2 + center + bottom0 + bottom1 + bottom2 + bottom3;
    let diff = 100 - sumTotal;

    // Ajuste do valor de `center` com base na diferença calculada
    if (diff > 0) {
      center += diff;
    } else if (diff < 0) {
      center -= diff;
    }

    // Construção do `gridTemplateRows`
    const gridTemplateRows = `${top1}% ${top2}% ${center}% ${bottom0}% ${bottom1}% ${bottom2}% ${bottom3}%`;

    // Retornar os valores ajustados
    return { center, gridTemplateRows, sumTotal };
  }
}
