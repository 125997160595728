import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
export interface Widget {
  id: number;
  uid: string;
  type: number;
  name: string;
  smart: number | null;
  store_id: number;
  tags: string;
  title: string;
  limit: number | null;
  assets_count: number | null;
  template: string | null;
  url_display: string | null;
  created_at: string;
  updated_at: string;
  feed_type: string;
}
export interface IStoreData {
  id: number;
  owned_by_company_id?: number;
  owned_by_user_id: number;
  store_name: string;
  store_description?: string;
  store_link_subdomain: string;
  store_link_domain?: string;
  store_zip_code?: string;
  store_street?: string;
  store_number?: string;
  store_district?: string;
  store_city?: string;
  store_uf?: string;
  store_complement?: string;
  support_email?: string;
  support_phone_number?: string;
  support_whatsapp?: string;
  support_whatsapp_status: number;
  zipcode?: string;
  metadata_object_id?: string;
  show_address: number;
  maintenence_status: number;
  status: number;
  created_at: string;
  updated_at: string;
  channel_id: number;
  public_token: string;
  store_logo?: string;
}

export interface WidgetDetailsResponse {
  id: number;
  uid: string;
  type: number;
  smart: number;
  store_id: number;
  title: string;
  feed_type: string;
  slick_filter: string;
  max_limit: number | null;
  tags: string;
  template: string;
  url_display: string;
  assets: {
    id: number;
    position: number | null; // Adicionei o campo 'position', que também faz parte de cada asset
  }[];
  style: {
    popup: {
      position?: string;
      highlight?: string;
      shape?: string;
      background_color?: string;
    },
    iframe: {
      selectedResolution?: {
        width: number;
        height: number;
      };
    },
    inline: {
      selectedResolution?: {
        width: number;
        height: number;
      };
      showDescription?: boolean;
      showProducts?: boolean;
    },
    carousel: {
      position?: string;
      highlight?: string;
      background_color?: string;
      font_color?: string;
      showProducts?: boolean;
    },
    stories: {
      position?: string;
      highlight?: string;
      background_color?: string;
      font_color?: string;
      showProducts?: boolean;
    }
  };
  widget_rule: {
    device_display: {
      mobile_display: boolean;
      desktop_display: boolean;
    };
    url_display: {
      contain_rule: string;
      equal_rule: string;
    };
  };
  created_at: string;
  updated_at: string;
}
export interface AssetsResponse {
  assets: {
    items: {
      schedule: {
        date_from: string;
        date_to: string | null;
      };
      media: {
        type: string;
        src: string;
        thumbnail: string | null;
        duration: number;
        aspect_ratio: string;
        pin: number;
        status: number;
      };
      content: {
        id: number;
        uid: string;
        title: string;
        description: string;
      };
    }[];
  }[];
}
@Injectable({
  providedIn: 'root'
})
export class WidgetsService {
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    }),
  };
  baseurl = 'https://app.krabo.io/api'; // Base_url
  baseurl2 = 'https://app.krabo.io/'; // Base_url
  constructor(private httpClient: HttpClient) {
    // this.dadosUsuarioLogado();
    if (environment.production) {
      this.baseurl = environment.serverUrl;
    } else {
      this.baseurl = environment.paymentGenetatorLinkURL;
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
  }

  getWidgets(): Observable<Widget[]> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    let storeId = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.httpClient.get<Widget[]>(`${environment.serverUrl}/admin/store/${storeId}/videoshopping/widget`, this.httpOptions);
    } else {
      return this.httpClient.get<Widget[]>(`${environment.paymentGenetatorLinkURL}/admin/store/${storeId}/videoshopping/widget`, this.httpOptions);
    }
  }













  deleteWidgets(widget_id: number): Observable<Widget[]> {
    let storeId = localStorage.getItem("SelectedStoreId") || '';
    if (environment.production) {
      return this.httpClient.delete<Widget[]>(`${environment.serverUrl}/admin/store/${storeId}/videoshopping/widget/${widget_id}`, this.httpOptions);
    } else {
      return this.httpClient.delete<Widget[]>(`${environment.paymentGenetatorLinkURL}/admin/store/${storeId}/videoshopping/widget/${widget_id}`, this.httpOptions);
    }
  }
  getAssets(filter: string | undefined = undefined): Observable<AssetsResponse> {

    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    let hasFilter = '';
    if (filter != undefined) {
      hasFilter = '?filter=' + filter + '&limit=9';
    }

    if (environment.production) {
      return this.httpClient.get<AssetsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/asset${hasFilter}`, this.httpOptions);
    } else {
      return this.httpClient.get<AssetsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/asset${hasFilter}`, this.httpOptions);
    }
  }
  listarAssetsWidget(widget_id: number | null, page: number | null = 1, limit: number | null = 1): Observable<AssetsResponse> {

    const id_loja = localStorage.getItem("SelectedStoreId") || 163;

    if (page == null) {
      page = 1
    }

    if (environment.production) {
      return this.httpClient.get<AssetsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget/${widget_id}/assets?page=${page}&limit=${limit}`, this.httpOptions);
    } else {
      return this.httpClient.get<AssetsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget/${widget_id}/assets?page=${page}&limit=${limit}`, this.httpOptions);
    }
  }
  detailWidget(uid: string | undefined = undefined): Observable<WidgetDetailsResponse> {

    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    // Se não encontrar, tenta pegar o SelectedStoreId dentro do objeto 'store' no localStorage
    if (!id_loja) {
      const storeInfo = localStorage.getItem("store");

      if (storeInfo) {
        try {
          const storeData = JSON.parse(storeInfo); // Converte o JSON de volta para objeto
          id_loja = storeData.SelectedStoreId || ''; // Acessa o SelectedStoreId dentro do objeto 'store'
        } catch (error) {
          console.error("Erro ao tentar acessar 'SelectedStoreId' do objeto 'store':", error);
          id_loja = ''; // Em caso de erro no parsing, define como vazio
        }
      }
    }

    if (environment.production) {
      return this.httpClient.get<WidgetDetailsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget/detail/${uid}`, this.httpOptions);
    } else {
      return this.httpClient.get<WidgetDetailsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget/detail/${uid}`, this.httpOptions);
    }
  }
  detailWidgetPublic(uid: string | undefined = undefined): Observable<WidgetDetailsResponse> {

    let id_loja = localStorage.getItem("SelectedStoreId") || '';
    // Se não encontrar, tenta pegar o SelectedStoreId dentro do objeto 'store' no localStorage
    if (!id_loja) {
      const storeInfo = localStorage.getItem("store");

      if (storeInfo) {
        try {
          const storeData = JSON.parse(storeInfo); // Converte o JSON de volta para objeto
          id_loja = storeData.SelectedStoreId || ''; // Acessa o SelectedStoreId dentro do objeto 'store'
        } catch (error) {
          console.error("Erro ao tentar acessar 'SelectedStoreId' do objeto 'store':", error);
          id_loja = ''; // Em caso de erro no parsing, define como vazio
        }
      }
    }

    if (environment.production) {
      return this.httpClient.get<WidgetDetailsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget/detail/${uid}/public`, this.httpOptions);
    } else {
      return this.httpClient.get<WidgetDetailsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget/detail/${uid}/public`, this.httpOptions);
    }
  }
  createWidget(widgetData: any): Observable<WidgetDetailsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }),
    };
    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.httpClient.post<WidgetDetailsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget/create`, widgetData, this.httpOptions);
    } else {
      return this.httpClient.post<WidgetDetailsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget/create`, widgetData, this.httpOptions);
    }
  }
  editWidget(widgetData: any, id: number): Observable<AssetsResponse> {

    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.httpClient.patch<AssetsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget/${id}`, widgetData, this.httpOptions);
    } else {
      return this.httpClient.patch<AssetsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget/${id}`, widgetData, this.httpOptions);
    }
  }
  addWidget(widgetData: any, uid: string | undefined): Observable<AssetsResponse> {

    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.httpClient.post<AssetsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget_asset/${uid}`, widgetData, this.httpOptions);
    } else {
      return this.httpClient.post<AssetsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget_asset/${uid}`, widgetData, this.httpOptions);
    }
  }
  subWidget(widgetData: any, uid: string | undefined): Observable<AssetsResponse> {

    const id_loja = localStorage.getItem("SelectedStoreId") || '';

    if (environment.production) {
      return this.httpClient.patch<AssetsResponse>(`${environment.serverUrl}/admin/store/${id_loja}/videoshopping/widget_asset/${uid}`, widgetData, this.httpOptions);
    } else {
      return this.httpClient.patch<AssetsResponse>(`${environment.paymentGenetatorLinkURL}/admin/store/${id_loja}/videoshopping/widget_asset/${uid}`, widgetData, this.httpOptions);
    }
  }
  getAccessToken(): string {
    return localStorage.getItem('access_token') || '';
  }

}
